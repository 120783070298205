import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import HeaderModal from "../../../../../components/header_modal";
import CustomModal from "../../../../../common/modal/custom_modal";
import LastFinished from "../../../../0-main/3-last_fihished";
import {Skeleton, useMediaQuery} from "@mui/material";
import {useSelector} from "react-redux";
import {selectSocketGlobalSlice} from "../../../../../redux/slice/socketroom.slice";
import {useGetAuctionsUpFinishQuery, useGetAuctionsUpFinishSwiperMutation} from "../../../../../redux/auctions.service";
import PreloaderPageLoad from "../../../../../components/preloader_page_load";
import Button from "../../../../../common/ui_kit/button";

const ModalLastAuc = ({open, handleClose}) => {
    const query_780 = useMediaQuery('(max-width:780px)');

    const {socketGlobal} = useSelector(selectSocketGlobalSlice)

    const [page, setPage] = useState(1)

    const {data: finish_data, isLoading, isFetching, refetch} = useGetAuctionsUpFinishQuery({
        page_size: query_780 ? 12 : 24,
        page
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        // skip: query_780
    })

    useEffect(() => {
        if (socketGlobal?.t === 300 && socketGlobal?.ia?.state === 'end') {
            refetch && refetch()
        }
    }, [socketGlobal, query_780])

    return (
        <CustomModal open={open} width={'1440px'}
                     onClose={handleClose}>
            <div className={s.modal}>
                <div className={s.modal_top}>
                    <HeaderModal isMobile={false} onClose={handleClose}
                                 title={'Завершенные аукционы'}
                                 arrow={false} typeModal={0}/>
                    <div className={s.content}>
                        {isLoading ? <Skeleton variant="rounded"
                                               width={`100%`}
                                               sx={{borderRadius: '16px'}}
                                               height={'500px'}/> :
                            <LastFinished isModalType={true} isLoadingData={isFetching} page={page} setPage={setPage}
                                          data={finish_data || []}/>}
                    </div>
                </div>
                {query_780 && <div className={s.btn}>
                    <Button onClick={handleClose}>Закрыть</Button>
                </div>}
            </div>
        </CustomModal>
    );
};

export default ModalLastAuc;