import React, {useContext, useEffect, useRef, useState} from 'react';
import s from './styles.module.css'
import Container from "../../../components/container";
import TitleAuction from "../../../common/title_auction";
import {Avatar, IconButton, Pagination, Tooltip, useMediaQuery} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import ItemMobile from "./item_mobile";
import {useSelector} from "react-redux";
import moment from "moment/moment";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "../../../assets/css/table-styles.css";
import {selectCategory} from "../../../redux/slice/category_subcategory.slice";
import {useNavigate} from "react-router-dom";
import {PATH} from "../../../constants/path";
import {short_month} from "../../../helper/date";
import {TimeZoneContext} from "../../../App";
import {ReactComponent as AuctionUpIcon} from "../../../assets/svg/auction_up.svg";
import {commisionCircle, spaceBtwHundreds} from "../../../helper/spaceBetweenSum";
import classNames from "classnames";
import SyncIcon from '@mui/icons-material/Sync';

const gridOptions = {
    headerHeight: 68,
    rowHeight: 92,
    suppressDragLeaveHidesColumns: true
};

export function calculatePercent(mall_price, sell_price) {
    const diff = mall_price - sell_price;
    const percent = (diff / mall_price) * 100;
    return percent === 0 ? 0 : percent
}

export function toFixed(number) {
    const decimalPart = number.toString().split('.')[1];
    if (decimalPart && parseInt(decimalPart) !== 0) {
        return number.toFixed(2);
    }
    return Math.round(number);
}

const WrapperContainer = ({children, isModalType}) => {
    if (!isModalType) {
        return <Container>{children}</Container>
    } else {
        return <div>{children}</div>
    }
}

const LastFinished = ({isLoadingData = false, data, setPage, page, isModalType = false}) => {
    const gridRef = useRef(null);
    const query_1280 = useMediaQuery('(max-width:1280px)');
    const query_780 = useMediaQuery('(max-width:780px)');
    const navigate = useNavigate()
    const {timezone} = useContext(TimeZoneContext)

    const {category: category_data} = useSelector(selectCategory)

    const [colDefs, setColDefs] = useState([
        {
            id: 1,
            field: "product.title",
            flex: 30.81,
            minWidth: 100,
            headerName: 'Товар',
            wrapText: true,
            headerClass: s.header_table_end,
            cellStyle: {cursor: 'pointer'},
            onCellClicked: (params) => {

                const category_slag = category_data?.results?.find((f) => f.id === params?.data?.product?.category)
                const sub_category_slag = category_slag?.subcategory_set?.find((f) => f.id === params?.data?.product?.subcategory)

                navigate(`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${params?.data?.id}`)
            },
            cellRenderer: (e) => {
                const {hero_image} = e?.data?.product

                return <div className={s.item_box}>
                    <div className={s.item_box_img}>
                        <Avatar src={hero_image} sx={{height: '100%', width: '100%', borderRadius: '8px'}}/>
                    </div>
                    <Tooltip title={e.value}>
                        <p className={s.item_box_title}>{e.value}</p>
                    </Tooltip>
                </div>
            }
        },
        {
            id: 2,
            field: "start_date", flex: 12.69, headerName: 'Дата',
            minWidth: 95,
            headerClass: s.header_table_end,
            cellRenderer: (e) => {
                const edc = moment.tz(e.value, 'Europe/Moscow').clone().tz(timezone)
                return <p className={s.item_box_date}>
                    {`${edc.date()} ${short_month[edc.month()]} ${edc.year()}, ${edc.hours() >= 10 ? edc.hours() :
                        `0${edc.hours()}`}:${edc.minutes() >= 10 ? edc.minutes() :
                        `0${edc.minutes()}`}`}
                </p>
            }
        },
        {
            id: 3,
            minWidth: 100,
            headerClass: s.header_table_end,
            field: "", flex: 9.66, headerName: 'Время торгов',
            comparator: (a, b, valueA, valueB) => {
                if (!valueA?.data?.start_date || !valueB?.data?.start_date || !valueA?.data?.end_date || !valueB?.data?.end_date) {
                    return 0;
                }
                const momentStartA = moment(valueA?.data?.start_date);
                const momentEndA = moment(valueA?.data?.end_date);
                const momentStartB = moment(valueB?.data?.start_date);
                const momentEndB = moment(valueB?.data?.end_date);

                const durationA = moment.duration(momentEndA.diff(momentStartA));
                const durationB = moment.duration(momentEndB.diff(momentStartB));

                return durationA.asMilliseconds() - durationB.asMilliseconds();
            },
            cellRenderer: (e) => {
                const duration_completed = moment.duration(moment(e.data?.end_date).diff(moment(e.data?.start_date))) ? moment.duration(moment(e.data?.end_date).diff(moment(e.data?.start_date))) : null;

                const duration_completed_obj = {
                    hh: `${duration_completed.hours() >= 10 ? duration_completed.hours() : `0${duration_completed.hours()}`}`,
                    mm: `${duration_completed.minutes() >= 10 ? duration_completed.minutes() : `0${duration_completed.minutes()}`}`,
                    ss: `${duration_completed.seconds() >= 10 ? duration_completed.seconds() : `0${duration_completed.seconds()}`}`,
                }
                return <p className={s.item_box_date}>
                    {`${duration_completed_obj.hh}:${duration_completed_obj.mm}:${duration_completed_obj.ss}`}
                </p>
            }
        },
        {
            id: 4,
            field: "",
            flex: 5.93,
            minWidth: 60,
            headerName: 'Тип',
            sortable: false,
            headerClass: s.header_table_end,
            cellRenderer: (e) => {
                return <div className={s.item_box_icon}>
                    <Tooltip title={'Торги на повышение'}>
                        <AuctionUpIcon/>
                    </Tooltip>
                </div>
            }
        },
        {
            id: 5,
            minWidth: 110,
            field: "product.retail_price",
            headerClass: s.header_table_end,
            flex: 13.37,
            headerName: 'Розничная цена',
            cellRenderer: (e) => {
                return <p className={s.item_box_price}> {spaceBtwHundreds(e.value || 0)} ₽</p>
            }
        },
        {
            id: 6,
            minWidth: 130,
            field: "bid_price_percent",
            headerClass: s.header_table_end,
            flex: 13.37,
            headerName: 'Стоимость участия',
            comparator: (a, b, valueA, valueB) => {
                if (!valueA || !valueB) {
                    return 0;
                }

                const durationA = (+valueA?.data?.bid_price_percent !== 0 && +valueA?.data?.bid_price_percent) ? commisionCircle((+valueA?.data?.min_price_increment * +valueA?.data?.bid_price_percent) / 100) : 0;
                const durationB = (+valueB?.data?.bid_price_percent !== 0 && +valueB?.data?.bid_price_percent) ? commisionCircle((+valueB?.data?.min_price_increment * +valueB?.data?.bid_price_percent) / 100) : 0;

                return durationA - durationB;
            },
            cellRenderer: (e) => {
                return <p
                    className={s.item_box_price}>{((+e.value === 0 || !e.value) ? 'Бесплатно' : `от ${spaceBtwHundreds(commisionCircle((+e?.data?.min_price_increment * +e.value) / 100))} ₽`)}
                </p>
            }
        },
        {
            id: 7,
            minWidth: 130,
            field: "highest_bid_amount",
            headerClass: s.header_table_end,
            flex: 13.37,
            headerName: 'Цена продажи',
            cellRenderer: (e) => {
                return <div className={s.item_box_profit}>
                    {e.value && <div className={s.item_box_profit_content}>
                        <p className={s.item_box_profit_title}>{spaceBtwHundreds(e.value)} ₽</p>

                        <p className={s.percent} style={{
                            color: calculatePercent(e.data?.product?.retail_price, e.value) > 0 ? '#29B942' : 'red'
                        }}>({calculatePercent(
                            e.data?.product?.retail_price, e.value) > 0 ?
                            `-${spaceBtwHundreds(toFixed(calculatePercent(e.data?.product?.retail_price, e.value)))}` :
                            `+${spaceBtwHundreds(toFixed(Math.abs(calculatePercent(e.data?.product?.retail_price, e.value))))}`}%)
                        </p>
                    </div>}
                    {!e.value &&
                        <p className={classNames(s.item_box_profit_title, s.item_box_profit_title_v2)}>Аукцион не
                            состоялся</p>}
                </div>
            }
        },
    ]);

    const handleResetColumnState = () => {
        if (gridRef.current) {
            gridRef.current.api.resetColumnState();
        }
    };

    useEffect(() => {
        setColDefs(colDefs?.map((el) => el.id === 2 ? ({
            ...el,
            cellRenderer: (e) => {
                const edc = moment.tz(e.value, 'Europe/Moscow').clone().tz(timezone)
                return <p className={s.item_box_date}>
                    {`${edc.date()} ${short_month[edc.month()]} ${query_1280 ? '' : edc.year()}, ${edc.hours() >= 10 ? edc.hours() :
                        `0${edc.hours()}`}:${edc.minutes() >= 10 ? edc.minutes() :
                        `0${edc.minutes()}`}`}
                </p>
            }
        }) : el))

    }, [query_1280])


    return (
        <div className={classNames(s.last_finished_container, isModalType && s.last_finished_container_modal_type)}>
            <WrapperContainer isModalType={isModalType}>
                {!isModalType && <div className={s.header}>
                    <TitleAuction onlyTitle={true} title={'Последние завершенные'}/>
                    <div className={s.update_table}>
                        <Tooltip title={'Обновить таблицу'}>
                            <IconButton onClick={handleResetColumnState}>
                                <SyncIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>}

                <div className={classNames(s.table_header, isModalType && s.table_header_modal_type)}>

                    <div className="ag-theme-quartz" style={{
                        height: 500,
                        width: '100%',
                        border: isModalType ? '1px solid #E9EAEC' : '',
                        borderRadius: isModalType ? '14px' : ''
                    }}>
                        <AgGridReact ref={gridRef} gridOptions={gridOptions}
                                     localeText={{
                                         noRowsToShow: 'Нет данных',
                                     }}
                                     suppressCellSelection={true}
                                     rowData={data?.results || []}
                                     columnDefs={colDefs}/>
                    </div>
                    {setPage && <div className={s.pagination}>
                        <Pagination
                            page={page}
                            onChange={(e, v) => {
                                setPage(v)
                            }}
                            color="primary"
                            count={data ? Math.ceil(data?.count / (query_780 ? 12 : 24)) : 0}
                            variant="outlined"
                            shape="rounded"
                        />
                    </div>}
                </div>

                {isModalType ? <div className={s.table_mobile_modal}>
                    {data?.results?.map((el, i) =>
                        <ItemMobile {...el} key={i} isModalType={isModalType}/>
                    )}
                    {setPage && <div className={classNames(s.pagination,isModalType && s.pagination_modal)} >
                        <Pagination
                            page={page}
                            onChange={(e, v) => {
                                setPage(v)
                            }}
                            color="primary"
                            count={data ? Math.ceil(data?.count / (query_780 ? 12 : 24)) : 0}
                            variant="outlined"
                            shape="rounded"
                        />
                    </div>}
                </div> : <div className={s.table_mobile}>
                    <Swiper
                        style={{width: '100%'}}
                        spaceBetween={8}
                        speed={1500}
                        shouldSwiperUpdate
                        onSlideChange={(a) => {
                            if (!data || !data?.results || data?.results?.length === 0 || data?.results?.length >= data?.count) {
                                return
                            } else {
                                if (data?.results?.length - 6 === a?.activeIndex + 1) {
                                    setPage(page + 1)
                                }
                            }

                        }}
                        slidesPerView={1.05}
                    >
                        {data?.results?.map((el, i) => <SwiperSlide key={i}>
                            <ItemMobile {...el}/>
                        </SwiperSlide>)}
                    </Swiper>
                </div>}
            </WrapperContainer>
        </div>
    );
};

export default LastFinished;
