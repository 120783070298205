import React from 'react';
import classNames from 'classnames';
import s from './styles.module.css'
import {CircularProgress} from "@mui/material";

const Button = ({title, id, loading = false, disabled = false, onClick, className, padding, styles, type = 0}) => {
    return (
        <div onClick={() => {
            if (loading) return
            if (disabled) return
            onClick()
        }} id={id} style={{
            padding: padding,
            background: (loading || disabled) && 'lightgray',
            cursor: (loading || disabled) && 'not-allowed',
            ...styles
        }} className={classNames(s.btn, type === 1 && s.secondTypeBtn, className)}>
            {loading ? <CircularProgress size={25} sx={{
                color: '#5654D4'
            }}/> : title}
        </div>
    );
};

export default Button;
