import React from 'react';
import s from './styles.module.css'
import Button from "../../../../common/ui_kit/button";
import { useGetAuctionsUpQuery } from "../../../../redux/auctions.service";
import { PATH } from "../../../../constants/path";
import { Avatar, useMediaQuery } from "@mui/material";
import { spaceBtwHundreds } from "../../../../helper/spaceBetweenSum";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCategory } from "../../../../redux/slice/category_subcategory.slice";

const AuctionList = ({ data, refLeftBox }) => {
    const query_700 = useMediaQuery('(max-width:780px)');

    const { category: category_data } = useSelector(selectCategory)

    const { data: data_auc, error: error_up, isLoading, isFetching: isLoadingUp, refetch } = useGetAuctionsUpQuery({
        page_size: 12,
        cat: data?.category || '',
        subcat: data?.subcategory || '',
        page: 1,
        state: ['end'],
        brand: data?.brand?.id,
        ord: 'anything',
    }, {
        refetchOnReconnect: true,
        refetchQuery: true,
        refetchOnMountOrArgChange: true,
        skip: false
    })

    const category_slag_global = category_data?.results?.find((f) => f.id === data?.category)
    const sub_category_slag_global = category_slag_global?.subcategory_set?.find((f) => f.id === data?.subcategory)

    return (
        <div className={s.main} style={{
            // height: (refLeftBox?.current?.clientHeight && !query_700) ? `${refLeftBox?.current?.clientHeight}px` : (query_700 ? 'fit-content' :'100%')
            // height: query_700 ? 'fit-content' :'100%'
        }}>
            <h5 className={s.main_title}>На аукционе еще дешевле</h5>

            <div className={s.content}>
                {data_auc?.results?.length === 0 ? <p className={s.empty}>Данного товара еще не было на аукционе</p> : <>
                    {data_auc?.results?.map((el) => {
                        const category_slag = category_data?.results?.find((f) => f.id === el?.product?.category)
                        const sub_category_slag = category_slag?.subcategory_set?.find((f) => f.id === el?.product?.subcategory)

                        return <div className={s.item} key={el?.id}>
                            <div className={s.item_left}>
                                <div className={s.img_box}>
                                    <Avatar sx={{ width: '100%', height: '100%', borderRadius: '0px' }}
                                        src={el?.product?.hero_image || null} />
                                </div>

                                <NavLink
                                    to={`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${el?.id}`}
                                    className={s.item_title}>{el?.product?.title || ''}</NavLink>
                            </div>
                            <div className={s.item_tight}>
                                <p className={s.item_tight_top}>{el?.highest_bid_amount ? `${spaceBtwHundreds(el?.highest_bid_amount)} ₽` : 'Аукцион не состоялся'}</p>
                                <p className={s.item_tight_bottom}>Цена продажи</p>
                            </div>
                        </div>
                    })}
                </>}

            </div>

            <NavLink
                to={`${PATH.AUCTIONS}/up/${category_slag_global?.slug}/${sub_category_slag_global?.slug}?brand=${data?.brand?.title}&state=run,pub`}>
                <Button className={s.reg_btn}>
                    Перейти к аукционам
                </Button>
            </NavLink>
        </div>
    );
};

export default AuctionList;