import React, {useEffect} from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {spaceBtwHundreds} from "../../../../helper/spaceBetweenSum";
import {Avatar, Tooltip} from "@mui/material";
import {useTimer} from "react-timer-hook";
import {useSelector} from "react-redux";
import {selectCategory} from "../../../../redux/slice/category_subcategory.slice";
import {PATH} from "../../../../constants/path";
import {NavLink} from "react-router-dom";

const ActiveLotItem = ({
                           isLast,
                           state = 'run',
                           is_user_bid_highest,
                           timer = 0,
                           product,
                           default_timer,
                           highest_bid,
                           id,
                           user_highest_bid,
                           closeActiveLots
                       }) => {
    const {category: category_data} = useSelector(selectCategory)
    const category_slag = category_data?.results?.find((f) => f.id === product?.category)
    const sub_category_slag = category_slag?.subcategory_set?.find((f) => f.id === product?.subcategory)

    const {
        totalSeconds,
        seconds: ss,
        minutes: mm,
        hours: hh,
        days: dd,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp: new Date(default_timer),
        autoStart: true,
    });

    useEffect(() => {
        if (default_timer) {
            restart(new Date(default_timer))
        }
    }, [default_timer])

    return (
        <NavLink to={`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${id}`}
                 style={{
                     width: '100%',
                     marginRight: '0'
                 }} onClick={closeActiveLots}>
            <div className={s.item} style={{border: isLast && 'none'}}>
                <div className={s.img_box}>
                    <Avatar src={product?.hero_image || null}
                            sx={{height: '100%', width: '100%', borderRadius: '6px'}}/>
                </div>
                <div className={s.content}>
                    <div className={s.content_top}>
                        <Tooltip title={product?.title}>
                            <h3 className={s.content_top_title}>{product?.title || ''}</h3>
                        </Tooltip>
                        <div className={classNames(s.status)}>
                            {state === 'run' && 'Идут торги'}
                        </div>
                    </div>
                    <div className={s.content_bottom}>
                        <div className={s.content_bottom_item}>
                            <p className={s.content_bottom_title}>
                                {state === 'run' && 'До окончания'}
                            </p>
                            <div className={s.dots}/>
                            <p className={s.content_bottom_sub_title}>
                                {state === 'run' && `${dd !== 0 ? `${dd}:` : ''}${hh >= 10 ? hh : `0${hh}`}:${mm >= 10 ? mm : `0${mm}`}:${ss >= 10 ? ss : `0${ss}`}`}
                            </p>
                        </div>
                        <div className={s.content_bottom_item}>
                            <p className={s.content_bottom_title}>
                                {state === 'run' && 'Текущая цена'}
                            </p>
                            <div className={s.dots}/>
                            <p className={s.content_bottom_sub_title}>{spaceBtwHundreds(highest_bid || 0)} ₽</p>
                        </div>
                        <div className={s.content_bottom_item}>
                            <p className={s.content_bottom_title}>
                                {state === 'run' && 'Моя ставка'}
                            </p>
                            <div className={s.dots}/>
                            <p className={s.content_bottom_sub_title}>
                                <span
                                    style={{color: is_user_bid_highest ? '#29B942' : 'lightcoral'}}>{spaceBtwHundreds(user_highest_bid || 0)} ₽</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </NavLink>
    );
};

export default ActiveLotItem;
