import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const BreadCrumbsV2 = ({breadcrumbs}) => {
    const filtered_breadcrumbs = breadcrumbs.filter((f) => f.title)

    return (
        <div className={s.main}>
            {filtered_breadcrumbs.map((el, i) => <p
                key={i}
                style={{
                    cursor: el.clickable && 'pointer'
                }}
                onClick={() => (filtered_breadcrumbs?.length !== (i + 1) || el?.clickable) && el?.action()}
                className={classNames(s.crumb, filtered_breadcrumbs?.length === (i + 1) && s.last)}>{el.title}
                <span
                    onClick={(e) => e.stopPropagation()} className={s.circle}></span></p>)}
        </div>
    );
};

export default BreadCrumbsV2;
