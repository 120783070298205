import React from 'react';
import s from './styles.module.css'
import {Avatar, Tooltip, useMediaQuery} from "@mui/material";
import {NavLink, useNavigate} from "react-router-dom";
import noImage from "../../../../../assets/images/no_image.jpg";
import classNames from "classnames";
import {ReactComponent as MolotIcon} from "../../../../../assets/svg/molot.svg";
import {spaceBtwHundreds} from "../../../../../helper/spaceBetweenSum";
import Button from "../../../../../common/ui_kit/button";
import {useSelector} from "react-redux";
import {selectCategory} from "../../../../../redux/slice/category_subcategory.slice";
import {PATH} from "../../../../../constants/path";

export const calcPercent = (oldPrice, newPrice) => {
    const discount = ((oldPrice - newPrice) / oldPrice) * 100;
    return `-${Math.round(discount)}%`;
}

const ItemMarketBox = ({new_price, old_price, title, img, haveInAuc, attributes, category, subcategory, id}) => {
    const navigate = useNavigate()
    const query_700 = useMediaQuery('(max-width:780px)');

    const {category: category_data} = useSelector(selectCategory)

    const category_slag = category_data?.results?.find((f) => f.id === category)
    const sub_category_slag = category_slag?.subcategory_set?.find((f) => f.id === subcategory)

    const handleGoLinkAuc = () => {
        if (haveInAuc) {
            navigate(`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${haveInAuc[0] || 0}`)
        }
    }
    const handleGoLinkProduct = () => {
        navigate(`${PATH.MARKET}/${category_slag?.slug}/${sub_category_slag?.slug}/${id}`)
    }

    const attributesConvert = (attributes && Object.keys(attributes)?.length > 0) ? (Object.keys(attributes)?.length > 4 ? Object.keys(attributes)?.slice(0, 4)?.map((key) => ({
        title: key,
        value: attributes[key]
    })) : Object.keys(attributes)?.map((key) => ({title: key, value: attributes[key]}))) : []

    return (
        <div className={s.item}>
            <div className={s.media_box}>

                <NavLink
                    style={{width: query_700 ? 'fit-content' : '100%', height: '100%'}}>
                    <Avatar
                        sx={{
                            height: '100%',
                            width: query_700 ? 'fit-content' : '100%',
                            borderRadius: query_700 ? '10px' : '12px',
                            overflow: 'hidden',
                        }}
                        src={img || noImage}
                    />
                </NavLink>
                <div className={classNames(s.title_mobile)}>
                    <NavLink t
                             style={{width: '100%', height: '100%'}}>
                        <Tooltip title={title}>
                            <span>{title}</span>
                        </Tooltip>
                    </NavLink>
                </div>
            </div>

            <div className={s.content_box}>
                <div>
                    <div className={s.title}>
                        <Tooltip title={title}>
                            <NavLink style={{width: '100%'}}>
                                <span>{title}</span>
                            </NavLink>
                        </Tooltip>
                    </div>

                    {attributes && <div className={s.attributes_box}>
                        {attributesConvert?.map((el) => {
                            return <div className={s.content_item} key={el?.title}>
                                <p className={s.content_item_title}>{el?.title}</p>
                                <Tooltip title={el?.value}><p
                                    className={s.content_item_duration}>{el?.value}</p></Tooltip>
                            </div>

                            // return <div key={key} className={s.attributes_item}>{attributes[key]}</div>
                        })}
                    </div>}

                    <div className={s.price_box}>
                        <div className={s.price_box_left}>
                            <p className={s.sub_title_price}>Цена товара</p>
                            <p className={s.title_price} data-price={`${calcPercent(old_price, new_price)}`}>
                                {`${spaceBtwHundreds(new_price)} ₽`}</p>
                        </div>

                        <div className={s.price_box_right} onClick={handleGoLinkAuc} style={{
                            cursor: haveInAuc && 'pointer'
                        }}>
                            {haveInAuc && <div className={s.molot_icon}>
                                <MolotIcon/>
                            </div>}
                            <p className={classNames(s.molot_title, !haveInAuc && s.molot_title_no_auc)}>{haveInAuc ? 'Есть на аукционе' : 'Нет на аукционе'}</p>
                        </div>
                    </div>
                </div>

                <div className={s.button_retail}>
                    <div className={s.retail}>
                        {`Старая цена — ${spaceBtwHundreds(old_price)} ₽`}
                    </div>
                    {/*<NavLink>*/}
                    <Button className={s.btn} onClick={handleGoLinkProduct}>
                        Перейти к товару
                    </Button>
                    {/*</NavLink>*/}
                </div>
            </div>
        </div>
    );
};

export default ItemMarketBox;