import React from 'react';
import s from './styles.module.css'
import {ReactComponent as Icon} from "../../../../assets/svg/modal/modal_1.svg";
import classNames from "classnames";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import CustomSelect from "../../../ui_kit/inputs/select";
import Input from "../../../ui_kit/inputs/input";
import InputMaskNumber from "../../../ui_kit/inputs/input_mask";
import Button from "../../../ui_kit/button";
import DescriptionFooterModal from "../../description_footer_modal";
import {validate} from "./validate";
import HeaderModal from "../../../../components/header_modal";
import {useWorkUsMutation} from "../../../../redux/user.service";
import DescriptionSuccessInfo from "../../../description_succes_info";

const options = [
    {value: 1, label: 'Инженер технической поддержки'},
    {value: 2, label: 'Бэкенд-разработчик'},
    {value: 3, label: 'Менеджер направления- автомобили'},
    {value: 4, label: 'Менеджер направления- недвижимость'},
    {value: 5, label: 'Бизнес-аналитик'},
];

const ModalWorkUs = ({onClose}) => {
    const [workUs, {isLoading}] = useWorkUsMutation()

    const formik = useFormik({
        initialValues: {
            vacancy: '',
            cand_resume_url: '',
            cand_fullname: '',
            cand_phone_number: '',
            cand_email: '',
        },
        validate,
        onSubmit: (values) => {
            workUs({...values, vacancy: values?.vacancy?.label})
                .unwrap()
                .then((res) => {

                    toast.success(res.detail || 'Ваша заявка отправлена!')
                })
                .catch((e) => {
                    console.log(e)
                    if (e.data?.detail) {
                        toast.error(e.data?.detail)
                    } else if (e.status === 400) {
                        for (let i = 0; i < Object.keys(e.data)?.length; i++) {
                            if (e.data[Object.keys(e.data)[i]] && e.data[Object.keys(e.data)[i]][0]) {
                                toast.error(e.data[Object.keys(e.data)[i]][0] || 'Ошибка')
                            } else {
                                toast.error('Ошибка')
                            }

                        }
                    } else {
                        toast.error('Ошибка')
                    }
                })
        }
    })

    return (
        <div className={s.content}>
            <HeaderModal isMobile={true} onClose={onClose} classNameTitle={s.icon_title} title={<div className={s.icon}>
                <Icon/>
            </div>} typeModal={1}/>

            <div className={s.header_title_box}>
                <div className={classNames(s.icon, s.icon_mobile)}>
                    <Icon/>
                </div>
                <h3 className={s.title}>Хотите работать в ITEMBUY?</h3>
                <p className={s.sub_title}>Выберите вакансию из списка</p>
            </div>
            <form onSubmit={(e) => {
                e.preventDefault()
                formik.handleSubmit()
            }} className={s.main_action}>
                <div className={s.action}>
                    <CustomSelect placeholder={'Выберите вакансию'}
                                  name={'vacancy'}
                                  errorMessage={formik.touched.vacancy && formik.errors.vacancy}
                                  options={options} value={formik.values.vacancy}
                                  onChange={(e) => formik.setFieldValue('vacancy', e)}/>

                    <Input value={formik.values.cand_fullname}
                           onBlur={formik.handleBlur}
                           name={'cand_fullname'}
                           errorMessage={formik.touched.cand_fullname && formik.errors.cand_fullname}
                           placeholder={'ФИО'}
                           onChange={formik.handleChange}/>

                    <Input value={formik.values.cand_email}
                           onBlur={formik.handleBlur}
                           name={'cand_email'}
                           errorMessage={formik.touched.cand_email && formik.errors.cand_email}
                           placeholder={'Email'}
                           onChange={formik.handleChange}/>

                    <InputMaskNumber onBlur={formik.handleBlur} value={formik.values.cand_phone_number}
                                     placeholder={'+7 000 000-00-00'}
                                     mask="+7 (999) 999-99-99"
                                     errorMessage={formik.touched.cand_phone_number && formik.errors.cand_phone_number}
                                     name={'cand_phone_number'}
                                     onChange={formik.handleChange}/>
                    <Input value={formik.values.cand_resume_url}
                           onBlur={formik.handleBlur}
                           name={'cand_resume_url'}
                           errorMessage={formik.touched.cand_resume_url && formik.errors.cand_resume_url}
                           placeholder={'Ссылка на резюме'}
                           onChange={formik.handleChange}/>

                </div>

                <div className={s.btn}>
                    <Button type={'submit'}
                            disabled={isLoading}
                    >Отправить</Button>
                    <DescriptionSuccessInfo/>
                </div>
            </form>
        </div>
    );
};

export default ModalWorkUs;
