import React from 'react';
import {Box, Modal, Stack, useMediaQuery} from "@mui/material";

const style = {
    paddingRight: '20px',
    maxHeight: '90%',
    height: 'fit-content',
    overflow: 'auto',
};

const styleS = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '440px',
    width: '100%',
    padding: '24px',
    paddingRight: '4px',
    background: ' #FFFFFF',
    borderRadius: '16px',
    maxHeight: '98%',
}

const full_height = {
    minHeight: '100dvh',
    maxHeight: '100dvh',
    height: '100dvh',
    maxWidth: '100%',
    borderRadius: '0px',
    padding: '0',
    paddingRight: '0',
    paddingBottom: '16px'
}
const full_heightS = {
    maxHeight: '100%',
    height: '100%',
    maxWidth: '100%',
    overflow: 'unset',
    overflowX: 'auto',
    paddingRight: '0',
    paddingBottom: '0'
}

const CustomModal = ({children, width = '440px', open, onClose, zIndex = false}) => {
    const query_700 = useMediaQuery('(max-width:780px)');
    return (
        <Modal
            sx={{
                zIndex: zIndex || 999999998,

            }}
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack
                sx={query_700 ? {...styleS, maxWidth: width ? width : styleS?.maxWidth, ...full_height} : {
                    ...styleS,
                    maxWidth: width ? width : styleS?.maxWidth
                }}>
                <Box sx={query_700 ? {...style, ...full_heightS} : style}>
                    {children}
                </Box>
            </Stack>
        </Modal>
    )
};

export default CustomModal;
