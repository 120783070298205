import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import HeaderModal from "../../../../components/header_modal";
import {ReactComponent as Active} from "../../../../assets/svg/radio_active.svg";
import {ReactComponent as UnActive} from "../../../../assets/svg/radio_unactive.svg";
import {useFormik} from "formik";
import {validate} from "./validate";
import Input from "../../../../common/ui_kit/inputs/input";
import CustomSelect from "../../../../common/ui_kit/inputs/select";
import TitleMobileAuth from "../../../../common/title_auth_mobile";
import InputMaskNumber from "../../../../common/ui_kit/inputs/input_mask";
import {useLoginMutation, useMakeActiveMutation, useRegistrationQueryMutation} from "../../../../redux/auth.service";
import {toast} from "react-toastify";
import {cleanPhoneNumber} from "../../../../helper/clearNumber";
import {useGetUserInfoMutation} from "../../../../redux/user.service";
import {handlerError} from "../../../../helper/handlerError";
import {setIsAuth} from "../../../../redux/slice/global.slice";
import {useDispatch} from "react-redux";
import RegistrationCode from "../registration_code";
import {useLocalOrSessionStorage} from "../../../../helper/useLocalStorage";
import ButtonsAction from "./buttonsAction";
import CustomModal from "../../../../common/modal/custom_modal";
import DescriptionSuccessInfo from "../../../../common/description_succes_info";
import {useSearchParams} from "react-router-dom";

const options_month = [
    {label: 'Январь', value: 1},
    {label: 'Февраль', value: 2},
    {label: 'Март', value: 3},
    {label: 'Апрель', value: 4},
    {label: 'Май', value: 5},
    {label: 'Июнь', value: 6},
    {label: 'Июль', value: 7},
    {label: 'Август', value: 8},
    {label: 'Сентябрь', value: 9},
    {label: 'Октябрь', value: 10},
    {label: 'Ноябрь', value: 11},
    {label: 'Декабрь', value: 12},
];

const daysInMonth = {
    'Январь': 31,
    'Февраль': 28,
    'Март': 31,
    'Апрель': 30,
    'Май': 31,
    'Июнь': 30,
    'Июль': 31,
    'Август': 31,
    'Сентябрь': 30,
    'Октябрь': 31,
    'Ноябрь': 30,
    'Декабрь': 31,
}


const RegistrationData = ({onClose, setStep, step, number_login, email_login}) => {
    const [isDateFieldTouched, setIsDateFieldTouched] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const [lastTouchedField, setLastTouchedField] = useState(null);
    const dispatch = useDispatch()
    const [registrationQuery] = useRegistrationQueryMutation()
    const [makeActive] = useMakeActiveMutation()
    const [login] = useLoginMutation()
    const [getUserInfo] = useGetUserInfoMutation()

    const date = new Date()
    const filteredYears = []

    for (let i = 1950; i < date.getFullYear() - 17; i++) {
        filteredYears.push({value: i, label: i})
    }
    const [loading, setLoading] = useState(false)
    const [openModalCode, setOpenModalCode] = useState(false)

    const [error, setError] = useLocalOrSessionStorage('reg', '')
    const [timerResetSms, setTimerResetSms] = useLocalOrSessionStorage('timer_reset', '')

    const formik = useFormik({
        initialValues: {
            // last_name: '',
            // first_name: '',
            // middle_name: '',
            // day: '',
            // month: '',
            // year: '',
            // gender: '',
            password: '',
            re_password: '',
            email: email_login || '',
            number: number_login || '',
            otp: null,
            invited_by_user_token: localStorage.getItem('invited_by_user_token') || ''
        },
        validate: validate,
        onSubmit: (values) => {
            setLoading(true)
            registrationQuery({
                // gKX5lI36QB2rRn2VhATa9A
                // birthday: `${values.year.value}-${values.month.value}-${values.day.value}`,
                email: values.email,
                number: `+${cleanPhoneNumber(values.number)}`,
                password: values.password,
                // first_name: values.first_name,
                // last_name: values.last_name,
                otp: values.otp,
                // middle_name: values.middle_name,
                // gender: values.gender === 'woman' ? 'f' : 'm',
                invited_by_user_token: values?.invited_by_user_token || 0
            }).unwrap().then(res => {
                if (res?.code) {
                    setOpenModalCode(true)
                    setTimerResetSms({
                        date: res.res_at || '',
                        number: values.number
                    })
                    localStorage.removeItem('invited_by_user_token')
                    searchParams.delete('invited_by_user_token')
                    setSearchParams(searchParams)
                    setLoading(false)
                    setError('')
                    formik.setFieldValue('otp', null)
                    toast.success(res.detail || '')
                } else {
                    login({
                        login: values.email,
                        password: values.password,
                        type: 'email'
                    }).unwrap().then((res) => {

                        getUserInfo().unwrap().then((res) => {
                            dispatch(setIsAuth(true))
                            setLoading(false)
                            setStep('confirm_reg')
                            setError('')
                        }).catch((e) => {
                            setLoading(false)
                            console.log(e)
                        })

                    }).catch((e) => {
                        console.log(e)
                        setLoading(false)
                        if (e && e.data) {
                            for (let i = 0; i < Object.keys(e.data)?.length; i++) {
                                toast.error(e.data[Object.keys(e.data)[i]])
                            }
                        } else {
                            toast.error('Ошибка')
                        }

                    })
                }
            }).catch((e) => {
                console.log(e)
                if (e?.data?.code === 303) {
                    setError({
                        ...e?.data,
                        mobile: values.number
                    })
                    setLoading(false)
                    toast.error(e?.data?.detail || 'Ошибка')
                } else if (e?.data?.code === 305) {
                    toast.error(e?.data?.detail || 'Ошибка')
                    setError({
                        ...e?.data,
                        mobile: values.number
                    })
                    formik.setFieldValue('otp', null)
                    setOpenModalCode(false)
                    setLoading(false)
                } else if (e?.data?.code === 302) {
                    setError({
                        ...e?.data,
                        mobile: values.number
                    })

                    setTimerResetSms({
                        date: e?.data?.res_at || '',
                        number: values.number
                    })
                    setOpenModalCode(true)
                    setLoading(false)
                } else {
                    setLoading(false)
                    handlerError(e, (e) => setError({...e, mobile: values.number}))
                }

            })
        }
    })

    useEffect(() => {
        if (searchParams?.get('ref')) {
            formik.setFieldValue('invited_by_user_token', searchParams?.get('ref'))
            localStorage.setItem('invited_by_user_token', searchParams?.get('ref'))
        }
    }, [searchParams])

    // const days = Array.from({length: (!formik.values.month.label || formik.values.month.label === '') ? 31 : daysInMonth[formik.values.month.label]}, (v, k) => ({
    //     value: (k++) + 1,
    //     label: (k++)
    // }))

    // useEffect(() => {
    //     if (formik.values.day.value >= daysInMonth[formik.values.month.label]) {
    //         formik.setFieldValue('day', {
    //             value: daysInMonth[formik.values.month.label],
    //             label: daysInMonth[formik.values.month.label]
    //         })
    //     }
    // }, [formik.values.month])

    // useEffect(() => {
    //     const arrayTouchedClear = lastTouchedField ? [...Object.keys(formik.touched)?.filter(f => f !== lastTouchedField), lastTouchedField] : Object.keys(formik.touched)
    //
    //     const arrayTouched = arrayTouchedClear.filter(item => formik.touched[item]);
    //     console.log(arrayTouched)
    //     console.log(formik.touched)
    //
    //     function isSubset(arr1, arr2) {
    //         return arr1.every(element => arr2?.includes(element));
    //     }
    //
    //     function hasIntersection(arr1, arr2) {
    //         return arr1.some(element => arr2?.includes(element));
    //     }
    //
    //     const findArray = ['day', 'month', 'year']
    //
    //     if (!arrayTouched || arrayTouched?.length === 0) {
    //         return
    //     } else {
    //         if (formik?.submitCount !== 0 && (!formik?.values?.day || !formik?.values?.month || !formik?.values?.year)) {
    //             setIsDateFieldTouched(true)
    //         } else if (!isSubset(findArray, arrayTouched) && findArray?.includes(arrayTouched?.at(-1))) {
    //             console.log('last click')
    //             setIsDateFieldTouched(false)
    //         } else if (isSubset(findArray, arrayTouched)) {
    //             setIsDateFieldTouched(false)
    //         } else if (!hasIntersection(findArray, arrayTouched)) {
    //             setIsDateFieldTouched(false)
    //         } else if (!isSubset(findArray, arrayTouched) && hasIntersection(findArray, arrayTouched) && !findArray?.includes(arrayTouched?.at(-1))) {
    //             setIsDateFieldTouched(true)
    //         } else {
    //             setIsDateFieldTouched(true)
    //         }
    //     }
    // }, [formik?.touched, lastTouchedField, formik?.submitCount])

    // useEffect(() => {
    //     console.log(formik)
    //     if (formik?.errors?.day && formik?.submitCount > 0) {
    //         setIsDateFieldTouched(true)
    //         formik.setFieldTouched('day', false)
    //         formik.setFieldTouched('month', false)
    //         formik.setFieldTouched('year', false)
    //     }
    // }, [formik?.submitCount, formik?.errors?.day])

    return (
        <div>

            <CustomModal open={openModalCode}
                         onClose={() => {
                         }}>
                <RegistrationCode loading={loading} setTimerResetSms={setTimerResetSms}
                                  timerResetSms={timerResetSms}
                                  regFormik={formik} setError={setError}
                                  error={error} step={{
                    enter_type: 'mobile'
                }} valueToSend={formik.values.number} onClose={() => {
                    formik.setFieldValue('otp', null)
                    setError(null)
                    setOpenModalCode(false)
                }}/>
            </CustomModal>

            <HeaderModal onClose={onClose} isMobile={true} title={'Регистрация'}
                         typeModal={0}/>

            <TitleMobileAuth top={24} title={'Регистрация на ITEMBUY'} pl={16} align={'left'}/>
            <form className={s.form} onSubmit={(e) => {
                e.preventDefault()
                formik.handleSubmit()
            }}>
                <div className={s.full_name_box}>
                    <InputMaskNumber onBlur={(e) => {
                        formik.handleBlur(e)
                        setLastTouchedField('number')
                    }}
                                     name={'number'}
                                     title={'Номер телефона'}
                                     maskChar={null}
                                     mask="+7 (999) 999-99-99"
                                     placeholder={'+7 (000) 000-00-00'}
                                     value={formik.values.number}
                                     errorMessage={formik.touched.number && formik.errors.number}
                                     onChange={formik.handleChange}
                    />
                    <Input className={s.email_input}
                           onBlur={(e) => {
                               formik.handleBlur(e);
                               formik.setFieldTouched('email', true, true);
                               setLastTouchedField('email')
                           }}
                           errorMessage={formik.touched.email && formik.errors.email}
                           onChange={formik.handleChange}
                           name={'email'}
                           value={formik.values.email}
                           title={'Почта'}
                           isNeedTitle={false} placeholder={'Почта / Email'}/>

                    {/*<Input value={formik.values.last_name}*/}
                    {/*       onChange={formik.handleChange}*/}
                    {/*       name={'last_name'}*/}
                    {/*       onBlur={(e) => {*/}
                    {/*           formik.handleBlur(e)*/}
                    {/*           setLastTouchedField('last_name')*/}
                    {/*       }}*/}
                    {/*       errorMessage={formik.touched.last_name && formik.errors.last_name}*/}
                    {/*       title={'Фамилия'}*/}
                    {/*       placeholder={'Введите фамилию'}/>*/}

                    {/*<Input value={formik.values.first_name}*/}
                    {/*       onChange={formik.handleChange}*/}
                    {/*       name={'first_name'}*/}
                    {/*       onBlur={(e) => {*/}
                    {/*           formik.handleBlur(e)*/}
                    {/*           setLastTouchedField('first_name')*/}
                    {/*       }}*/}
                    {/*       errorMessage={formik.touched.first_name && formik.errors.first_name}*/}
                    {/*       title={'Имя'}*/}
                    {/*       placeholder={'Введите имя'}/>*/}

                    {/*<Input value={formik.values.middle_name}*/}
                    {/*       onChange={formik.handleChange}*/}
                    {/*       name={'middle_name'}*/}
                    {/*       onBlur={(e) => {*/}
                    {/*           formik.handleBlur(e)*/}
                    {/*           setLastTouchedField('middle_name')*/}
                    {/*       }}*/}
                    {/*       errorMessage={formik.touched.middle_name && formik.errors.middle_name}*/}
                    {/*       title={'Отчество'}*/}
                    {/*       placeholder={'Введите отчество'}/>*/}

                    {/*<Input value={formik.values.invited_by_user_token}*/}
                    {/*       onChange={formik.handleChange}*/}
                    {/*       name={'invited_by_user_token'}*/}
                    {/*       onBlur={(e) => {*/}
                    {/*           formik.handleBlur(e)*/}
                    {/*           setLastTouchedField('invited_by_user_token')*/}
                    {/*       }}*/}
                    {/*       errorMessage={formik.touched.invited_by_user_token && formik.errors.invited_by_user_token}*/}
                    {/*       title={'ID пригласившего'}*/}
                    {/*       placeholder={'Введите ID'}/>*/}
                </div>

                {/*<div className={s.birthday_box}>*/}
                {/*    <h3 className={s.title}>Дата рождения</h3>*/}
                {/*    <TitleMobileAuth top={24} title={'Дата рождения'} align={'left'}/>*/}
                {/*    <div className={s.select}>*/}
                {/*        <CustomSelect height={5} options={days} placeholder={'День'}*/}
                {/*                      name={'day'}*/}
                {/*                      onChange={(value) => {*/}
                {/*                          formik.setFieldValue('day', value)*/}
                {/*                          formik.setFieldTouched('day', true)*/}
                {/*                          setLastTouchedField('day')*/}
                {/*                      }}*/}
                {/*                      value={formik.values.day}/>*/}
                {/*        <CustomSelect height={5} placeholder={'Месяц'}*/}
                {/*                      options={options_month}*/}
                {/*                      name={'month'}*/}
                {/*                      onChange={(value) => {*/}
                {/*                          formik.setFieldValue('month', value)*/}
                {/*                          formik.setFieldTouched('month', true)*/}
                {/*                          setLastTouchedField('month')*/}
                {/*                      }}*/}
                {/*                      value={formik.values.month}/>*/}
                {/*        <CustomSelect height={5} options={filteredYears?.reverse()}*/}
                {/*                      placeholder={'Год'} name={'year'}*/}
                {/*                      onChange={(value) => {*/}
                {/*                          formik.setFieldValue('year', value)*/}
                {/*                          formik.setFieldTouched('year', true)*/}
                {/*                          setLastTouchedField('year')*/}
                {/*                      }}*/}
                {/*                      value={formik.values.year}/>*/}
                {/*    </div>*/}
                {/*    <span*/}
                {/*        className={s.errorMessage}>{isDateFieldTouched && formik.errors.day}</span>*/}
                {/*</div>*/}

                {/*<div className={s.gender_box}>*/}
                {/*    <h3 className={s.title}>Пол</h3>*/}
                {/*    <div className={s.box}>*/}
                {/*        <div className={s.checkbox}>*/}
                {/*            <div className={s.checked} onClick={() => {*/}
                {/*                formik.setFieldTouched('gender', true)*/}
                {/*                formik.setFieldValue('gender', 'man')*/}
                {/*                setLastTouchedField('gender')*/}
                {/*            }}>*/}
                {/*                {formik.values.gender === 'man' ? <Active/> : <UnActive/>}*/}
                {/*            </div>*/}
                {/*            <p onClick={() => {*/}
                {/*                formik.setFieldTouched('gender', true)*/}
                {/*                formik.setFieldValue('gender', 'man')*/}
                {/*                setLastTouchedField('gender')*/}
                {/*            }}>Мужской</p>*/}
                {/*        </div>*/}
                {/*        <div className={s.checkbox}>*/}
                {/*            <div className={s.checked} onClick={() => {*/}
                {/*                formik.setFieldTouched('gender', true)*/}
                {/*                formik.setFieldValue('gender', 'woman')*/}
                {/*                setLastTouchedField('gender')*/}
                {/*            }}>*/}
                {/*                {formik.values.gender === 'woman' ? <Active/> : <UnActive/>}*/}
                {/*            </div>*/}
                {/*            <p onClick={() => {*/}
                {/*                formik.setFieldTouched('gender', true)*/}
                {/*                formik.setFieldValue('gender', 'woman')*/}
                {/*                setLastTouchedField('gender')*/}
                {/*            }}>Женский</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <span className={s.errorMessage}>{formik.touched.gender && formik.errors.gender}</span>*/}
                {/*</div>*/}

                <div className={s.password_box}>
                    <h3 className={s.title}>Придумайте пароль</h3>
                    <div className={s.inputs}>
                        <Input value={formik.values.password}
                               onChange={formik.handleChange}
                               name={'password'}
                               onBlur={(e) => {
                                   formik.handleBlur(e)
                                   setLastTouchedField('password')
                               }}
                               errorMessage={formik.touched.password && formik.errors.password}
                               title={'Пароль'}
                               placeholder={'Пароль'}
                               type={'password'}
                        />
                        <Input value={formik.values.re_password}
                               onChange={formik.handleChange}
                               name={'re_password'}
                               onBlur={(e) => {
                                   formik.handleBlur(e)
                                   setLastTouchedField('re_password')
                               }}
                               errorMessage={formik.touched.password && formik.errors.re_password}
                               title={'Пароль'}
                               placeholder={'Повторите пароль'}
                               type={'password'}/>
                    </div>
                </div>

                <ButtonsAction number={formik.values.number} loading={loading}
                               formik={formik} error={error}
                               setError={setError}/>
            </form>
            <DescriptionSuccessInfo/>
        </div>
    );
};

export default RegistrationData;
