import React, {useEffect, useRef, useState} from 'react';
import s from "./styles.module.css";
import {IconButton, useMediaQuery} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {motion} from 'framer-motion';
const Records = ({dataConvert}) => {
    const ref = useRef()
    const data = Object.keys(dataConvert)?.length > 0 ? Object.keys(dataConvert)?.map((key) => ({
        title: key,
        value: dataConvert[key]
    })) : []
    const query_700 = useMediaQuery('(max-width:780px)');
    const [isExpanded, setIsExpanded] = useState(true);
    const [showMore, setShowMore] = useState(false)
    const [currentHeight, setCurrentHeight] = useState('auto')

    const typeShowData = showMore ? data : data?.slice(0, 4)

    useEffect(() => {
        const fullHeight = ref.current.scrollHeight;
        const children = ref.current.children;
        let totalHeight = 0;

        if (data && data?.length > 4) {
            // Проходимся по всем дочерним элементам и складываем их высоту
            Array.from(children).forEach((child) => {
                const childHeight = child.getBoundingClientRect().height;

                totalHeight += childHeight;
            });

            setCurrentHeight(!showMore ? `${totalHeight + ((query_700 ? 8 : 32) * 3)}px` : `${fullHeight}px`)
        } else {
            setCurrentHeight('auto')
        }

    }, [showMore, query_700, data])


    return (
        <div className={s.description} id={'records'}>

            <div className={s.description_left}>
                <h2>Характеристики</h2>
                <div className={s.hidden}
                     onClick={() => setIsExpanded(!isExpanded)}>
                    <IconButton>
                        {!isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </IconButton>
                </div>
            </div>
            <motion.div
                className={s.description_right}
                initial={false}
                animate={{height: !isExpanded ? 'auto' : 0}}
                style={{overflow: 'hidden'}} // Скрываем содержимое при сворачивании
                transition={{duration: 0.5}} // Плавность анимации
            >
                <div className={s.items_box} ref={ref} style={{
                    height: currentHeight
                }}>
                    {(typeShowData?.map((el) => {
                        return <div className={s.description_right_content} key={el?.id}>
                            <div className={s.description_right_sub_content_item}>
                                <p className={s.description_right_sub_content_title}>{el?.title}</p>
                                <div className={s.line}/>
                                <p className={s.description_right_sub_content_sub_title}
                                   style={{whiteSpace: el?.value?.length > 40 ? 'normal' : 'nowrap'}}>{el?.value}</p>
                            </div>
                        </div>
                    }))}
                </div>
                {data && data?.length > 4 && <div className={s.showMore} onClick={() => {
                    setShowMore(!showMore)
                }}>
                    {!showMore && 'Показать все'}
                    {showMore && 'Скрыть'}
                </div>}
            </motion.div>

        </div>
    );
};

export default Records;
