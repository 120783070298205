import React from 'react';
import Select from 'react-select';
import s from './styles.module.css'

const CustomSelect = ({options, value, onBlur, onChange, placeholder, name = '', errorMessage, height = 9}) => {

    const colourStyles = {
        control: (styles) => ({
            display: 'flex',
            border: '1px solid #F4F5F6',
            width: '100%',
            padding: `${height}px 4px`,
            backgroundColor: '#F4F5F6',
            borderRadius: '12px',
            cursor: 'pointer',


        }),
        menu: (styles) => ({
            ...styles,
            boxSizing: 'border-box !important',
            margin: '8px 1px'
        }),
        option: (styles) => ({
            ...styles,
            padding: '10px',
            cursor: 'pointer'
        }),
        placeholder: (styles) => ({
            ...styles,
            fontWeight: '400',
            fontSize: '16px',
            color: '#7A808B',
            fontFamily: 'Inter, sans-serif'
        }),
    };
    return (
        <div>
            <Select
                id={name}
                name={name}
                inputId={name}
                onBlur={onBlur}
                isSearchable={false}
                styles={colourStyles}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                options={options}
            />
            <span className={s.errorMessage}>{errorMessage}</span>
        </div>
    );
};

export default CustomSelect;
