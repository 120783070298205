import React, {useEffect, useState} from 'react';
import {Box, Modal, Stack, useMediaQuery} from "@mui/material";
import Screen1 from "./1_screen";
import LoginPassword from "./login/login_password";
import ConfirmModal from "../../components/confirm_modal";
import LoginCode from "./login/login_code";
import RegistrationCode from "./registration/registration_code";
import RegistrationData from "./registration/registration_data";
import LoginReset from "./login/login_reset";
import LoginNewPassword from "./login/login_new_password";
import {useFormik} from "formik";
import {validate_email, validate_number} from "./validate";
import CustomModal from "../../common/modal/custom_modal";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectIsAuth} from "../../redux/slice/global.slice";
import {toast} from "react-toastify";

const style = {
    paddingRight: '20px',
    maxHeight: '90%',
    height: 'fit-content',
    overflow: 'auto',
};

const styleS = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '440px',
    width: '100%',
    padding: '24px',
    paddingRight: '4px',
    background: ' #FFFFFF',
    borderRadius: '16px',
    maxHeight: '90%',
}

const full_height = {
    minHeight: '100dvh',
    maxHeight: '100dvh',
    height: '100dvh',
    maxWidth: '100%',
    borderRadius: '0px',
    padding: '0',
    paddingRight: '0'
}
const full_heightS = {
    maxHeight: '100%',
    maxWidth: '100%',
    overflow: 'unset',
    overflowX: 'auto',
    paddingRight: '0',
    paddingBottom: '16px'
}

const Auth = ({open, onClose, login}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {isAuth} = useSelector(selectIsAuth)

    const query_700 = useMediaQuery('(max-width:780px)');

    const [loginOrReg, setLoginOrReg] = useState(1)
    const [enterChoose, setEnterChoose] = useState(1)
    const [step, setStep] = useState({
        step: 1,
        // login or registration
        auth_type: '',
        // mobile or email
        enter_type: ''
    })

    const formikEmail = useFormik({
        initialValues: {
            email: '',
        },
        validate: validate_email,
        onSubmit: () => {
            setStep({
                step: 2,
                auth_type: loginOrReg === 1 ? 'login' : 'registration',
                enter_type: enterChoose === 1 ? 'mobile' : 'email'
            })
        }
    })
    const formikPhone = useFormik({
        initialValues: {
            number: '',
        },
        validate: validate_number,
        onSubmit: () => {
            setStep({
                step: 2,
                auth_type: loginOrReg === 1 ? 'login' : 'registration',
                enter_type: enterChoose === 1 ? 'mobile' : 'email'
            })
        },

    })

    useEffect(() => {
        if (isAuth) {
            toast.success('Вы в системе')
            onClose()
        }
    }, [isAuth])

    useEffect(() => {
        if (searchParams?.get('registration') === 'open') {
            setLoginOrReg(2)
            setStep({
                step: 3,
                auth_type: 'registration',
                enter_type: enterChoose === 1 ? 'mobile' : 'email'
            })
            searchParams?.delete('registration')
            setSearchParams(searchParams)
        }
    }, [searchParams])
    return (
        <CustomModal open={open}
                     zIndex={999999997}
                     onClose={onClose}>
            {step.step === 1 &&
                <Screen1 setLoginOrReg={setLoginOrReg}
                         setStep={setStep}
                         formikEmail={formikEmail}
                         formikPhone={formikPhone}
                         enterChoose={enterChoose}
                         setEnterChoose={setEnterChoose}
                         onClose={onClose}
                         login={login}
                />}

            {(step.step === 2 && step.auth_type === 'login') &&
                <LoginPassword login={login}
                               enterChoose={enterChoose === 1 ? 'phone_number' : 'email'}
                               valueToSend={step.enter_type === 'mobile' ? formikPhone.values.number : formikEmail.values.email}
                               onClose={onClose} setStep={setStep} step={step}/>}

            {(step.step === 3 && step.auth_type === 'login') &&
                <LoginCode login={login}
                           valueToSend={step.enter_type === 'mobile' ? formikPhone.values.number : formikEmail.values.email}
                           onClose={onClose} setStep={setStep} step={step}/>}
            {(step.step === 4 && step.auth_type === 'login') &&
                <LoginReset
                    onClose={onClose}
                    handleChange={{
                        mobile: (e) => formikPhone.setFieldValue('number', e.target.value),
                        email: (e) => formikEmail.setFieldValue('email', e.target.value),
                    }}
                    valueToSend={step.enter_type === 'mobile' ? formikPhone.values.number : formikEmail.values.email}
                    setStep={setStep} step={step}/>}
            {(step.step === 5 && step.auth_type === 'login') &&
                <LoginCode login={login}
                           isReset={true}
                           valueToSend={step.enter_type === 'mobile' ? formikPhone.values.number : formikEmail.values.email}
                           onClose={onClose} setStep={setStep} step={step}/>}
            {(step.step === 6 && step.auth_type === 'login') &&
                <LoginNewPassword
                    onClose={onClose} setStep={setStep} step={step}/>}


            {(step.step === 2 && step.auth_type === 'registration') &&
                <RegistrationCode
                    valueToSend={step.enter_type === 'mobile' ? formikPhone.values.number : formikEmail.values.email}
                    onClose={onClose} setStep={setStep} step={step}/>}
            {(step.step === 3 && step.auth_type === 'registration') &&
                <RegistrationData
                    number_login={formikPhone?.values?.number}
                    email_login={formikEmail?.values?.email}
                    onClose={onClose} login={login} setStep={setStep} step={step}/>}


            {step === 'confirm_reg' && <ConfirmModal sub_title={<>
                <b>Регистрация почти завершена.</b> <br/>
                Для полного доступа к платформе, необходимо подтвердить email
            </>} onClose={onClose}/>}
            {step === 'confirm' && <ConfirmModal sub_title={'Удачных покупок!'} onClose={onClose}/>}
        </CustomModal>
    );
};

export default Auth;
