import React from 'react';
import s from './styles.module.css'
import {Avatar, Box, IconButton, Modal} from "@mui/material";
import {ReactComponent as Close} from "../../../../../assets/svg/close.svg";
import Button from "../../../../../common/ui_kit/button";
import StartPrice from "../../start_price";
import {NavLink} from "react-router-dom";
import {PATH} from "../../../../../constants/path";
import {useSelector} from "react-redux";
import {selectCategory} from "../../../../../redux/slice/category_subcategory.slice";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '1400px',
    width: '100%',
    maxHeight: '700px',
    height: '100%',
    overflow: 'auto',
    padding: '24px',
    background: ' #FFFFFF',
    borderRadius: '16px',
    '@media screen and (max-width: 780px)': {
        width: '100%',
        maxWidth: '100%',

        height: '100dvh',
        overflow: 'auto',
        borderRadius: '0px',
        padding: '12px 16px 16px 16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },

};

const ViewModal = ({open, onClose, handleAddedBucket, isLoadingAdded}) => {

    const data = Object.entries(open?.attributes)?.length > 0
        ? Object.entries(open?.attributes).map(([key, value]) => ({
            title: value,
            value: key
        }))
        : [];

    const {category: category_data} = useSelector(selectCategory)

    const category_slag = category_data?.results?.find((f) => f.id === open?.category)
    const sub_category_slag = category_slag?.subcategory_set?.find((f) => f.id === open?.subcategory)


    return (
        <Modal
            sx={{
                zIndex: 99999999
            }}
            open={Boolean(open)}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={{
                ...style,
                // '@media (max-width: 460px)': {
                //     width: '95%'
                // }
            }}>
                <div className={s.content}>
                    <div className={s.content_left}>
                        <Avatar src={open?.hero_image || null} sx={{

                            height: '100%',
                            width: '100%',
                            borderRadius: '0px'
                        }}/>
                    </div>
                    <div className={s.content_right}>
                        <div className={s.header}>
                            <p className={s.title}>{open?.title}</p>
                            <IconButton onClick={onClose}>
                                <Close/>
                            </IconButton>
                        </div>

                        <div className={s.info_content}>
                            <div className={s.info_content_box}>
                                <h3 className={s.info_content_box_title}>Информация</h3>
                                <div className={s.info_box}>
                                    <div className={s.content_left_mobile}>
                                        <Avatar src={open?.hero_image || null} sx={{

                                            height: '100%',
                                            width: '100%',
                                            borderRadius: '0px'
                                        }}/>
                                    </div>
                                    <StartPrice visibleTitle={false} price={open?.shop_price}
                                                old_price={open?.retail_price}
                                                title={'title'}/>

                                    <p className={s.added_basket} onClick={() => {
                                        if (!isLoadingAdded) {
                                            handleAddedBucket(open?.id)
                                        }
                                    }}>Добавить в корзину</p>
                                </div>
                            </div>
                            <div className={s.info_content_box}>
                                <h3 className={s.info_content_box_title}>Характеристики</h3>

                                <div className={s.items}>
                                    {(data?.map((el) => {
                                        return <div className={s.description_right_content} key={el?.id}>
                                            <div className={s.description_right_sub_content_item}>
                                                <p className={s.description_right_sub_content_title}>{el?.value}</p>
                                                <div className={s.line}/>
                                                <p className={s.description_right_sub_content_sub_title}
                                                   style={{whiteSpace: el?.value?.length > 40 ? 'normal' : 'nowrap'}}>{el?.title}</p>
                                            </div>
                                        </div>
                                    }))}
                                </div>
                            </div>
                        </div>

                        <div className={s.btns}>
                            <NavLink
                                to={`${PATH.MARKET}/${category_slag?.slug}/${sub_category_slag?.slug}/${open?.id}`}>
                                <Button>Перейти к товару</Button>
                            </NavLink>
                            {/*<Button className={s.reg}>Добавить в корзину</Button>*/}
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default ViewModal;