import React, {createContext, useEffect} from 'react';
import {useGetAuctionQuery, useGetProductQuery} from "../../redux/auctions.service";
import {useLocation, useParams} from "react-router-dom";
import ErrorModal from "./modals/error_modal";
import SkeletonAuctionUpInfo from "../../skeleton_pages/skeleton_auction_up_info";
import {useSelector} from "react-redux";
import {selectCategory} from "../../redux/slice/category_subcategory.slice";
import {selectAuctions} from "../../redux/slice/auctions.slice";
import {useMediaQuery} from "@mui/material";
import {selectIsAuth} from "../../redux/slice/global.slice";
import HelmetHeader from "../../common/helment";
import MarketItemInfo from "./index";

export const ContextSocket = createContext(null)
export const getRandom = (min, max) => Math.random() * (max - min) + min;

const MarketInfo = React.memo(() => {
        const query_700 = useMediaQuery('(max-width:780px)');
        const {block} = useSelector(selectIsAuth)
        const {pathname} = useLocation()
        const {productRoom: data} = useSelector(selectAuctions)
        const {category: category_data} = useSelector(selectCategory)
        const {type, category, category_item, product_id} = useParams()

        const {error, isLoading, isFetching, refetch} = useGetProductQuery(product_id, {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
            skip: !product_id
        })

        let isLoadingData = isLoading
        let isError = false

        const CATEGORY = (data && category) ? category_data?.results?.find((f) => (f?.slug?.toLowerCase() === category?.toLowerCase()) && (f.id === data?.category)) : null
        const CATEGORY_ITEM = (CATEGORY && category_item) ? CATEGORY?.subcategory_set?.find((f) => (f.slug?.toLowerCase() === category_item?.toLowerCase()) && ((f.id === data?.subcategory))) : null

        if (error) {
            isLoadingData = true
        }

        if (!isFetching && !isLoading) {
            if (!CATEGORY || !CATEGORY_ITEM) {
                isLoadingData = true
                isError = true
            }
        }

        useEffect(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, [pathname])

        if ((isLoadingData || isFetching)) return <>
            {((error || isError) && block === false) && <ErrorModal/>}
            <SkeletonAuctionUpInfo/>
        </>
        return (
            <ContextSocket.Provider value={{}}>
                <HelmetHeader title={`ITEMBUY: ${data?.title}`}
                              keywords={data?.keywords || null}
                              description={'Посмотрите подробности о лоте на ITEMBUY. Узнайте актуальную цену, описание товара и историю ставок. Примите участие в торгах и станьте обладателем уникального товара.'}/>

                <MarketItemInfo
                    category_item={category_item}
                    category={category} type={type}
                    CATEGORY_ITEM={CATEGORY_ITEM}
                    CATEGORY={CATEGORY}
                    data={data}
                    images_data={data?.images}
                />
            </ContextSocket.Provider>
        );
    })
;

export default MarketInfo;
