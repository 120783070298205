import {createSlice} from "@reduxjs/toolkit";

export const socketGlobalSlice = createSlice({
    name: 'socketRoom',
    initialState: {
        socketGlobal: null
    },

    reducers: {
        setSocketGlobal: (state, action) => {
            state.socketGlobal = action.payload.socketGlobal
        },
    }
})

export const selectSocketGlobalSlice = (state) => state.socketGlobal;

export const {setSocketGlobal} = socketGlobalSlice.actions

export default socketGlobalSlice.reducer
