import React from 'react';
import s from './styles.module.css'

const Container = ({children, style}) => {
    return (
        <div className={s.container} style={style}>
            {children}
        </div>
    );
};

export default Container;
