import React, {useEffect} from 'react';
import s from './styles.module.css'
import Page0 from "./components/0-page";
import HelmetHeader from "../../common/helment";
import Page1 from "./components/1-page";
import Page2 from "./components/2-page";
import Page3 from "./components/3-page";
import Page4 from "./components/4-page";
import Page5 from "./components/5-page";
import Page6 from "./components/6-page";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectIsAuth} from "../../redux/slice/global.slice";
import {PATH} from "../../constants/path";
import axios from "axios";

const MainPromo = () => {
    const navigate = useNavigate()
    const {isAuth} = useSelector(selectIsAuth)
    const [searchParams, setSearchParams] = useSearchParams();

    const handleReg = () => {
        if (!isAuth) {
            searchParams.set('registration', 'open')
            setSearchParams(searchParams)
            // setTimeout(() => {
            //     searchParams.delete('registration')
            //     setSearchParams(searchParams)
            // }, 100)
        } else {
            navigate(`${PATH.AUCTIONS}/up`)
        }

    }

    return (
        <div>
            <HelmetHeader title={'ITEMBUY: онлайн-аукцион'}
                          description={'Онлайн-аукцион ITEMBUY, здесь цены диктуют покупатели. Электроника, бытовая техника, модные аксессуары, транспорт, недвижимость. Купи по своей цене!'}/>

            <Page0 handleReg={handleReg} isAuth={isAuth}/>
            {/*<Page1/>*/}
            <Page2 handleReg={handleReg} isAuth={isAuth}/>
            <Page3/>
            <Page4 handleReg={handleReg} isAuth={isAuth}/>
            <Page5/>
            <Page6/>
        </div>
    );
};

export default MainPromo;