import React from 'react';
import s from './styles.module.css'

import Button from "../../../../../common/ui_kit/button";
import HeaderModal from "../../../../../components/header_modal";
import CustomModal from "../../../../../common/modal/custom_modal";

import step1 from '../../../../../assets/images/ios/step_1.png'
import step2 from '../../../../../assets/images/ios/step_2.png'
import step3 from '../../../../../assets/images/ios/step_3.png'
import {Avatar} from "@mui/material";
import classNames from "classnames";

const ModalInfo = ({open, handleClose,}) => {

    return (

            <CustomModal open={open} width={1440}
                         onClose={handleClose}>
                <div className={s.modal}>
                <HeaderModal isMobile={false} onClose={handleClose} title={'Установка на IOS'}/>

                <div className={s.box}>
                    <div className={s.btn_box}>
                        <div className={s.step_box}>
                            <div className={s.description_box}>
                                <h4 className={s.title}>Шаг 1</h4>
                                <p className={s.description}>
                                    Откройте <a href="https://itembuy.ru/"
                                                target={'_blank'}>itembuy.ru</a> на вашем смартфоне в
                                    браузере Safari </p>
                            </div>
                            <img src={step1}/>
                        </div>
                        <div className={s.step_box}>
                            <div className={s.description_box}>
                                <h4 className={s.title}>Шаг 2</h4>
                                <p className={s.description}>
                                    Нажмите центральную иконку «Поделиться» в нижнем меню браузера
                                </p>
                            </div>
                            <img src={step2}/>
                        </div>
                        <div className={s.step_box}>
                            <div className={s.description_box}>
                                <h4 className={s.title}>Шаг 3</h4>
                                <p className={s.description}>
                                    Выберите На экран «Домой»
                                </p>
                            </div>
                            <img src={step3}/>
                        </div>
                    </div>
                    <p className={classNames(s.description, s.description_last)}>
                        Иконка ITEMBUY появится на рабочем столе. Нажмите на нее, чтобы попасть в веб-приложение
                    </p>
                    <Button disabled={false} className={s.btn} onClick={handleClose}>
                        Понятно
                    </Button>

                </div>
                </div>
            </CustomModal>

    );
};

export default ModalInfo;
