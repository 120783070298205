import React, {useState} from 'react';
import Container from "../../../../components/container";
import s from './styles.module.css'
import Button from "../../../../common/ui_kit/button";
import {Avatar, useMediaQuery} from "@mui/material";

import girl from '../../../../assets/images/main/girl.png'
import social from '../../../../assets/images/main/social.png'
import mobile from '../../../../assets/images/main/mobile.png'
import qr from '../../../../assets/images/main/qr.png'
import {ReactComponent as VkIcon} from "../../../../assets/images/vk_icon.svg";
import {ReactComponent as TgIcon} from "../../../../assets/images/tg_icon.svg";
import {ReactComponent as AndroidIcon} from "../../../../assets/images/main/android.svg";
import {ReactComponent as IosIcon} from "../../../../assets/images/main/ios.svg";
import classNames from "classnames";
import {useSearchParams} from "react-router-dom";
import apk from '../../../../assets/apk/ITEMBUY.apk'
import ModalInfo from "./modal";

const Page6 = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [openModalIOS, setOpenModalIOS] = useState(false)

    const handleOpenStaySupplier = () => {
        searchParams.set('supplier', 'open')
        setSearchParams(searchParams)
    }

    const query_780 = useMediaQuery('(max-width:780px)');

    const handleClick = (index) => {
        if (index === 1) {
            const link = document.createElement('a')
            link.href = 'https://vk.com/itembuy'
            link.target = '_blank'
            link.click()
            link.remove()
        } else if (index === 2) {
            const link = document.createElement('a')
            link.href = 'https://t.me/itembuy'
            link.target = '_blank'
            link.click()
            link.remove()
        }
    }

    const handleDownloadApk = () => {
        const link = document.createElement('a')
        link.type = 'download'
        link.href = apk
        link.click()
        link.remove()
    }

    return (
        <Container>
            {openModalIOS && <ModalInfo open={openModalIOS} handleClose={() => setOpenModalIOS(false)}/>}
            <div className={s.content}>
                <div className={s.box}>
                    <div className={s.box_left}>
                        <h4 className={s.box_title}>
                            Подписывайтесь <br/> на нас в социальных сетях,<br/>
                            <span>чтобы не пропускать важные новости и акции</span>
                        </h4>

                        <div className={s.btns}>
                            <Button className={s.btn_link} onClick={() => handleClick(1)}>
                                <span style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}><VkIcon/> ВКонтакте</span>
                            </Button>
                            <Button className={s.btn_link} onClick={() => handleClick(2)}>
                                <span style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}><TgIcon/> Telegram</span>
                            </Button>
                        </div>
                    </div>
                    <Avatar src={social} sx={{
                        borderRadius: query_780 ? '16px' : '24px',
                        height: query_780 ? 'auto' : '680px',
                        width: query_780 ? '100%' : '680px'
                    }}/>
                </div>

                <div className={classNames(s.box, s.box_v2)}>
                    <Avatar src={mobile} sx={{
                        borderRadius: query_780 ? '16px' : '24px',
                        height: query_780 ? 'auto' : '680px',
                        width: query_780 ? '100%' : '680px'
                    }}/>

                    <div className={s.box_right}>
                        <div className={s.box_right_top}>
                            <h4 className={s.box_title}>
                            <span>Наше приложение пока в <br/>
                            разработке,</span> но мы сделали <br/>
                                удобную мобильную <br/>
                                версию
                            </h4>

                            <div className={s.download_btns}>
                                <div className={s.download_btn} onClick={handleDownloadApk}><AndroidIcon/>Android</div>
                                <div className={s.download_btn} onClick={() => setOpenModalIOS(true)}><IosIcon/>iOS
                                </div>
                            </div>
                        </div>

                        <div className={s.box_right_bottom}>
                            <div className={s.qr_box}>
                                <Avatar src={qr} sx={{borderRadius: '0px', height: '117px', width: '117px'}}/>
                            </div>

                            <p className={s.qr_title}>Отсканируйте, чтобы открыть с телефона</p>
                        </div>

                    </div>
                </div>

                <div className={s.last_box}>
                    <h4 className={s.last_box_title}>
                        Приглашаем <br/>
                        к сотрудничеству <br/>
                        поставщиков
                    </h4>

                    <p className={s.last_box_sub_title}>
                        Категории, которые нам интересны: электроника, бытовая техника, мода, автомобили и недвижимость
                    </p>

                    <div className={s.btn}>
                        <Button onClick={handleOpenStaySupplier}>
                            Связаться
                        </Button>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Page6;