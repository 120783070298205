import React, {createContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Container from "../../components/container";
import {breadcrumbs, mock} from "./mock";
import Info from "./0-info";
import s from './styles.module.css'
import BreadCrumbsV2 from "../../components/bread_srumbs_v2";
import Description from "../auction_item_info/1-description";
import Records from "../auction_item_info/2-records";
import AnotherProduct from "./another_product";

export const MoveContext = createContext(null)

const MarketItemInfo = ({
                            data,
                            images_data,
                            CATEGORY,
                            CATEGORY_ITEM,
                            type,
                            category,
                            category_item,

                        }) => {
    const navigate = useNavigate()
    const currentElement = mock[1]

    const refDescription = useRef()
    const refCharacter = useRef()

    return (
        <>
            <MoveContext.Provider value={{
                refDescription: refDescription,
                refCharacter: refCharacter,
            }}>
                <Container>
                    <div className={s.bread_crumbs}>
                        <BreadCrumbsV2
                            breadcrumbs={breadcrumbs(navigate, CATEGORY, CATEGORY_ITEM, category_item, type, category, data?.brand?.title)}/>
                        <p className={s.number}>{`№${data?.id}`}</p>
                    </div>
                    <Info
                        navigate={{
                            category: CATEGORY,
                            categoryItem: CATEGORY_ITEM,
                        }}
                        currentElement={currentElement}
                        data={data || {}}
                        images_data={images_data || []}/>

                    <div ref={refDescription}>
                        <Description description={data?.description || ''}/>
                    </div>
                    <div ref={refCharacter}>
                        <Records dataConvert={data?.attributes || {}}/>
                    </div>

                </Container>
                <AnotherProduct data={data}/>
            </MoveContext.Provider>
        </>
    );
};

export default MarketItemInfo;
