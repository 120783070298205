import React, {useState} from 'react';
import s from './styles.module.css'
import {Avatar} from "@mui/material";
import {ReactComponent as ResizeOutside} from "../../../../assets/svg/resize_outside.svg";
import {ReactComponent as ResizeInside} from "../../../../assets/svg/resize_inside.svg";
import {Swiper, SwiperSlide} from "swiper/react";

const MobileImages = ({images, title, id}) => {
    const [visibleType, setVisibleType] = useState(1)
    const [index, setIndex] = useState(0)

    return (
        <div className={s.main} style={{
            flexDirection: visibleType === 2 && 'column'
        }}>
            {visibleType === 1 && <div className={s.main_left} onClick={() => setVisibleType(2)}>
                <Avatar src={(images && images[index]) ? images[index] : null} sx={{
                    height: '100%',
                    width: 'auto',
                    maxWidth: '100%',
                    borderRadius: '8px'
                }}/>
                <div className={s.resize}>
                    <ResizeOutside/>
                </div>
            </div>}
            {visibleType === 2 && <div className={s.main_left_v2}>
                <div className={s.counter_box}>
                    <p>{!images ? `0 / 0` : `${index + 1} / ${images?.length}`}</p>
                </div>

                <div className={s.resize_inside} onClick={() => setVisibleType(1)}>
                    <ResizeInside/>
                </div>

                {images ? <Swiper
                    initialSlide={index}
                    spaceBetween={0}
                    speed={1500}
                    slidesPerView={1}
                    onSlideChange={(event) => setIndex(event.activeIndex)}
                >
                    {images?.map((el, i) => <SwiperSlide key={i}>
                        <div className={s.slide_item}>
                            <Avatar src={el} sx={{
                                height: '100%',
                                width: 'auto',
                                maxWidth: '100%',
                                borderRadius: '8px',
                                '& img': {
                                    objectFit: 'contain',
                                    height: '100%',
                                    width: 'auto'
                                }
                            }}/>
                        </div>
                    </SwiperSlide>)}
                </Swiper> : <span className={s.empty}>Нет фото</span>}

                <div className={s.resize}>
                    <ResizeOutside/>
                </div>
            </div>}
            <div className={s.main_right} style={{
                marginTop: visibleType === 2 && '16px'
            }}>
                <p className={s.number}>{`№${id}`}</p>
                <p className={s.title}>{title}</p>
            </div>
        </div>
    );
};

export default MobileImages;
