import React, {useContext} from 'react';
import s from './styles.module.css'
import HeaderModal from "../../../components/header_modal";
import classNames from "classnames";
import {AuthContext} from "../../../App";
import InputMaskNumber from "../../../common/ui_kit/inputs/input_mask";
import Input from "../../../common/ui_kit/inputs/input";
import Button from "../../../common/ui_kit/button";
import TitleMobileAuth from "../../../common/title_auth_mobile";
import DescriptionSuccessInfo from "../../../common/description_succes_info";

const Screen1 = ({
                     setStep,
                     formikPhone,
                     formikEmail,
                     setLoginOrReg,
                     onClose,
                     setEnterChoose,
                     enterChoose,
                     login
                 }) => {
        const authModal = useContext(AuthContext)

        return (
            <div className={s.screen_1}>
                <HeaderModal onClose={onClose} title={'Вход'} typeModal={1} isMobile={true}/>
                {authModal.isFromAuction && <p className={s.unauth}>{authModal.isFromAuction}</p>}
                <TitleMobileAuth top={24} title={'Вход'}/>
                <div className={s.content}>
                    <div className={s.choose_enter_box}>
                        <div onClick={() => setEnterChoose(1)}
                             className={classNames(s.choose_item, enterChoose === 1 && s.selected_enter)}>Телефон
                        </div>
                        <div onClick={() => setEnterChoose(2)}
                             className={classNames(s.choose_item, enterChoose === 2 && s.selected_enter)}>Почта
                        </div>

                        <div className={s.choose_line} style={{
                            left: enterChoose === 1 ? '4px' : 'calc(50% - 4px)'
                        }}/>
                    </div>
                    <form style={{width: '100%'}} className={s.form} onSubmit={(e) => {
                        e.preventDefault()
                    }}>
                        <div className={s.mobile_box}>
                            {enterChoose === 1 &&
                                <InputMaskNumber onBlur={formikPhone.handleBlur}
                                                 name={'number'}
                                                 maskChar={null}
                                                 mask="+7 (999) 999-99-99"
                                                 placeholder={'+7 (000) 000-00-00'}
                                                 value={formikPhone.values.number}
                                                 errorMessage={formikPhone.touched.number && formikPhone.errors.number}
                                                 onChange={formikPhone.handleChange}
                                />}
                            {enterChoose === 2 &&
                                <Input className={s.email_input}
                                       onBlur={formikEmail.handleBlur}
                                       errorMessage={formikEmail.touched.email && formikEmail.errors.email}
                                       onChange={formikEmail.handleChange}
                                       name={'email'}
                                       value={formikEmail.values.email}
                                       isNeedTitle={false} placeholder={'Почта / Email'}/>}
                        </div>

                        <div className={s.btns}>
                            <Button type={'submit'} onClick={() => {
                                setLoginOrReg(1)
                                if (enterChoose === 2) {
                                    formikEmail.handleSubmit()
                                } else {
                                    formikPhone.handleSubmit()
                                }

                            }}
                                    className={s.btn_enter}>Войти</Button>
                            <Button type_btn={1} type={'button'} className={s.btn_reg} onClick={() => {
                                setLoginOrReg(2)
                                setStep({
                                    step: 3,
                                    auth_type: 'registration',
                                    enter_type: enterChoose === 1 ? 'mobile' : 'email'
                                })
                            }}>У меня нет аккаунта</Button>
                        </div>
                    </form>
                    <DescriptionSuccessInfo/>
                    {/*<div className={s.description_box}>*/}
                    {/*    <p>Нажимая кнопку, вы соглашаетесь с <b> политикой конфиденциальности </b> сайта,*/}
                    {/*        принимаете <b> условия*/}
                    {/*            оферты </b> и*/}
                    {/*        подтверждаете, что ознакомились с <b> правилами</b></p>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
;

export default Screen1;
