import React, {useEffect} from 'react';
import s from './styles.module.css'
import Container from "../../../components/container";
import {ReactComponent as AuctionUpIcon} from '../../../assets/svg/auction_up.svg'
import TitleAuction from "../../../common/title_auction";
import {NavLink, useNavigate} from "react-router-dom";
import {PATH} from "../../../constants/path";
import AuctionItemUp from "../../../components/auction_item/auctiom_item_up";
import {Skeleton, Tooltip, useMediaQuery} from "@mui/material";
import {useGetAuctionsUpQuery, useGetProductsQuery} from "../../../redux/auctions.service";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {selectAuctions, setAuctions} from "../../../redux/slice/auctions.slice";
import {selectSocketGlobalSlice} from "../../../redux/slice/socketroom.slice";
import ItemMarket from "../../auctions/content_market/item_market";

const AnotherProduct = ({data}) => {
    console.log(data)
    const {products} = useSelector(selectAuctions)


    const query_700 = useMediaQuery('(max-width:780px)');
    const {error, isLoading: isLoadingUp, refetch} = useGetAuctionsUpQuery({page_size: 4, state: ['run', 'pub']}, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
    })
    const {
        error: errorProduct,
        isLoading: isLoadingProduct,
        isFetching: isFetchingProduct,
        refetch: refetchProduct
    } = useGetProductsQuery({
        page_size: 4,
        page: 1,
        brand: data.brand.id,
    }, {
        refetchOnReconnect: true,
        refetchQuery: true,
        refetchOnMountOrArgChange: true,
        skip: !window?.location?.pathname?.includes(PATH.MARKET)
    })


    return (
        <div className={s.auction_up_container}>
            <Container>
                <TitleAuction onlyTitle={true} withButton={false} title={'Другие товары'}/>

                <div className={s.content}>
                    {isLoadingUp
                        ? [1, 2, 3, 4].map(el => <Skeleton key={el} variant="rounded"
                                                           width={`100%`}
                                                           sx={{borderRadius: '16px'}}
                                                           height={query_700 ? '305px' : `580px`}/>)
                        : products?.results?.map((el) => {
                            return <ItemMarket visibleType={'box'} {...el} />
                        })}
                </div>
            </Container>
        </div>
    );
};

export default AnotherProduct;
