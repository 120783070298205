import React from 'react';
import s from './styles.module.css'
import Container from "../../components/container";
import {Skeleton} from "@mui/material";

const SkeletonAuctionUpInfo = () => {

    return (
        <div>
            <Container>
                <div className={s.header}>
                    <Skeleton variant="rounded"
                              width={`400px`}
                              height={'16px'}
                              sx={{
                                  borderRadius: '12px',
                                  '@media screen and (max-width: 780px)': {
                                      height: '104px !important',
                                  }
                              }}/>
                    <Skeleton variant="rounded"
                              width={`40px`}
                              height={'16px'}
                              sx={{
                                  borderRadius: '12px',
                                  '@media screen and (max-width: 780px)': {
                                      display: 'none'
                                  }
                              }}/>
                </div>

                <>
                    <div className={s.info_box}>
                        <div className={s.wrapper_bg_main}>
                            <Skeleton variant="rounded"
                                      width={`100%`}
                                      height={'424px'}
                                      sx={{
                                          borderRadius: '16px', '@media screen and (max-width: 780px)': {
                                              height: '60px !important',
                                              display:'none'
                                          }
                                      }}/>
                            <Skeleton variant="rounded"
                                      width={`100%`}
                                      sx={{
                                          display: 'none',
                                          '@media screen and (max-width: 780px)': {
                                              height: '90px !important',
                                              display: 'block',
                                              marginTop: '10px',
                                              borderRadius: '12px',
                                          }
                                      }}/>

                            <Skeleton variant="rounded"
                                      width={`100%`}
                                      sx={{
                                          display: 'none',
                                          '@media screen and (max-width: 780px)': {
                                              height: '125px !important',
                                              display: 'block',
                                              marginTop: '12px',
                                              borderRadius: '12px',
                                          }
                                      }}/>
                        </div>

                        <div className={s.info}>
                            <div className={s.header2}>
                                <Skeleton variant="rounded"
                                          width={`60%`}
                                          height={'40px'}
                                          sx={{borderRadius: '16px',}}/>

                                <div className={s.header_btns}>
                                    <div>
                                        <Skeleton variant="rounded"
                                                  width={`24px`}
                                                  height={'24px'}
                                                  sx={{borderRadius: '16px',}}/>
                                    </div>
                                    <div className={s.like}>
                                        <Skeleton variant="rounded"
                                                  width={`24px`}
                                                  height={'24px'}
                                                  sx={{borderRadius: '16px',}}/>
                                    </div>
                                </div>
                            </div>

                            <Skeleton variant="rounded"
                                      width={`40%`}
                                      height={'60px'}
                                      sx={{borderRadius: '16px', marginTop: '20px'}}/>

                            <div className={s.skeleton_grid}>
                                <Skeleton variant="rounded"
                                          height={'148px'}
                                          sx={{borderRadius: '16px'}}/>
                                <Skeleton variant="rounded"
                                          height={'148px'}
                                          sx={{borderRadius: '16px'}}/>
                            </div>

                            <div className={s.skeleton_modal}>
                                <Skeleton variant="rounded"
                                          width={`155px`}
                                          height={'14px'}
                                          sx={{borderRadius: '20px'}}/>
                                <Skeleton variant="rounded"
                                          width={`155px`}
                                          height={'14px'}
                                          sx={{borderRadius: '20px', marginLeft: '66px'}}/>
                            </div>

                            <Skeleton variant="rounded"
                                      height={'56px'}
                                      sx={{borderRadius: '16px', maxWidth: '440px', width: '100%', marginTop: '50px'}}/>
                        </div>
                    </div>

                    <div className={s.other_photo}>
                        {[1, 2, 3, 4, 5]?.map((el) => {
                            return <div key={el} className={s.choose_img}>
                                <Skeleton variant="rounded"
                                          width={`100%`}
                                          height={'100%'}
                                />
                            </div>
                        })}
                    </div>
                </>

                <div className={s.description}><Skeleton variant="rounded"
                                                         width={`100%`}
                                                         height={'330px'}
                                                         sx={{borderRadius: '16px',}}/></div>

                <div className={s.description}>
                    <Skeleton variant="rounded"
                              width={`100%`}
                              height={'603px'}
                              sx={{borderRadius: '16px', marginTop: '40px'}}/>
                </div>

                <div className={s.review}>
                    <Skeleton variant="rounded"
                              width={`100%`}
                              height={'459px'}
                              sx={{borderRadius: '16px'}}/>
                </div>

                <Skeleton variant="rounded"
                          width={`100%`}
                          height={'430px'}
                          sx={{borderRadius: '16px', marginTop: '40px'}}/>
            </Container>
        </div>
    );
};

export default SkeletonAuctionUpInfo;
