import {createSlice} from "@reduxjs/toolkit";
import {userApi} from "../user.service";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        ref: {}
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            userApi.endpoints.getUserInfo.matchFulfilled,
            (state, {payload}) => {
                state.user = payload
            },
        )
        builder.addMatcher(
            userApi.endpoints.getUserRefs.matchFulfilled,
            (state, {payload}) => {
                state.ref = payload
            }
        )
    },
    reducers: {
        setUser: (state, action) => {
            state.user = {...state.user, ...action.payload}
        },
    }
})

export const {setUser} = userSlice.actions

export const selectUser = (state) => state.user;

export default userSlice.reducer
