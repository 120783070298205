import React from 'react';
import s from "./styles.module.css";
import classNames from "classnames";

const TextArea = ({onChange, value, onBlur, errorMessage, placeholder, style, name, className}) => {
    return (
        <div>
        <textarea onBlur={onBlur} rows={3} style={style} className={classNames(s.input, className)} name={name}
                  value={value}
                  placeholder={placeholder}
                  onChange={onChange}/>
            <span className={s.errorMessage}>{errorMessage}</span>
        </div>


    );
};

export default TextArea;
