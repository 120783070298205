import React, {useContext} from 'react';
import s from './styles.module.css'
import Container from "../../../components/container";
import Button from "../../../common/ui_kit/button";
import {AuthContext} from "../../../App";
import logo from '../../../assets/images/choose_subsribe_icon.png'
import {Avatar} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";

const Header = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate()
    const authModal = useContext(AuthContext)

    const handleStartFreeBid = () => {
        if (authModal?.isAuth) {
            navigate('/auctions/up')
        } else {
            searchParams.set('registration', 'open')
            setSearchParams(searchParams)
        }
    }
    return (
        <div className={s.header}>
            <Container>
                <div className={s.content}>
                    <div className={s.content_top}>
                        <div className={s.content_top_top}>
                            <h1 className={s.title}>Выберите тариф <br/> участвуйте в аукционах <br/> покупайте по своей
                                цене!</h1>
                            <p className={s.sub_title}>
                                3 дня бесплатных аукционов всем новым пользователям.
                                <br/>
                                Вы поймете насколько это выгодно и интересно!
                            </p>
                        </div>
                        <Button className={s.btn} onClick={handleStartFreeBid}>
                            {authModal?.isAuth ? 'Перейти к аукционам' : 'Регистрация'}
                        </Button>
                    </div>
                    <div className={s.content_bottom}>
                        <Avatar src={logo} sx={{borderRadius: '0', width: '100%', height: '100%'}}/>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Header;