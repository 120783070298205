export const spaceBtwHundreds = (num) => {
    const n = num?.toString();
    const separator = " ";
    return n?.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + separator);
}

export const commisionCircle = (num = 0) => {
    const formatNumber = num.toString()

    const splitNum = formatNumber.split('.')

    if (splitNum?.length === 1) {
        return +num
    } else {
        const formatRoundEnd = Math.round(Number(`0.${splitNum[1]}`))
        const convertNumber = Number(splitNum[0]) + formatRoundEnd
        return convertNumber
    }

}
