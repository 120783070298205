import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'

import categoryReducer from './slice/category_subcategory.slice'
import socketGlobalReducer from './slice/socketroom.slice'
import bidReducer from './slice/bid.slice'
import auctionsReducer from './slice/auctions.slice'
import activeLotsReducer from './slice/active_lots.slice'
import userReducer from './slice/user.slice'
import favoriteReducer from './slice/favorite.slice'
import globalReducer from './slice/global.slice'
import {categoryApi} from "./category.service";
import {auctionsApi} from "./auctions.service";
import {authApi} from "./auth.service";
import {userApi} from "./user.service";
import {activeAuctionsApi} from "./active_auctions.service";
import {favoriteApi} from "./favority.service";
import {myLotsApi} from "./my_lots.service";

export const storeRedux = configureStore({
    reducer: {
        category: categoryReducer,
        favorites: favoriteReducer,
        activeLots: activeLotsReducer,
        socketGlobal: socketGlobalReducer,
        bids: bidReducer,
        auctions: auctionsReducer,
        user: userReducer,
        global: globalReducer,
        [myLotsApi.reducerPath]: myLotsApi.reducer,
        [favoriteApi.reducerPath]: favoriteApi.reducer,
        [activeAuctionsApi.reducerPath]: activeAuctionsApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [categoryApi.reducerPath]: categoryApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [auctionsApi.reducerPath]: auctionsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            authApi.middleware,
            categoryApi.middleware,
            userApi.middleware,
            myLotsApi.middleware,
            favoriteApi.middleware,
            activeAuctionsApi.middleware,
            auctionsApi.middleware,
        ),
})

setupListeners(storeRedux.dispatch)
