import React from 'react';

const Tg = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#384151"/>
            <rect x="8" y="8" width="32" height="32" rx="16" fill="#384151"/>
            <rect x="8" y="8" width="32" height="32" rx="16" fill="#384151"/>
            <path d="M15.1916 23.6473C19.8444 21.5775 22.9471 20.213 24.4995 19.5537C28.932 17.6714 29.853 17.3444 30.4533 17.3335C30.5853 17.3312 30.8806 17.3646 31.0718 17.5231C31.2333 17.6568 31.2777 17.8375 31.2989 17.9644C31.3202 18.0912 31.3467 18.3801 31.3256 18.6059C31.0854 21.1827 30.0461 27.436 29.5174 30.322C29.2936 31.5432 28.8531 31.9527 28.4266 31.9928C27.4997 32.0799 26.7959 31.3674 25.8982 30.7665C24.4934 29.8263 23.6998 29.2411 22.3363 28.3236C20.7605 27.2634 21.782 26.6806 22.6801 25.7282C22.9151 25.479 26.9989 21.6864 27.0779 21.3424C27.0878 21.2993 27.097 21.1389 27.0037 21.0542C26.9103 20.9695 26.7726 20.9985 26.6732 21.0215C26.5323 21.0542 24.2882 22.5686 19.9409 25.5649C19.3039 26.0115 18.7269 26.2291 18.21 26.2177C17.6401 26.2051 16.5439 25.8887 15.729 25.6182C14.7294 25.2865 13.935 25.1111 14.0042 24.5477C14.0402 24.2542 14.436 23.9541 15.1916 23.6473Z" fill="white"/>
        </svg>

    );
};

export default Tg;
