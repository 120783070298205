import React from 'react';
import {Box, Modal} from "@mui/material";
import s from './styles.module.css'
import {ReactComponent as Close} from "../../assets/svg/close.svg";
import {ReactComponent as Arrow} from "../../assets/svg/arrow_forward.svg";
import Button from "../../common/button";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '680px',
    width: '100%',
    maxHeight: '99dvh',
    overflow: 'auto',
    padding: '24px',
    background: ' #FFFFFF',
    borderRadius: '16px',
    '@media screen and (max-width: 780px)': {
        width: '100%',
        maxWidth: '100%',
        maxHeight: '100dvh',
        height: '100dvh',
        overflow: 'auto',
        borderRadius: '0px',
        padding: '12px 16px 16px 16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
};

const ModalRoot = ({onClose, open, onClick, auctionRules}) => {
    return (
        <Modal
            sx={{
                zIndex: 99999999
            }}
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className={s.content}>
                    <div className={s.mobile_header}>
                        <div className={s.icon_mobile} onClick={onClose}><Close/></div>
                    </div>
                    <div className={s.header}>
                        <h3>Правила аукциона</h3>
                        <Close onClick={onClose}/>
                    </div>

                    <div className={s.item}>
                        <h2>{auctionRules?.title}</h2>
                        <p dangerouslySetInnerHTML={{__html: auctionRules?.description}}></p>
                    </div>
                </div>

                <div className={s.btns}>
                    <Button title={'Закрыть'} className={s.btn} onClick={onClose}/>
                    <div className={s.second_button} onClick={onClick}>
                        <p>Условия доставки</p>
                        <Arrow/>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default ModalRoot;
