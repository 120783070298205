import React from 'react';
import {Helmet} from "react-helmet";

const HelmetHeader = ({title, description, keywords = null}) => {
    return (
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{title}</title>
            <meta name="apple-mobile-web-app-title" content={title}/>
            <meta name="application-name" content={title}/>
            {keywords && <meta name="keywords" content={keywords}/>}
            <meta name="description"
                  content={description}/>

        </Helmet>
    );
};

export default HelmetHeader;