import {useEffect, useState} from "react";

function getStorageValue(key, defaultValue, type) {
    const saved = type === 'local' ? (localStorage.getItem(key) || '') : (sessionStorage.getItem(key) || '');
    const initial = saved ? JSON.parse(saved) : defaultValue;
    return initial || defaultValue;
}

export const useLocalOrSessionStorage = (key, defaultValue, type = 'local') => {
    const [value, setValue] = useState(() => {
        return getStorageValue(key, defaultValue, type);
    });

    useEffect(() => {
        type === 'local' ? localStorage.setItem(key, JSON.stringify(value)) : sessionStorage.setItem(key, JSON.stringify(value))

    }, [key, value]);

    return [value, setValue];
};
