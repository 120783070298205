import React from 'react';
import s from './styles.module.css'
import {ReactComponent as NavigateCatalog} from "../../assets/svg/navigate_catalog.svg";
import {ReactComponent as NavigateFavorite} from "../../assets/svg/navigate_favorite.svg";
import {ReactComponent as NavigateEvent} from "../../assets/svg/navigate_event.svg";
import PersonIcon from '@mui/icons-material/Person';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import QuizIcon from '@mui/icons-material/Quiz';
import HomeIcon from '@mui/icons-material/Home';
import classNames from "classnames";
import {NavLink, useLocation} from "react-router-dom";
import {PATH} from "../../constants/path";
import {useSelector} from "react-redux";
import {selectActiveLots} from "../../redux/slice/active_lots.slice";
import {selectFavorites} from "../../redux/slice/favorite.slice";
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';

const navigateAuth = [
    {title: 'Аукционы', icon: <NavigateCatalog/>, id: 1, path: `${PATH.AUCTIONS}/up`},
    // {title: 'Магазин', icon: <LocalGroceryStoreOutlinedIcon/>, id: 5, path: `${PATH.MARKET}`},
    {title: 'Избранное', icon: <NavigateFavorite/>, id: 2, path: PATH.ACCOUNT + '/' + PATH.FAVORITES, isCount: true},
    {title: 'События', icon: <NavigateEvent/>, id: 3, path: PATH.ACCOUNT + '/' + PATH.ACTIVE_AUCTIONS, isCount: true},
    {title: 'Профиль', icon: <PersonIcon/>, id: 4, path: PATH.ACCOUNT},
]
const navigateNotAuth = [
    {title: 'Главная', icon: <HomeIcon/>, id: 1, path: '/'},
    {title: 'Аукционы', icon: <NavigateCatalog/>, id: 2, path: `${PATH.AUCTIONS}/up`},
    // {title: 'Магазин', icon: <LocalGroceryStoreOutlinedIcon/>, id: 5, path: `${PATH.MARKET}`},
    {title: 'Блог', icon: <AutoStoriesIcon/>, id: 3, path: '/blog'},
    {title: 'Помощь', icon: <QuizIcon/>, id: 4, path: `/support`},
]

const MobileNavigateBottom = ({isAuth, isIphoneAndStandalone = false}) => {
    const {pathname} = useLocation()
    const {activeLots} = useSelector(selectActiveLots)
    const {favorites} = useSelector(selectFavorites)
    const navigate = isAuth ? navigateAuth : navigateNotAuth

    return (
        <div className={classNames(s.navigate, isIphoneAndStandalone && s.navigate_iphone_standalone)}>
            {navigate.map((nav) => <div key={nav.id} className={s.navigate_item}>
                    <NavLink to={nav.path} style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        flexDirection:  'column',
                        width: '100%',
                    }}>
                        <div className={classNames(s.icon, pathname === nav.path && s.selected)}>
                            {nav.isCount && <div
                                className={s.circle_count}>{(nav?.id === 2 && favorites?.count || nav?.id === 3 && activeLots?.results?.length) || 0}</div>}
                            {nav.icon}
                        </div>
                        <div className={classNames(s.title, pathname === nav.path && s.selected)}>{nav.title}</div>
                    </NavLink>
                </div>
            )}
        </div>
    );
};

export default MobileNavigateBottom;
