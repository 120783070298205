import React from 'react';
import {Box, Modal} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {PATH} from "../../../../constants/path";
import s from "./styles.module.css";
import {ReactComponent as Close} from "../../../../assets/svg/close.svg";
import Button from "../../../../common/button";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '440px',
    width: '100%',
    padding: '24px',
    background: ' #FFFFFF',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};
const ErrorModal = () => {
    const navigate = useNavigate()
    const {type} = useParams()

    const handleClose = () => navigate(PATH.MARKET)
    return (
        <Modal
            sx={{
                zIndex: 999999999999
            }}
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={style}>
                <div className={s.header}>
                    <div className={s.close}/>
                    <div className={s.notif_icon}><h1>Ошибка!</h1></div>
                    <div className={s.close} onClick={handleClose}><Close/></div>
                </div>

                <div className={s.content}>
                    <p>К сожалению данный товар не найден</p>
                </div>

                <Button title={'Вернуться к списку товаров'} onClick={handleClose} className={s.btn}/>
            </Box>

        </Modal>
    );
};

export default ErrorModal;
