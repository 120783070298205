import React, {useContext, useEffect, useState} from 'react';
import s from "../styles.module.css";
import classNames from "classnames";
import {Avatar, IconButton, Tooltip} from "@mui/material";
import {commisionCircle, spaceBtwHundreds} from "../../../../helper/spaceBetweenSum";
import StatusTitle from "../status_title";
import Button from "../../../../common/button";
import {ReactComponent as AuctionUpIcon} from "../../../../assets/svg/auction_up.svg";
import {ReactComponent as Arrow} from "../../../../assets/svg/arrow_next_price.svg";
import {NavLink} from "react-router-dom";
import moment from "moment/moment";
import noImage from "../../../../assets/images/no_image.jpg";
import {useDispatch, useSelector} from "react-redux";
import {selectCategory} from "../../../../redux/slice/category_subcategory.slice";
import {PATH} from "../../../../constants/path";
import {toast} from "react-toastify";
import {useSetFavoriteMutation} from "../../../../redux/favority.service";
import {AuthContext} from "../../../../App";
import {selectAuctions, setAuctions} from "../../../../redux/slice/auctions.slice";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {motion} from "framer-motion";

const customButtonStyle = {
    height: '100%',
    width: '100%',
};

const ItemLine = ({
                      timer,
                      startDateTime,
                      highest_bid_amount,
                      time_for_bid,
                      base_price,
                      bid_price_percent,
                      min_price_increment,
                      visibleType,
                      start_date,
                      end_date,
                      product,
                      id,
                      follow,
                      state,

                  }) => {
    const [setFavorite, {isLoading}] = useSetFavoriteMutation()
    const dispatch = useDispatch()
    const {auctions} = useSelector(selectAuctions)

    const authModal = useContext(AuthContext)
    const {category: category_data} = useSelector(selectCategory)

    const [sumRun, setSunRun] = useState(highest_bid_amount ? highest_bid_amount : base_price)
    const [sumRunNext, setSunRunNext] = useState(highest_bid_amount ? +highest_bid_amount + +min_price_increment : +base_price + +min_price_increment)
    const [animateText, setAnimateText] = useState(false);

    const duration_completed = moment.duration(moment(end_date).diff(moment(start_date))) ? moment.duration(moment(end_date).diff(moment(start_date))) : null;
    const duration_completed_obj = {
        hh: `${duration_completed.hours() >= 10 ? duration_completed.hours() : `0${duration_completed.hours()}`}`,
        mm: `${duration_completed.minutes() >= 10 ? duration_completed.minutes() : `0${duration_completed.minutes()}`}`,
        ss: `${duration_completed.seconds() >= 10 ? duration_completed.seconds() : `0${duration_completed.seconds()}`}`,
    }

    const category_slag = category_data?.results?.find((f) => f.id === product?.category)
    const sub_category_slag = category_slag?.subcategory_set?.find((f) => f.id === product?.subcategory)

    const handleClick = () => {
        if (authModal?.isAuth) {
            setFavorite(id)
                .unwrap()
                .then((res) => {
                    dispatch(setAuctions({
                        auctions: {
                            ...auctions,
                            results: auctions?.results?.map((el) => +el.id === +id ? ({
                                ...el,
                                follow: Boolean(res?.detail?.includes('Added'))
                            }) : el)
                        }
                    }))
                    if (res?.detail?.includes('Added')) {
                        toast.success('Аукцион добавлен в избранное', {autoClose: 1000})
                    } else {
                        toast.warning('Аукцион удален из избранного', {autoClose: 1000})
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        } else {
            authModal.actionModal(true, 'Для данного действия необходима авторизация')
        }
    }

    useEffect(() => {
        if (state === 'run') {

            const animationDuration = 100; // Длительность анимации в миллисекундах

            const startTime = Date.now();
            const endTime = startTime + animationDuration;

            const updatePrice = () => {
                const now = Date.now();
                const progress = Math.min(1, (now - startTime) / animationDuration);
                const currentPrice = sumRun + ((highest_bid_amount ? highest_bid_amount : base_price) - sumRun) * progress;
                const currentPriceNext = sumRunNext + ((highest_bid_amount ? +highest_bid_amount + +min_price_increment : +base_price + +min_price_increment) - sumRunNext) * progress;

                setSunRun(+currentPrice ? +currentPrice?.toFixed(0) : 0);
                setSunRunNext(+currentPriceNext ? +currentPriceNext?.toFixed(0) : 0);

                if (now < endTime) {
                    requestAnimationFrame(updatePrice);
                } else {
                    setAnimateText(false)
                }
            };
            setAnimateText(true)
            updatePrice();
        }
    }, [state, highest_bid_amount, sumRun])
    return (
        <>
            <div className={s.line_box_left}>

                <NavLink to={`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${id}`}>
                    <div className={classNames(s.media_box, s.media_box_line)}>
                        <Avatar
                            sx={{
                                height: '100%',
                                width: '100%',
                                borderRadius: '8px',
                                overflow: 'hidden',
                            }}
                            src={product?.hero_image || noImage}
                        />
                    </div>
                </NavLink>
                <div className={s.line_content}>
                    <div>
                        <div className={classNames(s.title, s.title_line)}>
                            <NavLink to={`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${id}`}>
                                <Tooltip title={product?.title}>
                                    <span>{product?.title}</span>
                                </Tooltip>
                            </NavLink>
                        </div>

                        <div
                            className={classNames(s.content_completed, visibleType === 'line' && s.content_completed_line)}>
                            <div
                                className={classNames(s.content_item, visibleType === 'line' && s.content_item_line)}>
                                <p className={s.content_item_title}>
                                    {state === 'end' ? 'Продолжительность' : 'Время на ставку'}
                                </p>
                                <div className={s.line_dash}/>
                                <motion.p
                                    initial={(state === 'run' && timer?.isRunning && timer?.totalSeconds < 11) ? {
                                        scale: 1,
                                        translateZ: 0
                                    } : {}}
                                    animate={(state === 'run' && timer?.isRunning && timer?.totalSeconds < 11) ? {
                                        scale: [1, 1.028, 1],
                                        translateZ: 0
                                    } : {}}
                                    transition={(state === 'run' && timer?.isRunning && timer?.totalSeconds < 11) ? {
                                        duration: 1,
                                        ease: "easeInOut",
                                        repeat: Infinity,
                                        delay: 0
                                    } : {}}
                                    style={(state === 'run' && timer?.isRunning && timer?.totalSeconds < 11) ? {color: '#f85151'} : {}}
                                    className={s.content_item_duration}>
                                    {state === 'end' ?
                                        <>
                                            {`${duration_completed_obj.hh ? duration_completed_obj.hh : 0}`}&nbsp;
                                            <span>ч.</span>
                                            &nbsp;
                                            {`${duration_completed_obj.mm ? duration_completed_obj.mm : 0}`}&nbsp;
                                            <span>мин.</span>
                                            &nbsp;
                                            {`${duration_completed_obj.ss ? duration_completed_obj.ss : 0}`}&nbsp;
                                            <span>сек.</span>
                                        </>
                                        : (state === 'run' ? `${timer.dd === 0 ? '' : `${timer.hh}:`}${timer.hh}:${timer.mm}:${timer.ss}` : time_for_bid)}
                                </motion.p>
                            </div>
                            <div
                                className={classNames(s.content_item, visibleType === 'line' && s.content_item_line)}>
                                <p className={s.content_item_title}>
                                    Шаг цегы, от
                                </p>
                                <div className={s.line_dash}/>
                                <p className={s.content_item_duration}>
                                    {(+bid_price_percent === 0 ? 'Бесплатно' : `${spaceBtwHundreds(50)} ₽`)}
                                </p>
                            </div>
                            <div
                                className={classNames(s.content_item, visibleType === 'line' && s.content_item_line)}>
                                <p className={s.content_item_title}>Розничная цена</p>
                                <div className={s.line_dash}/>
                                <p className={s.content_item_duration}>{spaceBtwHundreds(product?.retail_price)} ₽</p>
                            </div>
                        </div>

                    </div>

                    <div className={s.status}>
                        <StatusTitle status_item={state} startDateTime={startDateTime}
                                     visibleType={visibleType}/>
                    </div>
                </div>
            </div>
            <div className={s.action}>
                <div className={s.top_content_action}>
                    <div className={s.icons}>
                        <div className={s.header_btns}
                             style={{gridTemplateColumns: '1fr'}}>
                            <Tooltip title={follow ? 'Удалить из избранного' : 'Добавить в избранное'}>
                                <IconButton
                                    sx={{
                                        ...customButtonStyle,
                                    }}
                                    disabled={isLoading} onClick={handleClick}
                                >
                                    {follow ?
                                        <FavoriteIcon sx={{color: '#5654D4'}}/> :
                                        <FavoriteBorderIcon sx={{color: '#91969F'}}/>}
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>

                    <div className={s.action_content}>

                        <p className={classNames(s.content_item_title, s.content_item_title_line)}>{
                            (state === 'run' && 'Текущая цена') ||
                            (state === 'pub' && 'Начальная цена') ||
                            (state === 'end' && 'Цена выкупа')
                        }</p>
                        {state === 'pub' &&
                            <div className={classNames(s.price_box, s.price_box_line)}>
                                <p className={s.price_box_title}>{spaceBtwHundreds(base_price)} ₽</p>
                                <Tooltip title={'Торги на повышение'}>
                                    <AuctionUpIcon/>
                                </Tooltip>
                            </div>}
                        {state === 'end' &&
                            <div className={classNames(s.price_box, s.price_box_line)}>
                                <p className={s.price_box_title}>{highest_bid_amount !== null ? `${spaceBtwHundreds(highest_bid_amount)} ₽` : 'Аукцион не состоялся'}</p>
                                <Tooltip title={'Торги на повышение'}>
                                    <AuctionUpIcon/>
                                </Tooltip>
                            </div>}
                        {state === 'run' &&
                            <div className={classNames(s.price_box, s.price_box_bidding, s.price_box_bidding_line)}>
                                <p className={classNames(s.price_box_title, animateText && s.price_box_title_animation)}>{spaceBtwHundreds(sumRun)} ₽</p>
                                {/*<Arrow/>*/}
                                {/*<p className={classNames(s.price_box_title, animateText && s.price_box_title_animation)}>{spaceBtwHundreds(sumRunNext)} ₽</p>*/}
                            </div>}
                    </div>
                </div>

                <div className={s.action_button_box}>
                    <NavLink to={`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${id}`}>
                        <Button title={'Перейти к лоту'} onClick={() => {
                        }} className={s.action_button}/>
                    </NavLink>
                </div>
            </div>
        </>
    );
};

export default ItemLine;
