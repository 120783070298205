import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import Item from "../item";
import Title from "../../order_item/page_1/common/title";
import {spaceBtwHundreds} from "../../../helper/spaceBetweenSum";
import moment from "moment-timezone";
import Button from "../../../common/ui_kit/button";
import {useBuySubscribeMutation, useGetUserInfoMutation} from "../../../redux/user.service";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {selectIsAuth} from "../../../redux/slice/global.slice";
import {AuthContext} from "../../../App";
import {selectUser} from "../../../redux/slice/user.slice";
import CustomSlider from "../../../common/ui_kit/slider";

const Content = ({subscribes}) => {
    const {user} = useSelector(selectUser)
    console.log(user)
    const authModal = useContext(AuthContext)

    const [currentItem, setCurrentItem] = useState((subscribes && subscribes[0] && subscribes[0]?.title) ? subscribes[0] : null)
    const [incentivePoints, setIncentivePoints] = useState(0)

    const [buySubscribe, {isLoading: isLoadingBuy}] = useBuySubscribeMutation()
    const [getUserInfo] = useGetUserInfoMutation()

    const handleChange = (_, newValue) => {
        setIncentivePoints(newValue);
    };

    const handleBuySubscribe = () => {
        if (authModal?.isAuth) {
            buySubscribe({bid_token_bundle: currentItem?.id, incentive_points_used: incentivePoints})
                .unwrap()
                .then((res) => {
                    console.log(res)
                    setIncentivePoints(0)
                    getUserInfo().unwrap().then(() => {
                        const link = document.createElement('a')
                        link.href = res?.payment?.payment_url
                        link.target = '_blank'
                        link.click()
                        link.remove()

                    }).catch((e) => {
                        console.log(e)
                    })

                })
                .catch((e) => {
                    console.log(e)
                    toast.error(e?.data?.detail || 'Ошибка покупки')
                })
        } else {
            authModal.actionModal(true, 'Для данного действия необходима авторизация')
        }

    }

    useEffect(() => {
        setIncentivePoints(0)
    }, [currentItem])
    return (
        <div className={s.content}>
            <div className={s.content_header}>
                <h3 className={s.title}>Выберите тариф для участия в аукционах</h3>
                <p className={s.sub_title}>Определите подходящий лимит на количество повышений цены</p>
            </div>
            <div className={s.content_box}>

                <div className={s.items_box}>
                    {subscribes?.map((el) => {
                        return <Item isActiveItem={el?.title === currentItem?.title}
                                     handleClick={() => setCurrentItem(el)}
                                     currentItem={currentItem}
                                     key={el.title} {...el} />
                    })}
                </div>

                <div className={s.info_box} style={{height: 'fit-content'}}>
                    <h3 className={s.info_box_title}>{`Тариф “${currentItem?.title}”`}</h3>
                    <p className={s.info_box_description}
                       dangerouslySetInnerHTML={{__html: currentItem?.description}}></p>
                </div>

                <div className={s.detail_box}>
                    <div className={s.header}>
                        <h3 className={s.info_box_title}>Детали заказа</h3>
                    </div>

                    <div className={s.info_box_detail}>
                        <div className={s.info_box_top}>
                            <div className={s.info_box_top_item}>
                                <p className={s.info_box_top_item_title}>{`Тариф “${currentItem?.title}”`}</p>
                                <div className={s.dots}/>
                                <p className={s.info_box_top_item_sub_title}>{`${spaceBtwHundreds(currentItem?.price)} ₽`}</p>
                            </div>
                            <div className={s.info_box_top_item}>
                                <p className={s.info_box_top_item_title}>Количество повышений</p>
                                <div className={s.dots}/>
                                <p className={s.info_box_top_item_sub_title}>{spaceBtwHundreds(currentItem?.amount)}</p>
                            </div>
                            {authModal?.isAuth && <div className={s.info_box_top_item}>
                                <p className={s.info_box_top_item_title}>Доступные баллы</p>
                                <div className={s.dots}/>
                                <p className={s.info_box_top_item_sub_title}>{spaceBtwHundreds((user?.customer_wallet?.incentive_points) || 0)}</p>
                            </div>}
                            {authModal?.isAuth && <div className={s.info_box_top_item}>
                                <p className={s.info_box_top_item_title}>Используется баллов</p>
                                <div className={s.dots}/>
                                <p className={s.info_box_top_item_sub_title}>{spaceBtwHundreds((incentivePoints) || 0)}</p>
                            </div>}
                        </div>

                        <div className={s.info_box_center}>
                            <div className={s.info_box_center_top}>
                                <p className={s.title_price}>Итого</p>
                                <p className={s.sub_title_finish_price}>{`${spaceBtwHundreds(currentItem?.price - incentivePoints)} ₽`}</p>
                            </div>
                            {authModal?.isAuth && <div className={s.info_box_center_bottom}>
                                {/*<p className={s.info_box_top_item_title}>Использовать баллы для оплаты</p>*/}
                                <CustomSlider
                                    classNameSliderBox={s.slider_box}
                                    min_price={0}
                                    max_price={((user?.customer_wallet?.incentive_points > currentItem?.price) ? currentItem?.price - 1 : user?.customer_wallet?.incentive_points) || 0}
                                    handleChange={handleChange}
                                    step={1} value={incentivePoints}/>
                                <p className={s.info_pay_free_balls}>Вы можете оплатить часть товара баллами.</p>
                            </div>}
                        </div>

                        <Button className={s.btn} disabled={isLoadingBuy} loading={isLoadingBuy}
                                onClick={handleBuySubscribe}>Перейти к оплате</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Content;