import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {spaceBtwHundreds} from "../../../helper/spaceBetweenSum";
import lider from '../../../assets/images/lider.png'
import {Avatar} from "@mui/material";
// background: linear-gradient(90deg, #5654D4 0%, #6E66BB 49%, #FECF2A 100%);
import {mix, motion} from "framer-motion";
import classNames from "classnames";

const WrapperAnimated = ({children, isActive, onClick}) => {
    return <motion.div className={classNames(s.wrapper)}
                       key={`${isActive}`}
                       onClick={onClick}
        // whileHover={{scale: 1.05}}
                       whileHover={{
                           scale: 1.05,
                           // scale: 1.5,
                           transition: {type: "spring", stiffness: 1000, damping: 20, ease: 'easeInOut'}
                       }}
                       initial={{background: '#fff', scale: 1}}
                       animate={isActive ? {
                           background: [
                               'linear-gradient(90deg, #5654D4 0%, #FECF2A 100%)',
                               'linear-gradient(90deg, #5654D4 100%, #FECF2A 100%)',
                               'linear-gradient(90deg, #FECF2A 0%, #5654D4 100%)',
                               'linear-gradient(90deg, #FECF2A 0%, #5654D4 0%)',
                               'linear-gradient(90deg, #5654D4 0%, #FECF2A 100%)'
                           ],
                           scale: [1, 1]
                       } : {background: '#fff'}}

                       transition={isActive ? {
                           duration: 4,
                           repeat: Infinity,
                           ease: 'easeInOut',
                       } : {

                           // type: "spring",
                           // stiffness: 1000,
                           // damping: 20,
                           // ease: 'easeInOut',
                       }}>
        {children}
    </motion.div>
}

const Item = ({price, amount, is_lider = false, currentItem, id, handleClick, title, isActiveItem, promo_text}) => {

    return (
        <WrapperAnimated isActive={isActiveItem} onClick={handleClick}>
            <div
                className={classNames(s.item)}>
                {is_lider && <motion.div
                    animate={isActiveItem ? {
                            top: ['-30px', '-60px', '-30px', '-45px', '-30px'],
                            rotate: ['0', '30deg', '0', '-30deg', '0'],
                            scale: [1, 1.3, 1, 1.3, 1],
                        }
                        : {
                            top: ['-30px'],
                            rotate: '0deg',
                            scale: [1],
                        }} className={s.leader}>
                    <Avatar sx={{height: '70px', width: '70px'}} src={lider}/>
                </motion.div>}
                <div className={s.item_top}>
                    <p className={s.item_top_left}>{title}</p>
                    <p className={s.item_top_right}>{`${spaceBtwHundreds(price)} ₽`}</p>
                </div>
                <div className={s.item_bottom}>
                    <div className={s.item_bottom_left_box}>
                        <p style={{color: '#222C3E'}}
                           className={s.item_bottom_left_box_description}>{`${spaceBtwHundreds(amount)} повышений цены в аукционах`}</p>
                        <p className={s.item_bottom_left_box_description}>{`${spaceBtwHundreds(price)} ₽ единоразово за пакет участия`}</p>
                    </div>
                    <p className={s.item_bottom_right}>{`за ${amount} повышений`}</p>
                </div>
            </div>

            <motion.div
                key={`${isActiveItem},${new Date().getMilliseconds()}`}
                animate={{
                    opacity: [0, 1],
                }}
                transition={{
                    duration: .4,
                    ease: 'easeInOut',
                }}
                className={s.description_box}>
                <p className={s.description} style={{
                    WebkitLineClamp: isActiveItem ? 10 : 1
                }}>{promo_text}</p>
            </motion.div>
        </WrapperAnimated>

    );
};

export default Item;