import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {baseQueryWithReauth} from "../api/baseQuery";

export const api = createApi({
    baseQuery: fetchBaseQuery(),
    reducerPath: 'api',
    tagTypes: ['Users', 'Posts', 'Addresses'],
    endpoints: () => ({}),
});

export const categoryApi = createApi({
    reducerPath: 'categoryApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getCategorySubCategory: builder.query({
            query: () => ({
                url: 'categories-subcategories/',
            }),
        }),
        getBrands: builder.query({
            query: ({cat = [], subcat = []}) => ({
                url: 'brands/',
                params: {
                    cat: cat,
                    subcat: subcat
                }
            }),
        }),

    }),
})
export const {useGetCategorySubCategoryQuery, useGetBrandsQuery} = categoryApi;
