import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const InputTitle = ({
                        type = 'text',
                        title,
                        placeholder,
                        className,
                        isNeedTitle = true,
                        styleInput,
                        styleBox,
                        value,
                        onChange = () => {
                        },
                        min = 0,
                        onKeyPress,
                        maxLength
                    }) => {

    return (
        <div className={classNames(s.box, className)} style={{...styleBox}}>
            {isNeedTitle && <p>{title}</p>}
            <input className={s.input} type={type} min={min} maxLength={maxLength} value={value}
                   onChange={(e) => onChange(e.target.value)}
                   placeholder={placeholder}
                   onKeyPress={onKeyPress}
                   style={{...styleInput}}/>
        </div>
    );
};

export default InputTitle;
