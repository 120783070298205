export const validate = (values, step) => {
    const errors = {};
    if (step === 'mobile') {
        if (!values[step]) {
            errors.mobile = 'Обязательное поле';
        } else if (values.mobile.length !== 18) {
            errors.mobile = 'Неверный номер телефона';
        }
    } else {
        if (!values[step]) {
            errors[step] = 'Обязательное поле';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[step])) {
            errors[step] = 'Неверный email адресс';
        }
    }

    return errors;
}

