import React, {useEffect} from 'react';
import s from './styles.module.css'
import Button from "../../../../common/ui_kit/button";
import {useTimer} from "react-timer-hook";

const SendAgain = ({isLoading, handlerResendSms, timerResetSms}) => {
    const {
        totalSeconds,
        seconds: ss,
        minutes: mm,
        hours: hh,
        days: dd,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp: new Date(timerResetSms?.date),
        autoStart: Boolean(timerResetSms?.date),
    });

    useEffect(() => {
        if (!timerResetSms?.date) return
        restart(new Date(timerResetSms?.date), true)
    }, [timerResetSms?.date])

    return (
        <>
            {!isRunning && <Button disabled={isLoading} className={s.btn_reg} type_btn={1} onClick={handlerResendSms}>Отправить
                повторно</Button>}

            {isRunning && <p className={s.timer}>{`Отправить повторно через: ${totalSeconds} сек`}</p>}
        </>
    );
};

export default SendAgain;
