import React from 'react';
import s from './styles.module.css'

const TitleMobileAuth = ({title, top, align = 'center',pl = 0}) => {
    return (
        <h2 className={s.title} style={{marginTop: `${top}px`, textAlign: align,paddingLeft:`${pl}px`}}>
            {title}
        </h2>
    );
};

export default TitleMobileAuth;
