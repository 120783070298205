import React, {useState} from 'react';
import s from './styles.module.css'
import {ReactComponent as InfoIcon} from '../../assets/svg/info_welcome.svg'
import HeaderModal from "../header_modal";
import Button from "../../common/ui_kit/button";
import CustomModal from "../../common/modal/custom_modal";
import {ReactComponent as Logo} from "../../assets/svg/logo_v2.svg";
import {ReactComponent as Vk} from "../../assets/svg/vk_s.svg";
import classNames from "classnames";
import {ReactComponent as Tg} from "../../assets/svg/tg_s.svg";

const ModalWelcome = () => {
    const [welcome, setWelcome] = useState("0")

    return (
        <CustomModal open={welcome === "0"}

                     width={'598px'}
                     onClose={() => setWelcome("1")}>
            <HeaderModal isMobile={true} onClose={() => {
                setWelcome("1")
            }}
                         title={<Logo/>}
                         arrow={false} typeModal={1}/>
            <div className={s.content}>
                <div className={s.content_top}>
                    <div className={s.icon}>
                        <Logo/>
                    </div>
                    {/*<h3 className={s.title}>Добро пожаловать!</h3>*/}

                    <div className={s.description_box}>
                        <p>
                            <b>ITEMBUY: онлайн-аукцион начинает свою работу!</b>
                        </p>
                        <p>
                            Это означает, что уже сейчас вы можете окунуться в захватывающий мир совершенно нового
                            шопинга - где стоимость товара находится исключительно во власти покупателей.
                        </p>
                        <p>
                            Регистрируйтесь на платформе, участвуйте в аукционах и совершайте покупки по самым
                            уникальным ценам!
                        </p>
                        <p>
                            Подписывайтесь на социальные сети, чтобы первыми узнавать о всех новостях и событиях.
                        </p>
                        <p>
                            Желаем удачных покупок с <b>ITEMBUY!</b>
                        </p>

                    </div>
                </div>
                <div className={s.social_box}>
                    <a href="https://vk.com/itembuy" target={'_blank'}>
                        <div className={s.social_icon}><Vk/></div>
                    </a>
                    <a href="https://t.me/itembuy" target={'_blank'}>
                        <div className={classNames(s.social_icon, s.social_icon_v2)}><Tg/></div>
                    </a>
                </div>
                <Button className={s.btn} onClick={() => {
                    setWelcome("1")
                }}>Отлично!</Button>
            </div>
        </CustomModal>
    );
};

export default ModalWelcome;
