import React, {useState} from 'react';
import s from './styles.module.css'
import HeaderModal from "../../../../components/header_modal";
import {useFormik} from "formik";
import {validate} from "./validate";
import Input from "../../../../common/ui_kit/inputs/input";
import InputMaskNumber from "../../../../common/ui_kit/inputs/input_mask";
import Button from "../../../../common/ui_kit/button";
import TitleMobileAuth from "../../../../common/title_auth_mobile";
import {toast} from "react-toastify";
import {useResentPasswordEmailMutation, useResentPasswordMobileMutation} from "../../../../redux/auth.service";
import {cleanPhoneNumber} from "../../../../helper/clearNumber";
import LoginCode from "../login_code";
import ButtonsAction from "./buttonsAction";
import CustomModal from "../../../../common/modal/custom_modal";

const LoginReset = ({setStep, onClose, step, valueToSend, handleChange}) => {
    const [timerResentSms, setTimerResetSms] = useState(null)
    const [error, setError] = useState('')
    const [openModalCode, setOpenModalCode] = useState(false)
    const [resentPasswordEmail, {isLoading}] = useResentPasswordEmailMutation()
    const [resentPasswordMobile, {isLoading: isLoadingMobile}] = useResentPasswordMobileMutation()

    const formik = useFormik({
        initialValues: {
            [step.enter_type === 'mobile' ? 'mobile' : 'email']: valueToSend
        },
        validate: (values) => validate(values, step.enter_type),
        onSubmit: (values) => {

            const formData = new FormData();

            formData.append(step.enter_type === 'email' ? 'email' : 'phone_number', step.enter_type === 'email' ? values[step.enter_type] : `+${cleanPhoneNumber(values[step.enter_type])}`);

            if (step.enter_type === 'email') {
                resentPasswordEmail(formData)
                    .unwrap()
                    .then((res) => {
                        toast.success(res?.detail || 'Отправленно')
                        onClose()
                    })
                    .catch((e) => {
                        console.log(e)
                        toast.error(e?.data?.detail || 'Ошибка')
                    })
            } else {
                resentPasswordMobile(formData)
                    .unwrap()
                    .then((res) => {
                        toast.success(res?.detail || 'Отправленно')
                        setOpenModalCode(true)
                        setError('')
                        setTimerResetSms({
                            date: res.res_at || '',
                            number: valueToSend
                        })

                        // onClose()
                    })
                    .catch((e) => {
                        console.log(e)
                        if (e?.data?.code === 305) {
                            setError({...e.data, mobile: values.mobile})
                            toast.error(e?.data?.detail || 'Ошибка')
                        } else if (e?.data?.code === 304) {
                            setError({...e.data, mobile: values.mobile})
                            setTimerResetSms({
                                date: e?.data?.exp_at || '',
                                number: valueToSend
                            })
                            setOpenModalCode(true)
                        } else {
                            if (e?.data?.detail) {
                                toast.error(e?.data?.detail || 'Ошибка')
                            } else {
                                function errorHandler(errorObject) {

                                    for (let key in errorObject) {
                                        const value = errorObject[key];
                                        if (Array.isArray(value)) {
                                            value.forEach(error => {
                                                toast.error(`${error}`);
                                            });
                                        } else {
                                            toast.error(`${value}`);
                                        }
                                    }
                                }

                                errorHandler(e.data)
                            }

                            setError('')
                        }

                    })
            }
        }
    })
    return (
        <div>
            {openModalCode && <CustomModal open={openModalCode}
                                           onClose={onClose}>
                <LoginCode timerResentSms={timerResentSms} setTimerResetSms={setTimerResetSms} error={error}
                           setError={setError} closeLoginReset={onClose} onClose={() => {
                    setOpenModalCode(false)
                }} valueToSend={formik.values[step.enter_type]}
                />
            </CustomModal>

            }

            <HeaderModal isMobile={true} onClose={onClose} onClick={() => setStep({...step, step: 2})}
                         title={'Сброс пароля'}
                         arrow={true} typeModal={1}/>

            <TitleMobileAuth top={24} title={'Сброс пароля'}/>

            <div className={s.enter_password_title}>
                <p>{step.enter_type === 'mobile' ?
                    <>Убедитесь, что это ваш номер телефона.
                        <br/>
                        На него мы отправим код для
                        <br/>
                        восстановления пароля
                    </> :
                    <>Убедитесь, что это ваша почта.
                        <br/>
                        На нее мы отправим ссылку для
                        <br/>
                        восстановления пароля
                    </>}</p>
            </div>

            <form className={s.form} onSubmit={(e) => {
                e.preventDefault()
                formik.handleSubmit()
            }} style={{width: '100%'}}>
                <div className={s.enter_password_input}>
                    {step.enter_type !== 'mobile' && <Input value={formik.values[step.enter_type]}
                                                            name={step.enter_type}
                                                            onBlur={formik.handleBlur}
                                                            onChange={(e) => {
                                                                formik.handleChange(e)
                                                                handleChange.email(e)
                                                            }}
                                                            errorMessage={formik.touched[step.enter_type] && formik.errors[step.enter_type]}
                                                            placeholder={'Почта / Email'}/>}
                    {step.enter_type === 'mobile' &&
                        <InputMaskNumber maskChar={null} mask="+7 (999) 999-99-99" placeholder={'+7 (000) 000-00-00'}
                                         value={formik.values[step.enter_type]}
                                         name={step.enter_type}
                                         onChange={(e) => {
                                             formik.handleChange(e)
                                             handleChange.mobile(e)
                                         }}
                                         errorMessage={formik.touched[step.enter_type] && formik.errors[step.enter_type]}
                                         onBlur={formik.handleBlur}
                        />}
                </div>
                {step.enter_type === 'mobile' ?
                    <ButtonsAction error={error} loading={isLoadingMobile} formik={formik}
                                   number={formik.values.mobile}/> :
                    <Button title={''} disabled={isLoading || isLoadingMobile}
                            type={'submit'}
                            className={s.btn}>
                        Сбросить пароль
                    </Button>}


            </form>
        </div>
    );
};

export default LoginReset;
