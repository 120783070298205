import {toast} from "react-toastify";

export const handlerError = (e, actionClick) => {
    if (e && e.data) {
        console.log(e)
        if (e.originalStatus && (e.originalStatus >= 500 || e.originalStatus === 403)) {
            toast.error('Ошибка')
        } else if (e.data && e.data[0] === '<') {
            toast.error('Ошибка')
        } else {
            for (let i = 0; i < Object.keys(e.data)?.length; i++) {
                if (Object.keys(e.data) && Object.keys(e.data)[i] && (Object.keys(e.data)[i] === 'service_info' || Object.keys(e.data)[i] === 'code')) {
                    if (actionClick) {
                        actionClick(e)
                    }
                    return
                }
                toast.error((Array.isArray(e.data[Object.keys(e.data)[i]]) ? e.data[Object.keys(e.data)[i]][0] : e.data[Object.keys(e.data)[i]]) || 'Ошибка')
            }
        }

    } else {
        toast.error('Ошибка')
    }
}
