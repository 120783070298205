import img2 from '../../assets/images/auction2.png'
import img1 from '../../assets/images/Photo.png'
import img3 from '../../assets/images/auction3.png'
import {PATH} from "../../constants/path";

export const mock = {
    1: {
        enter_price: 540234,
        type_lot: 'prepair',
        brand: 'Apple',
        title: 'iPhone 14 Pro Max 512 Гб, Deep Purple',
        imgs: [{img: img1, id: 1}, {img: img2, id: 2}, {img: img3, id: 3}]
    },
    2: {
        enter_price: 540234,
        type_lot: 'going',
        brand: 'Samsung',
        title: 'iPhone 14 Pro Max 512 Гб, Deep Purple',
        imgs: [{img: img1, id: 1}, {img: img2, id: 2}, {img: img3, id: 3}]
    },
    3: {
        enter_price: 540234,
        type_lot: 'prepair',
        brand: 'MI',
        title: 'iPhone 14 Pro Max 512 Гб, Deep Purple',
        imgs: [{img: img1, id: 1}, {img: img2, id: 2}, {img: img3, id: 3}]
    },
    4: {
        enter_price: 540234,
        type_lot: 'going',
        brand: 'Apple',
        title: 'iPhone 14 Pro Max 512 Гб, Deep Purple',
        imgs: [{img: img1, id: 1}, {img: img2, id: 2}, {img: img3, id: 3}]
    },
    5: {
        enter_price: 65445,
        type_lot: 'prepair',
        brand: 'Samsung',
        title: 'iPhone 14 Pro Max 512 Гб, Deep Purple',
        imgs: [{img: img1, id: 1}, {img: img2, id: 2}, {img: img3, id: 3}]
    },
    6: {
        enter_price: 42343,
        type_lot: 'going',
        brand: 'Apple',
        title: 'iPhone 14 Pro Max 512 Гб, Deep Purple',
        imgs: [{img: img1, id: 1}, {img: img2, id: 2}, {img: img3, id: 3}]
    },
    7: {
        enter_price: 34213,
        type_lot: 'prepair',
        brand: 'Apple',
        title: 'iPhone 14 Pro Max 512 Гб, Deep Purple',
        imgs: [{img: img1, id: 1}, {img: img2, id: 2}, {img: img3, id: 3}]
    },
    8: {
        enter_price: 540234,
        type_lot: 'going',
        brand: 'Samsung',
        title: 'iPhone 14 Pro Max 512 Гб, Deep Purple',
        imgs: [{img: img1, id: 1}, {img: img2, id: 2}, {img: img3, id: 3}]
    },
    9: {
        enter_price: 543,
        type_lot: 'prepair',
        brand: 'MI',
        title: 'iPhone 14 Pro Max 512 Гб, Deep Purple',
        imgs: [{img: img1, id: 1}, {img: img2, id: 2}, {img: img3, id: 3}]
    },
    10: {
        enter_price: 756756,
        type_lot: 'going',
        brand: 'Apple',
        title: 'iPhone 14 Pro Max 512 Гб, Deep Purple',
        imgs: [{img: img1, id: 1}, {img: img2, id: 2}, {img: img3, id: 3}]
    },
    11: {
        enter_price: 540234,
        type_lot: 'prepair',
        brand: 'MI',
        title: 'iPhone 14 Pro Max 512 Гб, Deep Purple',
        imgs: [{img: img1, id: 1}, {img: img2, id: 2}, {img: img3, id: 3}]
    },
    12: {
        enter_price: 540234,
        type_lot: 'going',
        brand: 'Apple',
        title: 'iPhone 14 Pro Max 512 Гб, Deep Purple',
        imgs: [{img: img1, id: 1}, {img: img2, id: 2}, {img: img3, id: 3}]
    },
}

export const breadcrumbs = (navigate, CATEGORY, CATEGORY_ITEM, category_item, type, category, brand) => [
    {
        title: 'Главная',
        action: () => navigate(PATH.MAIN)
    },
    {
        title: window?.location?.pathname?.includes(PATH.MARKET) ? 'Товары' : 'Аукционы на повышение',
        action: () => navigate(PATH.MARKET),
    },
    {
        title: CATEGORY?.title,
        action: () => navigate(`${PATH.MARKET}/${category}`),
    },
    {
        title: CATEGORY_ITEM?.title,
        action: () => navigate(`${PATH.MARKET}/${category}/${category_item}`),
    },
    {
        title: brand,
    },

]
