import React, {useContext, useEffect} from 'react';
import s from "./styles.module.css";
import classNames from "classnames";
import ItemBox from "./item_box";
import ItemLine from "./item_line";
import moment from "moment-timezone";
import {TimeZoneContext} from "../../../App";
import {useTimer} from "react-timer-hook";

const AuctionItemUp = (props) => {
        const {timezone} = useContext(TimeZoneContext)

        const {
            totalSeconds,
            seconds: ss,
            minutes: mm,
            hours: hh,
            days: dd,
            isRunning,
            start,
            pause,
            resume,
            restart,
        } = useTimer({
            expiryTimestamp: new Date(props.default_timer),
            autoStart: true,
        });

        useEffect(() => {
            if (!props.default_timer) return
            restart(new Date(props?.default_timer), true)
        }, [props.default_timer])

        if (props.isLoadingUp) return null

        return (
            <div className={classNames(s.item, props.visibleType === 'line' && s.item_line)}>
                {props.visibleType !== 'line' &&
                    <ItemBox {...props}
                             startDateTime={moment.tz(props.start_date, 'Europe/Moscow').clone().tz(timezone)}
                             timer={{
                                 dd: dd === 0 ? 0 : (dd >= 10 ? dd : `0${dd}`),
                                 hh: hh >= 10 ? hh : `0${hh}`,
                                 mm: mm >= 10 ? mm : `0${mm}`,
                                 ss: ss >= 10 ? ss : `0${ss}`,
                                 totalSeconds: totalSeconds,
                                 isRunning: isRunning
                             }}
                             visibleType={props.visibleType}/>
                }
                {props.visibleType === 'line' &&
                    <ItemLine  {...props}
                               startDateTime={moment.tz(props.start_date, 'Europe/Moscow').clone().tz(timezone)}
                               timer={{
                                   dd: dd === 0 ? 0 : (dd >= 10 ? dd : `0${dd}`),
                                   hh: hh >= 10 ? hh : `0${hh}`,
                                   mm: mm >= 10 ? mm : `0${mm}`,
                                   ss: ss >= 10 ? ss : `0${ss}`,
                                   totalSeconds: totalSeconds,
                                   isRunning: isRunning
                               }}
                               visibleType={props.visibleType}/>}
            </div>
        );
    }
;

export default AuctionItemUp;
