import React, {useState} from 'react';
import s from './styles.module.css'
import HeaderModal from "../../../../components/header_modal";
import {useFormik} from "formik";
import {validate} from "./validate";
import Input from "../../../../common/ui_kit/inputs/input";
import Button from "../../../../common/ui_kit/button";
import TitleMobileAuth from "../../../../common/title_auth_mobile";
import {useLoginMutation} from "../../../../redux/auth.service";
import {cleanPhoneNumber} from "../../../../helper/clearNumber";
import {useGetUserInfoMutation} from "../../../../redux/user.service";
import {handlerError} from "../../../../helper/handlerError";
import {auctionsApi} from "../../../../redux/auctions.service";
import {useDispatch} from "react-redux";
import {setIsAuth} from "../../../../redux/slice/global.slice";
import {IconButton} from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

const LoginPassword = ({onClose, step, valueToSend, setStep, login: loginV2, enterChoose}) => {
    const dispatch = useDispatch()
    const [login, {isLoading}] = useLoginMutation()
    const [getUserInfo, {isLoadingInfo}] = useGetUserInfoMutation()

    const [typeInput, setTypeInput] = useState('password')

    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validate: validate,
        onSubmit: (values) => {
            login({
                login: enterChoose === 'phone_number' ? `+${cleanPhoneNumber(valueToSend)}` : valueToSend,
                password: values.password,
                type: enterChoose
            }).unwrap().then((res) => {
                getUserInfo().unwrap().then((res) => {
                    dispatch(setIsAuth(true))
                    setStep('confirm')
                    dispatch(auctionsApi.util.resetApiState());
                }).catch((e) => {
                    console.log(e)
                })


            }).catch((e) => {
                console.log(e)
                handlerError(e)

            })
        }
    })
    return (
        <div>
            <HeaderModal isMobile={true} onClose={onClose} onClick={() => setStep({...step, step: 1})}
                         title={'Введите пароль'}
                         arrow={true} typeModal={1}/>

            <TitleMobileAuth top={24} title={'Введите пароль'}/>

            <div className={s.enter_password_title}>
                <p>{step.enter_type === 'mobile' ?
                    <>Введите Ваш пароль</> :
                    <>Введите Ваш пароль</>}</p>
            </div>

            <form autoComplete="nope" className={s.form} onSubmit={(e) => e.preventDefault()}
                  style={{width: '100%'}}>
                <div className={s.enter_password_input}>
                    <Input style={{
                        paddingRight: '56px'
                    }} type={typeInput} className={s.input} value={formik.values.password}
                           name={'password'}
                           errorMessage={formik.touched.password && formik.errors.password}
                           onBlur={formik.handleBlur} onChange={formik.handleChange}
                           placeholder={'Введите пароль'}/>
                    <div className={s.show_pass}>
                        <IconButton
                            onMouseDown={() => setTypeInput('text')}
                            onMouseUp={() => setTypeInput('password')}
                            onTouchStart={() => setTypeInput('text')}
                            onTouchEnd={() => setTypeInput('password')}
                        >
                            {typeInput === 'password' ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                        </IconButton>
                    </div>
                </div>

                <div className={s.forget}>
                    <p onClick={() => setStep({...step, step: 4})}>Не помню пароль</p>
                </div>

                <div className={s.btns}>
                    <Button type={'submit'} disabled={isLoading || isLoadingInfo} onClick={formik.handleSubmit}
                            className={s.btn_enter}>
                        Подтвердить
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default LoginPassword;
