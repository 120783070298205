import React from 'react';
import s from "./styles.module.css";
import pdf1 from '../../assets/pdf/Пользовательское соглашение.pdf'
import pdf2 from '../../assets/pdf/Публичная оферта.pdf'
import pdf3 from '../../assets/pdf/Согласие на обработку персональных данных.pdf'
import {useMediaQuery} from "@mui/material";

const DescriptionSuccessInfo = () => {
    const query_700 = useMediaQuery('(max-width:780px)');
    return (
        <div className={s.description_box}>
            <p>
                Нажимая кнопку, вы подтверждаете, что ознакомились и согласны с
                <b>
                    <a href={pdf1} download={query_700} target={query_700 ? '' : '_blank'}> пользовательским
                        соглашением</a>
                </b>,
                <b>
                    <a href={pdf2} download={query_700} target={query_700 ? '' : '_blank'}> условиями оферты</a>
                </b>, даете своё
                <b>
                    <a href={pdf3} download={query_700} target={query_700 ? '' : '_blank'}> согласие на обработку
                        персональных данных</a>
                </b>.
            </p>
        </div>
    );
};

export default DescriptionSuccessInfo;
