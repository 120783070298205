import React from 'react';
import s from './styles.module.css'
import BreadCrumbs from "../../../components/bread_crumbs";
import {useMediaQuery} from "@mui/material";

const Header = ({breadcrumbs, currentPage}) => {
    const query_700 = useMediaQuery('(max-width:780px)');

    return (
        <div className={s.header}>
            {!query_700 && <BreadCrumbs breadcrumbs={breadcrumbs} last_dot={breadcrumbs.length}/>}

            <h2>{currentPage.sub_title || ''}</h2>
        </div>
    );
};

export default Header;
