import React from 'react';
import s from './styles.module.css'
import {useFormik} from "formik";
import {validate} from "./validate";
import HeaderModal from "../header_modal";
import TitleMobileAuth from "../../common/title_auth_mobile";
import Input from "../../common/ui_kit/inputs/input";
import Button from "../../common/ui_kit/button";
import CustomModal from "../../common/modal/custom_modal";

const LoginNewPassword = ({onClose, handleConfirm, open, isLoading}) => {
    const formik = useFormik({
        initialValues: {
            password: '',
            re_password: '',
        },
        validate: validate,
        onSubmit: (values) => {
            handleConfirm(values.password, () => onClose())
        }
    })
    return (
        <CustomModal open={open}
                     onClose={onClose}>
            <HeaderModal isMobile={true} onClose={onClose}
                         title={'Придумайте новый пароль'}
                         arrow={false} typeModal={0}/>

            <TitleMobileAuth top={24} title={'Придумайте новый пароль'}/>

            <form className={s.form} onSubmit={(e) => e.preventDefault()}>
                <div className={s.enter_password_input}>
                    <Input value={formik.values.password}
                           type={'password'}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           name={'password'}
                           errorMessage={formik.touched.password && formik.errors.password}
                           placeholder={'Новый пароль'}/>

                    <Input value={formik.values.re_password}
                           type={'password'}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           name={'re_password'}
                           errorMessage={formik.touched.password && formik.errors.re_password}
                           placeholder={'Повторите пароль'}/>
                </div>

                <Button disabled={isLoading} type={'submit'} onClick={formik.handleSubmit} className={s.btn}>Изменить
                    пароль</Button>
            </form>
        </CustomModal>
    );
};

export default LoginNewPassword;
