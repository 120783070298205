import React from 'react';

const Vk = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#384151"/>
            <rect x="8" y="8" width="32" height="32" rx="16" fill="#384151"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M34.1772 19.023C34.3255 18.5286 34.1772 18.1652 33.4713 18.1652H31.1375C30.544 18.1652 30.2706 18.4791 30.1222 18.8253C30.1222 18.8253 28.9353 21.7182 27.2539 23.5973C26.7099 24.1412 26.4627 24.3141 26.166 24.3141C26.0176 24.3141 25.8029 24.1412 25.8029 23.6468V19.023C25.8029 18.4296 25.6307 18.1652 25.1361 18.1652H21.4685C21.0978 18.1652 20.8747 18.4406 20.8747 18.7016C20.8747 19.2643 21.7153 19.3939 21.802 20.9763V24.4133C21.802 25.1668 21.6659 25.3035 21.3692 25.3035C20.578 25.3035 18.6536 22.3975 17.512 19.0725C17.2882 18.4262 17.0639 18.1652 16.4674 18.1652H14.1335C13.4667 18.1652 13.3333 18.4791 13.3333 18.8253C13.3333 19.4435 14.1247 22.5095 17.0175 26.5645C18.9463 29.3334 21.6633 30.8348 24.1358 30.8348C25.6195 30.8348 25.8029 30.5014 25.8029 29.9271V27.8341C25.8029 27.1673 25.9434 27.0342 26.413 27.0342C26.7594 27.0342 27.3528 27.2073 28.7376 28.5424C30.3199 30.1246 30.5807 30.8348 31.4709 30.8348H33.8048C34.4716 30.8348 34.805 30.5014 34.6126 29.8434C34.4022 29.1876 33.6467 28.2363 32.6442 27.1084C32.1002 26.4654 31.2842 25.7731 31.0371 25.427C30.6909 24.9819 30.7897 24.7842 31.0371 24.3888C31.0371 24.3888 33.8804 20.3832 34.1772 19.0232V19.023Z"
                  fill="white"/>
        </svg>
    );
};

export default Vk;
