import React from 'react';
import s from './styles.module.css'
import {ReactComponent as ArrowIcon} from "../../../../assets/svg/auction_mobile_top_left.svg";
import {IconButton, Tooltip} from "@mui/material";
import {NavLink} from "react-router-dom";
import {PATH} from "../../../../constants/path";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MusicOffIcon from "@mui/icons-material/MusicOff";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const MobileHeaderAction = ({isSilent, setIsSilent, navigate: navigateData, follow, handleClick, isLoading,handleOpenDelivery}) => {
    const {category, categoryItem} = navigateData

    return (
        <div className={s.main}>
            <div className={s.main_left}>
                <NavLink to={`${PATH.MARKET}/${category?.slug}/${categoryItem?.slug}`}>
                    <ArrowIcon/>
                </NavLink>
            </div>
            <div className={s.main_right}>
                <div className={s.header_btns}>
                    <Tooltip title={'Условия доставки'}>
                        <IconButton onClick={handleOpenDelivery}>
                            <LocalShippingOutlinedIcon/>
                        </IconButton>
                    </Tooltip>

                    {/* <Tooltip title={'Правила аукциона'}>
                        <IconButton>
                            <InfoOutlinedIcon/>
                        </IconButton>
                    </Tooltip> */}

                </div>
            </div>
        </div>
    );
};

export default MobileHeaderAction;
