import {createSlice} from "@reduxjs/toolkit";
import {auctionsApi} from "../auctions.service";

export const bidSlice = createSlice({
    name: 'bids',
    initialState: {
        bids: null,
        previousBids: null,
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            auctionsApi.endpoints.getBidAuction.matchFulfilled,
            (state, {payload}) => {
                state.previousBids = state.bids
                state.bids = payload
            }
        )
    },
    reducers: {
        setBids: (state, action) => {
            if (state.bids.results && state.bids.results?.length >= 12) {
                state.bids.results.shift()
            }
            state.bids = {
                ...state.bids,
                count: action.payload.count,
                results: [...state.bids.results, action.payload.bid]
            }
        },
        setBidsAll: (state, action) => {
            state.bids = {...state.bids, results: action.payload.bids}
        },
    }
})

export const {setBids, setBidsAll} = bidSlice.actions

export const selectBids = (state) => state.bids;

export default bidSlice.reducer
