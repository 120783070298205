import React from 'react';
import s from "./styles.module.css";
import {ReactComponent as Confirm} from "../../assets/svg/confirm.svg";
import {ReactComponent as Close} from "../../assets/svg/close.svg";
import Button from "../../common/ui_kit/button";

const ConfirmModal = ({onClose, sub_title = 'Данные изменены', confirm, title = 'Успешно',btn_title = 'Отлично!'}) => {
    return (
        <div className={s.confirm}>
            <div className={s.header}>
                <div className={s.header_left}/>
                <div className={s.confirm_icon}>
                    <Confirm/>
                </div>
                <div className={s.close} onClick={onClose}>
                    <Close/>
                </div>
            </div>


            <h3>{title}</h3>

            <p>{sub_title}</p>

            <Button className={s.btn} onClick={() => confirm ? confirm() : onClose()}>{btn_title}</Button>
        </div>
    );
};

export default ConfirmModal;
