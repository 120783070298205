import React, {useEffect, useRef, useState} from 'react';
import s from './styles.module.css'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import classNames from "classnames";
import {useMediaQuery} from "@mui/material";

const VideoPlayer = ({src, poster}) => {
    const query_780 = useMediaQuery('(max-width:780px)');

    const videoRef = useRef(null);
    const hideTimeoutRef = useRef(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [isMouseMoving, setIsMouseMoving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handlePlayPause = () => {
        const video = videoRef.current;
        if (video.paused) {
            video.play();
            setIsPlaying(true);
        } else {
            video.pause();
            setIsPlaying(false);
        }
    };

    useEffect(() => {
        const video = videoRef.current;

        const updatePlayingStatus = () => {
            setIsPlaying(!video.paused);
        };

        video.addEventListener('play', updatePlayingStatus);
        video.addEventListener('pause', updatePlayingStatus);
        // Убираем слушатели при размонтировании компонента
        return () => {
            video.removeEventListener('play', updatePlayingStatus);
            video.removeEventListener('pause', updatePlayingStatus);
        };
    }, []);
    console.log(isLoading)
    useEffect(() => {
        if (isPlaying) {
            const handleMouseMove = () => {
                setIsMouseMoving(true);
                clearTimeout(hideTimeoutRef.current);
                hideTimeoutRef.current = setTimeout(() => {
                    setIsMouseMoving(false);
                }, 1000);
            };

            if (isPlaying) {
                window.addEventListener('mousemove', handleMouseMove);
                clearTimeout(hideTimeoutRef.current);
                hideTimeoutRef.current = setTimeout(() => {
                    setIsMouseMoving(false);
                }, 1000); // Запускаем таймер, который скроет кнопку через 1 секунду без движения мыши
            }

            // window.addEventListener('mousemove', handleMouseMove);

            return () => {
                window.removeEventListener('mousemove', handleMouseMove);
                clearTimeout(hideTimeoutRef.current);
            };
        }
    }, [isPlaying]);

    return (
        <div className={classNames(
            s.custom_video_container,
            !isPlaying && s.custom_video_container_inactive
        )}>
            <video
                ref={videoRef}
                className={s.custom_video}
                controls
                onLoadedData={(l) => {
                    console.log('onLoadedData')
                    setIsLoading(false)
                }}
                onLoadStart={(l) => {
                    setIsLoading(true)
                }}
                onWaiting={() => {
                    console.log('onWaiting')
                    setIsLoading(true)
                }}
                onCanPlay={() => {
                    setIsLoading(false)
                    console.log('onCanPlay')
                }}

                poster={poster}
            >
                <source src={src} type="video/mp4"/>
                Ваш браузер не поддерживает HTML5 видео.
            </video>
            {!isLoading && <div
                className={classNames(s.play_pause_button, (!isMouseMoving && isPlaying) && s.play_pause_button_hidden)}
                onClick={handlePlayPause}
            >
                {isPlaying ? <PauseIcon
                        sx={{color: '#222C3E', height: query_780 ? '24px' : '40px', width: query_780 ? '24px' : '40px'}}/> :
                    <PlayArrowIcon sx={{
                        color: '#222C3E',
                        height: query_780 ? '24px' : '40px',
                        width: query_780 ? '24px' : '40px'
                    }}/>}
            </div>}
        </div>
    );
};

export default VideoPlayer;