import {createSlice} from "@reduxjs/toolkit";
import {categoryApi} from "../category.service";

export const categorySlice = createSlice({
    name: 'auth',
    initialState: {
        category: {}
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            categoryApi.endpoints.getCategorySubCategory.matchFulfilled,
            (state, {payload}) => {
                state.category = payload
            }
        )
    },
})

export const selectCategory = (state) => state.category;

export default categorySlice.reducer
