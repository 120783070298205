import React, {useRef, useState} from 'react';
import Container from "../../../../components/container";
import s from './styles.module.css'
import {useMediaQuery} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import VideoPlayer from "../../../../common/videoPlayer";
import {ReactComponent as ArrowLeft} from "../../../../assets/svg/arrow_left.svg";
import {ReactComponent as ArrowRight} from "../../../../assets/svg/arrow_right.svg";
import classNames from "classnames";

import rev1 from '../../../../assets/video/rev1.MOV'
import rev2 from '../../../../assets/video/rev2.MOV'
import rev3 from '../../../../assets/video/rev3.MOV'
import rev4 from '../../../../assets/video/rev4.MOV'
import rev5 from '../../../../assets/video/rev5.MOV'
import rev6 from '../../../../assets/video/rev6.mp4'

import post1 from '../../../../assets/video/post1.png'
import post2 from '../../../../assets/video/post2.png'
import post3 from '../../../../assets/video/post3.png'
import post4 from '../../../../assets/video/post4.png'
import post5 from '../../../../assets/video/post5.png'
import post6 from '../../../../assets/video/post6.png'

const revVideo = [
    {id: 6, src: rev6, poster: post6},
    {id: 1, src: rev1, poster: post1},
    {id: 2, src: rev2, poster: post2},
    {id: 3, src: rev3, poster: post3},
    {id: 4, src: rev4, poster: post4},
    {id: 5, src: rev5, poster: post5},
]

const Page5 = () => {
    const swiperRef = useRef();
    const query_780 = useMediaQuery('(max-width:780px)');
    const [progress, setProgress] = useState(0)
    return (
        <Container>
            <div className={s.content}>
                <h4 className={s.title}>Отзывы о нас</h4>

                {/*{!query_780 && <div className={s.box}>*/}
                {/*    {revVideo?.map((el) => {*/}
                {/*        return <div key={el?.id} className={s.item}>*/}
                {/*            <VideoPlayer src={el.src} poster={el.poster}/>*/}
                {/*        </div>*/}
                {/*    })}*/}
                {/*</div>}*/}
                <div className={s.swipper_box}>
                    <Swiper
                        onActiveIndexChange={(e) => {
                            console.log(e)
                            setProgress(e?.progress)
                        }}
                        spaceBetween={!query_780 ? 40 : 24}
                        slidesPerView={!query_780 ? 4 : 2}
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                    >
                        {revVideo?.map((el, i) => <SwiperSlide key={i}>
                            <div key={el?.id} className={s.item}>
                                <VideoPlayer src={el.src} poster={el.poster}/>
                            </div>
                        </SwiperSlide>)}
                    </Swiper>

                    <div className={s.action_swipper}>
                        <p className={classNames(progress === 0 && s.disabled)}
                           onClick={() => progress !== 0 && swiperRef?.current?.slidePrev()}>
                            <ArrowLeft/>
                            Предыдущее
                        </p>
                        <p className={classNames(progress === 1 && s.disabled)}
                           onClick={() => progress !== 1 && swiperRef.current?.slideNext()}>
                            Следующее
                            <ArrowRight/>
                        </p>
                    </div>
                </div>

            </div>
        </Container>
    );
};

export default Page5;