import React from 'react';
import s from "./styles.module.css";
import classNames from "classnames";
import {ReactComponent as Arrow} from "../../assets/svg/arrow_info.svg";
import {ReactComponent as Close} from "../../assets/svg/close.svg";

const HeaderModal = ({
                         typeModal,
                         title,
                         onClick,
                         arrow = false,
                         onClose,
                         style,
                         isMobile = false,
                         isHiddenIconClose = false,
                         classNameTitle,
                         isAbsolute = false,
                         isUpTop = false,
                     }) => {
    return (
        <div className={classNames(s.header, isMobile && s.header_modile, isUpTop && s.header_up_top)} style={style}>
            {typeModal === 1 && <div className={classNames(s.header_left, arrow && s.arrow, isAbsolute && s.isAbsolute)}
                                     onClick={onClick}>
                {arrow && <Arrow/>}
            </div>}
            <h2 className={classNames(s.title, classNameTitle)} style={{
                textAlign: typeModal === 1 ? 'center' : ' left',
                maxWidth: arrow && '320px',
                minWidth: arrow && '320px'
            }}>{title}</h2>

            {!isHiddenIconClose && <div className={s.close} onClick={onClose}>
                <Close/>
            </div>}
        </div>
    );
};

export default HeaderModal;
