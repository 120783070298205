import React, { useContext, useRef, useState } from 'react';
import s from './styles.module.css'
import StartPrice from "./start_price";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import ReactImageZoom from "react-image-zoom";
import classNames from "classnames";
import ModalImgView from "./modal";
import MobileHeaderAction from "./mobile_header_action";
import MobileImages from "./mobile_images";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ModalDelivery from "../../../components/modal_delivery_auction";
import ModalRoot from "../../../components/modal_root_auction";
import CharacterProduct from "./character_product";
import AuctionList from "./auction_list";
import { useAddedToBucketMutation } from "../../../redux/auctions.service";
import { toast } from "react-toastify";
import { AuthContext } from "../../../App";
import { useDispatch } from 'react-redux';
import { myLotsApi } from '../../../redux/my_lots.service';

const Info = ({ images_data, data, navigate }) => {
    const [addedToBucket, { isLoading: isLoadingAdded }] = useAddedToBucketMutation()

    const authModal = useContext(AuthContext)

    const [showImg, setShowImg] = useState(0)
    const [openModalImgs, setOpenModalImgs] = useState(false)
    const [modal, setModal] = useState({
        delivery: false,
        root: false
    })

    const props = { width: 428, zoomWidth: 500, img: images_data[showImg] };

    const images = images_data?.length > 5 ? images_data?.slice(0, 4) : images_data
    const imagesMoreThanFive = images_data?.length > 5
    const dispatch = useDispatch()
    const refLeftBox = useRef()

    const handleAddedBucket = (id) => {
        if (authModal?.isAuth) {
            addedToBucket(id ? id : data?.id)
                .unwrap()
                .then((res) => {
                    console.log(res)
                    toast.success('Товар добавлен в "Мои заказы"')
                    dispatch(myLotsApi.util.invalidateTags(['lots']));
                })
                .catch((e) => {
                    toast.error(e.data?.detail || 'Ошибка')
                    console.log(e)
                })
        } else {
            authModal.actionModal(true, 'Для данного действия необходима авторизация')
        }
    }
    return (

        <div className={s.info_box}>
            {openModalImgs &&
                <ModalImgView open={openModalImgs} images={images_data} index={showImg}
                    title={data?.title}
                    description={data?.description}

                    onClose={() => setOpenModalImgs(false)} />}
            <ModalDelivery deliveryTerm={{
                "description": "<div class=\"dc\"><p><strong>Отправка товара:</strong> в течении <strong>5-ти дней</strong> после оплаты</p><p><strong>Стоимость доставки</strong>: бесплатно</p><p><strong>Способы доставки: </strong></p><ul>\n<li>Курьерская доставка/пункт выдачи в г. Смоленск (2-7 дней)</li>\n<li>Курьерская компания CDEK (2-7 дней)</li>\n<li>Почта России (5-15 дней)</li>\n</ul></div>",
                "title": ""
            }}
                hiddenNext={true}
                onClick={() => setModal({ delivery: false, root: true })}
                open={modal.delivery}
                onClose={() => setModal({ ...modal, delivery: false })} />
            {/* <ModalRoot auctionRules={data?.auction_rules} onClick={() => setModal({delivery: true, root: false})}
                       open={modal.root}
                       onClose={() => setModal({...modal, root: false})}/> */}

            <div className={s.wrapper_img_content}>
                <div className={s.wrapper_bg_main}>
                    <div style={{
                        height: 'fit-content',
                        width: 'fit-content',
                        cursor: 'pointer'
                    }} onClick={() => setOpenModalImgs(true)}>
                        {images_data?.length !== 0 ? <ReactImageZoom  {...props} /> :
                            <span className={s.empty}>Нет фото</span>}
                    </div>
                </div>

                <div className={s.other_photo_main}>
                    {images?.map((el, i) => {
                        return <div
                            key={i}
                            className={classNames(s.choose_img, i !== showImg && s.choose_img_hover)}
                            onMouseEnter={() => setShowImg(i)}>
                            <Avatar sx={{
                                height: '100%',
                                width: '100%',
                                borderRadius: '8px',
                                opacity: showImg === i ? 1 : 0.3
                            }} alt={'image'}
                                src={el} />
                        </div>

                    })}
                    {imagesMoreThanFive && <div className={s.plus} onClick={() => setOpenModalImgs(true)}>
                        +{images_data?.length - 4}
                    </div>}
                </div>
            </div>

            <div className={s.mobile_top_content}>
                <MobileHeaderAction follow={data?.follow}
                    handleOpenDelivery={() => setModal({ ...modal, delivery: true })}
                    navigate={navigate} />
                <MobileImages title={data?.title} images={data?.images} id={data?.id} />
            </div>

            <div className={s.info}>
                <div className={s.header}>
                    <Tooltip title={data?.product?.title}>
                        <span className={s.header_header}>{data?.title}</span>
                    </Tooltip>
                    <div className={s.header_btns}>
                        <Tooltip title={'Условия доставки'}>
                            <IconButton onClick={() => setModal({ ...modal, delivery: true })}>
                                <LocalShippingOutlinedIcon />
                            </IconButton>
                        </Tooltip>

                        {/* <Tooltip title={'Правила аукциона'}>
                            <IconButton onClick={() => setModal({...modal, root: true})}>
                                <InfoOutlinedIcon/>
                            </IconButton>
                        </Tooltip> */}

                    </div>
                </div>

                <div className={s.box_content_info}>
                    <div className={s.box_content_info_left} ref={refLeftBox}>
                        <StartPrice price={data?.shop_price || 0}
                            haveInAuc={data?.inc_aucs}
                            category={data?.category}
                            subcategory={data?.subcategory}
                            old_price={data?.retail_price || 0}
                            title={'Цена товара'} />
                        <CharacterProduct isLoadingAdded={isLoadingAdded} data={data}
                            handleAddedBucket={handleAddedBucket} />
                    </div>

                    <AuctionList refLeftBox={refLeftBox} data={data} />
                </div>

            </div>
        </div>

    );
};

export default Info;
