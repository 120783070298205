import React, {useContext, useState} from 'react';
import s from './styles.module.css'
import {Avatar, Tooltip} from "@mui/material";
import classNames from "classnames";
import Button from "../../../../common/ui_kit/button";
import ViewModal from "./view_modal";
import {MoveContext} from "../../index";

const ItemWrapper = ({children, title}) => {
    return <div className={s.item}>
        <p className={s.title_item}>{title}</p>
        {children}
    </div>
}

const CharacterProduct = ({data, handleAddedBucket, isLoadingAdded}) => {
    const {refDescription, refCharacter} = useContext(MoveContext)

    const [viewModal, setViewModal] = useState(null)
    const attributes = (data?.attributes && Object.keys(data?.attributes)?.length > 0) ? (Object.keys(data?.attributes)?.length > 4 ? Object.keys(data?.attributes)?.slice(0, 4)?.map((key) => ({
        title: key,
        value: data?.attributes[key]
    })) : Object.keys(data?.attributes)?.map((key) => ({title: key, value: data?.attributes[key]}))) : []

    const handleScrollToDescription = () => {
        if (refDescription.current) {
            const top = refDescription.current.getBoundingClientRect().top + window.scrollY - 40;
            window.scrollTo({top, behavior: 'smooth'});
        }
    };

    const handleScrollToCharacter = () => {
        if (refCharacter.current) {
            const top = refCharacter.current.getBoundingClientRect().top + window.scrollY - 40;
            window.scrollTo({top, behavior: 'smooth'});
        }
    };

    return (
        <div className={s.main}>
            {viewModal && <ViewModal isLoadingAdded={isLoadingAdded} handleAddedBucket={handleAddedBucket} open={viewModal} onClose={() => setViewModal(null)}/>}

            <div className={s.info_box}>
                <ItemWrapper title={'Варианты'}>
                    {(!data?.variants || data?.variants?.length === 0) ? <div className={s.empty}>Другие варианты отсутствуют</div> : <div className={s.variants}>
                        {data?.variants?.map((el) => {
                            return <Tooltip key={el.id} title={el?.title}>
                                <div key={el.id} className={s.variant} onClick={() => setViewModal(el)}>
                                    <Avatar src={el?.hero_image || null}
                                            sx={{height: '100%', width: '100%', borderRadius: '0'}}/>
                                </div>
                            </Tooltip>
                        })}
                    </div>}
                </ItemWrapper>
                <ItemWrapper title={'Характеристики'}>
                    <div className={s.info_box_inside}>
                        <div className={s.info_box_inside_top}>
                            {attributes?.map((el, i) => {
                                return <div
                                    className={classNames(s.content_item_line)}>
                                    <p className={s.content_item_title}>
                                        {el?.title}
                                    </p>
                                    {/*<div className={s.line_dash}/>*/}
                                    <Tooltip title={el?.value}>
                                        <p
                                            className={s.content_item_duration}>
                                            {el?.value}
                                        </p>
                                    </Tooltip>
                                </div>
                            })}
                        </div>

                        <p className={s.link} onClick={handleScrollToCharacter}>Все характеристики</p>
                    </div>
                </ItemWrapper>

                {/* <ItemWrapper title={'Описание'}>
                    <div className={s.info_box_inside}>
                        <div className={s.info_box_inside_des}>
                            <p className={s.info_box_inside_description}
                               dangerouslySetInnerHTML={{__html: data?.description}}/>
                        </div>

                        <p className={s.link} onClick={handleScrollToDescription}>Читать далее</p>
                    </div>
                </ItemWrapper> */}
            </div>
            <Button className={s.btn} disabled={isLoadingAdded} loading={isLoadingAdded} onClick={handleAddedBucket}>
                Добавить в корзину
            </Button>
        </div>
    );
};

export default CharacterProduct;
