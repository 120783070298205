import React from 'react';
import {spaceBtwHundreds} from "../../../helper/spaceBetweenSum";
import s from './styles.module.css'
import {Slider, styled} from "@mui/material";
import molotIcon from "../../../assets/svg/molot.svg";
import classNames from "classnames";

const AirbnbSlider = styled(Slider)(({theme}) => ({
    color: '#5654D4',
    // color: '#29B942',
    // height: 'auto',
    // padding: '0 0',
    '&.MuiSlider-root': {
        // height: '17px',
    },
    '& .MuiSlider-thumb': {
        height: 27,
        width: 27,
        backgroundColor: '#FECF2A',

        border: 'none',
        '&:hover': {
            // boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: '2',
            transform: 'translate(-50%, -50%)',
            width: '15px', // Укажите размер иконки
            height: '15px',
            backgroundImage: `url(${molotIcon})`, // Укажите путь к вашей иконке
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        },
    },
    '& .MuiSlider-track': {
        height: '12px',
    },
    '& .MuiSlider-rail': {
        color: '#d8d8d8',
        opacity: 1,
        height: '12px',
    },
}));

const CustomSlider = ({classNameSliderBox,min_price, max_price,step = 1,value,handleChange}) => {
    return (
        <div className={classNames(s.slider_box,classNameSliderBox)}>
            <div className={s.info_box}>
                <p>{`${min_price} ₽`}</p>
                <p>{`${spaceBtwHundreds(max_price)} ₽`}</p>
            </div>

            <div className={s.slider}>
                <AirbnbSlider
                    value={value}
                    onChange={handleChange}
                    defaultValue={value}
                    shiftStep={step}
                    step={step}
                    min={min_price}
                    max={max_price}
                />
            </div>
        </div>
    );
};

export default CustomSlider;