export const validate = (values) => {
    const errors = {};

    if (!values.code) {
        errors.code = 'Обязательное поле';
    } else if (values.code.length !== 7) {
        errors.code = 'Заполните поле до конца';
    }
    return errors;


}
