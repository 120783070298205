import React, {useContext} from 'react';
import s from './styles.module.css'
import {ReactComponent as AuctionUpIcon} from "../../../../assets/svg/auction_up.svg";
import {spaceBtwHundreds} from "../../../../helper/spaceBetweenSum";
import {calculateTimeDifference, formatDateTime} from "../../../../helper/convertDate";
import {TimeZoneContext} from "../../../../App";
import {useSelector} from "react-redux";
import {selectCategory} from "../../../../redux/slice/category_subcategory.slice";
import {PATH} from "../../../../constants/path";
import {NavLink} from "react-router-dom";
import {Avatar, Tooltip} from "@mui/material";
import classNames from "classnames";

function calculatePercent(mall_price, sell_price) {
    const diff = mall_price - sell_price;
    const percent = (diff / mall_price) * 100;
    return percent === 0 ? 0 : percent
}

function toFixed(number) {
    const decimalPart = number.toString().split('.')[1];
    if (decimalPart && parseInt(decimalPart) !== 0) {
        return number.toFixed(2);
    }
    return Math.round(number);
}

const ItemMobile = ({
                        isModalType = false,
                        end_date, highest_bid_amount, product, start_date, id
                    }) => {
    const {timezone} = useContext(TimeZoneContext)
    const {category: category_data} = useSelector(selectCategory)

    const category_slag = category_data?.results?.find((f) => f.id === product?.category)
    const sub_category_slag = category_slag?.subcategory_set?.find((f) => f.id === product?.subcategory)

    const convertDate = formatDateTime(start_date, timezone)

    return (
        <NavLink to={`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${id}`}>
            <div className={classNames(s.item,isModalType && s.item_modal)}>
                <div className={s.item_header}>
                    <div className={s.item_header_img}>
                        <div className={s.item_header_img_type}>
                            <Tooltip title={'Торги на повышение'}>
                                <AuctionUpIcon/>
                            </Tooltip>
                        </div>
                        <Avatar src={product?.hero_image} alt=""
                                sx={{height: '100%', width: '100%', borderRadius: '3px'}}/>
                    </div>
                    <div className={s.item_header_description}>
                        <p className={s.item_header_description_title}>{product?.title}</p>
                        {highest_bid_amount ? <p className={s.item_header_description_percent}><span style={{
                                color: calculatePercent(product?.retail_price, highest_bid_amount) > 0 ? '' : 'red'
                            }}>
                        {calculatePercent(product?.retail_price, highest_bid_amount) > 0 ? `-${spaceBtwHundreds(toFixed(calculatePercent(product?.retail_price, highest_bid_amount)))}` : `+${spaceBtwHundreds(toFixed(Math.abs(calculatePercent(product?.retail_price, highest_bid_amount))))}`}%
                    </span> от розничной цены</p> :
                            <p className={s.item_header_description_percent}>Аукцион не состоялся</p>}
                    </div>
                </div>

                <div className={s.line}/>

                <div className={s.item_info}>
                    <div className={s.item_info_box}>
                        <p className={s.item_info_box_title}>Дата</p>
                        <p className={s.item_info_box_sub_title}>{convertDate}</p>
                    </div>
                    <div className={s.item_info_box}>
                        <p className={s.item_info_box_title}>Время торгов</p>
                        <p className={s.item_info_box_sub_title}>{calculateTimeDifference(start_date, end_date)}</p>
                    </div>
                    <div className={s.item_info_box}>
                        <p className={s.item_info_box_title}>Розничная цена</p>
                        <p className={s.item_info_box_sub_title}> {spaceBtwHundreds(product?.retail_price)} ₽</p>
                    </div>
                    <div className={s.item_info_box}>
                        <p className={s.item_info_box_title}>Цена выкупа</p>
                        <p className={s.item_info_box_sub_title}>{highest_bid_amount ? `${spaceBtwHundreds(highest_bid_amount)} ₽` : '0 ₽'}</p>
                    </div>
                </div>
            </div>
        </NavLink>
    );
};

export default ItemMobile;
