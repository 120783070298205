import React, {useState} from 'react';
import s from './styles.module.css'
import TextExpander from "./textExpander";
import {motion} from 'framer-motion';
import {IconButton} from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Description = ({description}) => {
    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <div className={s.description} id={'description'}>

            <div className={s.description_left}>
                <h2>Описание</h2>
                <div className={s.hidden}
                     onClick={() => setIsExpanded(!isExpanded)}>
                    <IconButton>
                        {!isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </IconButton>
                </div>
            </div>
            <motion.div
                className={s.description_right}
                initial={false}
                animate={{height: !isExpanded ? 'auto' : 0}}
                style={{overflow: 'hidden'}} // Скрываем содержимое при сворачивании
                transition={{duration: 0.5}} // Плавность анимации
            >
                <TextExpander text={description} line={8}/>
            </motion.div>
        </div>
    );
};

export default Description;
