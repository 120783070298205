import moment from "moment-timezone";
import {case_num_f} from "./okonchania";

export function formatDateTime(inputDate, timezone) {
    const date = moment.tz(inputDate, 'Europe/Moscow').clone().tz(timezone)

    const day = date.format('DD');
    const month = date.format('MM');
    const year = date.format('YYYY');
    const hours = date.format('HH');
    const minutes = date.format('mm');

    // Форматирование с добавлением ведущих нулей
    const formattedDate = `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year} ${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;

    return formattedDate;
}

export function calculateTimeDifference(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);

    const duration = moment.duration(end.diff(start));

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    // Форматирование с добавлением ведущих нулей
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    let formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    const countTitleConvert = case_num_f(['день', 'дня', 'дней']);

    // Если есть дни, добавить их к строке
    if (days > 0) {
        formattedTime = `${days} ${countTitleConvert(days)} ${formattedTime}`;
    }

    return formattedTime;
}

export function formatDate(inputDate) {
    const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];

    const [year, month, day] = inputDate.split('-');
    const formattedDate = new Date(year, month - 1, day); // Месяцы в JavaScript начинаются с 0

    const dayOfMonth = formattedDate.getDate().toString().padStart(2, '0'); // Добавляем ноль перед днем при необходимости
    const monthName = months[formattedDate.getMonth()];
    const fullYear = formattedDate.getFullYear();

    return `${dayOfMonth} ${monthName} ${fullYear}`;
}

export function formatDateTimeNameMonth(inputDate) {

    const date = new Date(inputDate);

    const optionsDate = {
        day: 'numeric',
        month: 'short',
    };

    const optionsTime = {
        hour: 'numeric',
        minute: 'numeric',
    };

    const formattedDate = date.toLocaleDateString('ru-RU', optionsDate);
    const formattedTime = date.toLocaleTimeString('ru-RU', optionsTime);

    return `${formattedDate}, ${formattedTime}`;
}

export function formatDateNameWeek(inputDate) {
    const date = new Date(inputDate);

    const optionsDate = {
        day: 'numeric',
        month: 'short',
    };

    const optionsDay = {
        weekday: 'short',
    };

    const formattedDate = date.toLocaleDateString('ru-RU', optionsDate);
    const formattedDay = date.toLocaleDateString('ru-RU', optionsDay);

    return `${formattedDate}, ${formattedDay}`;
}

export const formatDateGetTime = (inputDate) => {
    const dateMomemnt = moment.tz(inputDate || '', 'Europe/Moscow').clone().tz(moment.tz.guess())
    return dateMomemnt.format('HH:mm')
    // return `${dateMomemnt.}`
}

