import React from 'react';
import classNames from "classnames";
import s from "./styles.module.css";
import {short_month} from "../../../../helper/date";

const StatusTitle = ({status_item, startDateTime, visibleType = 'box'}) => {
    const start_date_time = startDateTime
    const start_full_time = `${start_date_time.hours() >= 10 ? start_date_time.hours() : `0${start_date_time.hours()}`}:${start_date_time.minutes() >= 10 ? start_date_time.minutes() : `0${start_date_time.minutes()}`}`
    return (
        <>
            {status_item === 'end' &&
                <div
                    className={classNames(s.header_title, s.header_title_completed, visibleType === 'line' && s.header_title_line)}>Торги
                    завершены</div>}
            {status_item === 'run' &&
                <div
                    className={classNames(s.header_title, s.header_title_binding, visibleType === 'line' && s.header_title_line)}>Идут
                    торги</div>}
            {status_item === 'pub' &&
                <div
                    className={classNames(s.header_title, s.header_title_published, visibleType === 'line' && s.header_title_line)}>{`старт: ${start_date_time.date()} ${short_month[start_date_time.month()]} ${start_date_time.year()}, ${start_full_time}`}</div>}

        </>
    );
};

export default StatusTitle;
