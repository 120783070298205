import React, {useEffect, useState} from 'react';
import s from "./styles.module.css";
import {spaceBtwHundreds} from "../../../../helper/spaceBetweenSum";
import {ReactComponent as Up} from "../../../../assets/svg/auction_up.svg";
import classNames from "classnames";
import {Tooltip} from "@mui/material";
import ModalAction from "../modal_action";
import {useMotionValue, useTransform, motion, animate} from "framer-motion";
import {calcPercent} from "../../../auctions/content_market/item_market/item_market_box";
import {ReactComponent as MolotIcon} from "../../../../assets/svg/molot.svg";
import {useSelector} from "react-redux";
import {selectCategory} from "../../../../redux/slice/category_subcategory.slice";
import {PATH} from "../../../../constants/path";
import {useNavigate} from "react-router-dom";

const StartPrice = ({price, visibleTitle = true, title, old_price, haveInAuc, category, subcategory}) => {
    const navigate = useNavigate()
    const {category: category_data} = useSelector(selectCategory)

    const category_slag = category_data?.results?.find((f) => f.id === category)
    const sub_category_slag = category_slag?.subcategory_set?.find((f) => f.id === subcategory)

    const handleGoLinkAuc = () => {
        if (haveInAuc && haveInAuc?.length !== 0) {
            navigate(`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${haveInAuc[0] || 0}`)
        }
    }
    return (
        <div className={classNames(s.start_price, s.start_price_run)}>

            {visibleTitle && <p className={s.start_price_title}>{title}</p>}
            <div className={s.start_price_box}>
                <div className={classNames(s.start_price_box_info)}>
                    <p className={classNames(s.start_price_box_info_title)}>{`${spaceBtwHundreds(price)}`} ₽</p>
                    <p className={classNames(s.start_price_box_info_title, s.start_price_box_info_title_old)}
                       data-price={`${calcPercent(old_price, price)}`}>{`${spaceBtwHundreds(old_price)}`} ₽</p>
                </div>
                {visibleTitle && <div className={s.price_box_right} onClick={handleGoLinkAuc} style={{
                    cursor: haveInAuc && 'pointer'
                }}>
                    {(haveInAuc && haveInAuc?.length !== 0) && <div className={s.molot_icon}>
                        <MolotIcon/>
                    </div>}
                    <p className={classNames(s.molot_title, !(haveInAuc && haveInAuc?.length !== 0) && s.molot_title_no_auc)}>{(haveInAuc && haveInAuc?.length !== 0) ? 'Есть на аукционе' : 'Нет на аукционе'}</p>
                </div>}
            </div>

        </div>
    );
};

export default StartPrice;
