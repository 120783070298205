export const validate = (values) => {
    const errors = {};

    if (!values.brands) {
        errors.brands = 'Обязательное поле';
    } else if (values.brands?.length < 3) {
        errors.brands = 'Минимальная длина 3 символа';
    } else if (values.brands?.length >= 1024) {
        errors.brands = 'Максимальная длина 1024 символа';
    }

    if (!values.repr_phone_number) {
        errors.repr_phone_number = 'Обязательное поле';
    } else if (values.repr_phone_number.length !== 18) {
        errors.repr_phone_number = 'Неверный номер телефона';
    }

    if (!values.repr_email) {
        errors.repr_email = 'Обязательное поле';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.repr_email)) {
        errors.repr_email = 'Неверный email адресс';
    }

    if (!values.repr_name) {
        errors.repr_name = 'Обязательное поле';
    } else if (values.repr_name?.length < 3) {
        errors.repr_name = 'Минимальная длина 3 символа';
    } else if (values.repr_name?.length >= 48) {
        errors.repr_name = 'Максимальная длина 48 символа';
    }

    if (!values.organization) {
        errors.organization = 'Обязательное поле';
    } else if (values.organization?.length < 3) {
        errors.organization = 'Минимальная длина 3 символа';
    } else if (values.organization?.length >= 255) {
        errors.organization = 'Максимальная длина 255 символа';
    }

    if (!values.category) {
        errors.category = 'Обязательное поле';
    }

    return errors;
}
