import React from 'react';
import classNames from "classnames";
import s from './styles.module.css'
import {Box, CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";

const Button = ({
                    children,
                    onClick,
                    type_btn = 0,
                    style,
                    type = 'button',
                    disabled = false,
                    className,
                    loading = true,
                    link = ''
                }) => {
    const navigate = useNavigate()

    return (
        <button style={style} type={type} disabled={disabled}
                onClick={() => link ? navigate(link) : (onClick ? onClick() : {})}
                className={classNames(s.btn, type_btn === 1 && s.new_btn, disabled && s.disabled, className)}>
            {(disabled && loading) ? <Box sx={{display: 'flex'}}>
                <CircularProgress/>
            </Box> : children}
        </button>
    );
};

export default Button;
