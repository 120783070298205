export const validate = (values) => {
    const errors = {}

    if (!values.email) {
        errors.email = 'Обязательное поле';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Неверный email адресс';
    }

    if (!values.q_text) {
        errors.q_text = 'Обязательное поле';
    } else if (values.q_text?.length < 10) {
        errors.q_text = 'Минимальная длина 10 символа';
    } else if (values.q_text?.length > 1000) {
        errors.q_text = 'Максимальная длина 1000 символа';
    }

    if (!values.q_theme) {
        errors.q_theme = 'Обязательное поле';
    }

    if (!values.name) {
        errors.name = 'Обязательное поле';
    } else if (values.name?.length < 3) {
        errors.name = 'Минимальная длина 3 символа';
    } else if (values.name?.length > 48) {
        errors.name = 'Максимальная длина 48 символа';
    }
    return errors;
}
