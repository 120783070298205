import {createSlice} from "@reduxjs/toolkit";
import {favoriteApi} from "../favority.service";

export const favoriteSlice = createSlice({
    name: 'favoriteSlice',
    initialState: {
        favorites: null
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            favoriteApi.endpoints.getFavorite.matchFulfilled,
            (state, {payload}) => {
                state.favorites = payload
            }
        )
    },
    reducers: {
        setFavorites: (state, action) => {
            state.favorites = {...state.favorites, ...action.payload}
        },
    }
})

export const {setFavorites} = favoriteSlice.actions

export const selectFavorites = (state) => state.favorites;

export default favoriteSlice.reducer
