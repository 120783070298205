import React from 'react';
import s from './styles.module.css'
import Container from "../../../../components/container";
import icon1 from '../../../../assets/images/main/page2_1.png'
import icon2 from '../../../../assets/images/main/page2_2.png'
import icon3 from '../../../../assets/images/main/page2_3.png'
import icon4 from '../../../../assets/images/main/page2_4.png'
import {Avatar} from "@mui/material";
import classNames from "classnames";
import Button from "../../../../common/ui_kit/button";

const data = [
    {
        title: 'Регистрация',
        icon: icon1,
        sub_title: 'Заполните простую форму, подтвердите почту и вперед',
        bg: '#F7F7FD'
    },
    {
        title: 'Участвуйте в аукционе',
        icon: icon2,
        sub_title: 'Выберите нужный товар из каталога и сделайте ставку',
        bg: '#F5FCF6'
    },
    {title: 'Выкуп товара', icon: icon3, sub_title: 'Выкупите товар, если ваша ставка была последней', bg: '#FFF6F5'},
    {title: 'Доставка', icon: icon4, sub_title: 'Отправляем выкупленный лот в течении 5-ти дней', bg: '#FFFBED'},
]
const Page2 = ({handleReg, isAuth}) => {
    return (
        <Container>
            <div className={s.content}>
                <h3 className={s.title}>
                    Как начать покупать <br/> с выгодой <span>до 50%?</span>
                </h3>

                <div className={s.info_box}>
                    {data?.map((el, i) => {
                        return <div style={{background: el.bg}}
                                    className={classNames(s.info_item, (i === 1 || i === 2) && s.info_item_border)}
                                    key={i}>
                            <Avatar src={el.icon} sx={{borderRadius: '0px', height: '60px', width: '68px'}}/>
                            <p className={s.info_item_title}>{el.title}</p>
                            <p className={s.info_item_sub_title}>{el.sub_title}</p>
                        </div>
                    })}
                </div>

                <div className={s.btn}>
                    <Button onClick={handleReg}
                            className={classNames(!isAuth && 'yellow_btn_reg')}>{isAuth ? 'Перейти к аукционам' : 'Регистрация'}</Button>
                </div>
            </div>
        </Container>
    );
};

export default Page2;