import {baseQueryWithReauth} from "../api/baseQuery";
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import Cookies from "js-cookie";
import moment from "moment-timezone";

export const activeAuctionsApi = createApi({
    reducersPath: 'activeAuctionsApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['wow'],
    endpoints: (build) => ({
        getActiveAuctions: build.query({
            query: () => ({
                url: 'auth/user/auctions/?state=run&page=1&page_size=100',
                method: 'GET',
                headers: {
                    'X-CSRFToken': Cookies.get('token')
                }
            }),
            transformResponse(baseQueryReturnValue, meta, arg) {
                return {
                    ...baseQueryReturnValue, results: baseQueryReturnValue?.results?.map((el) => {
                        const time_for_bid = el?.time_for_bid?.split(':')

                        return {
                            ...el,
                            highest_bid: el?.highest_bid_amount,
                            user_highest_bid: el?.user_highest_bid_amount,
                            default_timer: el.state === 'run' ?
                                (el?.highest_bid_date ?
                                    moment.tz(el?.highest_bid_date, 'Europe/Moscow').add(time_for_bid[0], 'hours').add(time_for_bid[1], 'minutes').add(time_for_bid[2], 'second').format()

                                    : moment.tz(el?.start_date, 'Europe/Moscow').add(time_for_bid[0], 'hours').add(time_for_bid[1], 'minutes').add(time_for_bid[2], 'second').format())
                                : moment().format()
                        }
                    })
                }
            },
            providesTags: ['wow']
        })
    })
})
export const {
    useGetActiveAuctionsQuery,
} = activeAuctionsApi;
