import React from 'react';
import s from "./styles.module.css";
import {NavLink} from "react-router-dom";
import {PATH} from "../../constants/path";

const TitleAuction = ({title, withButton, buttonText = 'Все лоты', svg, link = PATH.MAIN, onlyTitle = false}) => {
    return (
        <div className={s.header}>
            <div className={s.title}>
                <p>{title}</p>
                {!onlyTitle && svg}
            </div>

            {(!onlyTitle || withButton) && <NavLink to={link}>{buttonText}</NavLink>}
        </div>
    );
};

export default TitleAuction;
