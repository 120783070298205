import React, {useState} from 'react';
import HeaderModal from "../../../../components/header_modal";
import s from './styles.module.css'
import {useFormik} from "formik";
import {validate} from "./validate";
import InputMaskNumber from "../../../../common/ui_kit/inputs/input_mask";
import Button from "../../../../common/ui_kit/button";
import TitleMobileAuth from "../../../../common/title_auth_mobile";
import {
    useResentPasswordMobileMutation,
    useSetPasswordMobileMutation
} from "../../../../redux/auth.service";
import Input from "../../../../common/ui_kit/inputs/input";
import {toast} from "react-toastify";
import {cleanPhoneNumber} from "../../../../helper/clearNumber";
import SendAgain from "./send_again";

const LoginCode = ({
                       login,
                       closeLoginReset,
                       onClose,
                       setTimerResetSms,
                       timerResentSms,
                       setStep,
                       error,
                       setError,
                       step,
                       valueToSend
                   }) => {
    const [setPasswordMobile, {isLoading}] = useSetPasswordMobileMutation()
    const [resentPasswordMobile, {isLoading: isLoadingResetCode}] = useResentPasswordMobileMutation()

    const [modalNewPassword, setModalNewPassword] = useState(false)
    // const [loading, setLoading] = useState(false)

    const formikNewPassword = useFormik({
        initialValues: {
            password: '',
            re_password: '',
        },
        validate: (values) => {
            const errors = {};
            if (!values.password) {
                errors.password = 'Обязательное поле';
            }

            if (values.re_password !== values.password) {
                errors.re_password = 'Пароли не совпадают'
            }


            return errors;
        },
        onSubmit: (values) => {
            // handleConfirm(values.password, () => onClose())
            const formData = new FormData();

            formData.append('phone_number', `+${cleanPhoneNumber(valueToSend)}`);
            formData.append('otp', formik.values.code?.replace('-', ''));
            formData.append('new_password', values.password);

            // setPasswordMobile(formData)
            setPasswordMobile(formData)
                .unwrap()
                .then((res) => {
                    // debugger
                    onClose()
                    closeLoginReset()
                    toast.success(res?.detail || 'Пароль успешно изменен')
                })
                .catch((e) => {
                    function errorHandler(errorObject) {

                        for (let key in errorObject) {
                            const value = errorObject[key];
                            if (Array.isArray(value)) {
                                value.forEach(error => {
                                    toast.error(`${error}`);
                                });
                            } else {
                                toast.error(`${value}`);
                            }
                        }
                    }

                    if (e.data.detail) {
                        toast.error(e.data.detail)
                    } else {
                        errorHandler(e.data)
                    }
                    console.log(e)
                })
        }
    })

    const formik = useFormik({
        initialValues: {
            code: ''
        },
        validate: validate,
        onSubmit: (values) => {
            const formData = new FormData();

            formData.append('otp', values.code?.replace('-', ''));
            formData.append('phone_number', `+${cleanPhoneNumber(valueToSend)}`);

            setPasswordMobile(formData)
                .unwrap()
                .then((res) => {

                })
                // +7 (951) 707-59-77
                .catch((e) => {
                    console.log(e)
                    if (e.data?.code === 305) {
                        onClose()
                        setError({...e.data, mobile: valueToSend})
                        toast.error(e.data.detail)
                    } else if (e.data?.code === 303) {
                        setError({...e.data, mobile: valueToSend})
                        toast.error(e.data.detail)
                    } else if (e.data?.code === 307) {
                        toast.success('Введите новый пароль')
                        setModalNewPassword(true)
                        setError({...e.data, mobile: valueToSend})
                    } else {
                        function errorHandler(errorObject) {

                            for (let key in errorObject) {
                                const value = errorObject[key];
                                if (Array.isArray(value)) {
                                    value.forEach(error => {
                                        toast.error(`${key}: ${error}`);
                                    });
                                } else {
                                    toast.error(`${key}: ${value}`);
                                }
                            }
                        }

                        if (e?.data?.detail) {
                            toast.error(e.data.detail)
                        } else {
                            errorHandler(e.data)
                        }
                    }


                })
        }
    })

    const handlerResendSms = () => {
        const formData = new FormData();
        formData.append('phone_number', `+${cleanPhoneNumber(valueToSend)}`);
        resentPasswordMobile(formData)
            .unwrap()
            .then((res) => {
                toast.success(res?.detail || 'Сообщение отправлено')
                setTimerResetSms({
                    date: res.res_at || '',
                    number: valueToSend
                })
                setError('')

            })
            .catch((e) => {
                console.log(e)
                toast.error(e?.data?.detail || 'Ошибка')
                setTimerResetSms({
                    date: e?.data?.service_info || '',
                    number: valueToSend
                })
            })
    }

    if (!modalNewPassword) {
        return <div>
            <HeaderModal isMobile={true} onClose={() => {
                onClose()
                // regFormik.setFieldValue('otp', null)
            }}
                         title={'Подтверждение'}
                         arrow={false} typeModal={1}/>

            <TitleMobileAuth top={24} title={'Подтверждение'}/>

            <div className={s.enter_password_title}>
                <p>Мы отправили код на номер <br/>
                    {valueToSend || '+7 (000) 000-00-00'}</p>
            </div>

            <form className={s.form} onSubmit={(e) => e.preventDefault()}>
                <div className={s.code_box}>
                    <InputMaskNumber maskChar={null} mask="999-999" placeholder={'000-000'}
                                     value={formik.values.code}
                                     onChange={formik.handleChange}
                                     name={'code'}
                                     onBlur={formik.handleBlur}
                                     errorMessage={formik.touched.code && formik.errors.code}
                    />
                    {(error && (error?.code === 303 || error?.code === 304)) &&
                        <span className={s.try_count}>Попыток {error.tries}</span>}

                </div>

                <div className={s.btns}>
                    <Button disabled={isLoading} type={'submit'}
                            onClick={formik.handleSubmit} className={s.btn_enter}>
                        Подтвердить
                    </Button>
                    <SendAgain isLoading={isLoadingResetCode} timerResetSms={timerResentSms}
                               handlerResendSms={handlerResendSms}/>
                    {/*{isFinish &&*/}
                    {/*    <Button className={s.btn_reg} type_btn={1} onClick={restart}>Отправить повторно</Button>}*/}

                    {/*{!isFinish && <p className={s.timer}>{`Отправить повторно через: ${ss} сек`}</p>}*/}
                </div>
            </form>
        </div>
    } else {
        return <div>
            <HeaderModal isMobile={true} onClose={() => {
                onClose()

            }
            }
                         title={'Придумайте новый пароль'}
                         arrow={false} typeModal={0}/>

            <TitleMobileAuth top={24} title={'Придумайте новый пароль'}/>

            <form className={s.form} onSubmit={(e) => e.preventDefault()}>
                <div className={s.enter_password_input}>
                    <Input value={formikNewPassword.values.password}
                           type={'password'}
                           onChange={formikNewPassword.handleChange}
                           onBlur={formikNewPassword.handleBlur}
                           name={'password'}
                           errorMessage={formikNewPassword.touched.password && formikNewPassword.errors.password}
                           placeholder={'Новый пароль'}/>

                    <Input value={formikNewPassword.values.re_password}
                           type={'password'}
                           onChange={formikNewPassword.handleChange}
                           onBlur={formikNewPassword.handleBlur}
                           name={'re_password'}
                           errorMessage={formikNewPassword.touched.password && formikNewPassword.errors.re_password}
                           placeholder={'Повторите пароль'}/>
                </div>

                <Button disabled={isLoading} type={'submit'} onClick={formikNewPassword.handleSubmit} className={s.btn}>Изменить
                    пароль</Button>
            </form>
        </div>
    }
};

export default LoginCode;
