import React, {useEffect} from 'react';
import {toast} from "react-toastify";
import s from './styles.module.css'
import {Avatar} from "@mui/material";
import {ReactComponent as CloseIcon} from '../../assets/svg/alert_close_icon.svg'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useSelector} from "react-redux";
import {selectCategory} from "../../redux/slice/category_subcategory.slice";
import {NavLink} from "react-router-dom";
import {PATH} from "../../constants/path";
import {BASE_URL_IMAGE} from "../../constants/url";

const AlertNotification = ({status}) => {
    const {category: category_data} = useSelector(selectCategory)

    useEffect(() => {
        console.log(status)

        if (status?.t === 300 && status?.ia?.state === 'run') {
            const category_slag = category_data?.results?.find((f) => f.id === status?.ia?.product?.category)
            const sub_category_slag = category_slag?.subcategory_set?.find((f) => f.id === status?.ia?.product?.subcategory)

            toast.info(<div className={s.alert_box}>
                <div className={s.alert_avatar}>
                    <Avatar alt={'logo'} sx={{height: '100%', width: '100%', borderRadius: '0px'}}
                            src={`${BASE_URL_IMAGE}${status?.ia?.product?.hero_image}`}/>
                </div>
                <div className={s.alert_description}>
                    <p className={s.alert_description_top}>{`Начался аукцион № ${status?.ia?.id}`}</p>
                    <NavLink
                        to={`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${status?.ia?.id}`}
                        className={s.alert_description_bottom}>{status?.ia?.product?.title}</NavLink>
                </div>
            </div>, {
                position: 'top-center',
                autoClose: 5000,
                className: s.alert,
                bodyClassName: s.alert_body,
                icon: <InfoOutlinedIcon sx={{color: '#222C3E'}}/>,
                hideProgressBar: true,
                closeButton: (e) => {
                    return <div className={s.close_icon}><CloseIcon/></div>
                },
            })

        }
    }, [status])

    return (
        <div style={{height: 0, maxHeight: 0, position: 'absolute', top: '-10px'}}/>
    );
};

export default AlertNotification;