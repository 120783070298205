import React from 'react';
import InputMask from "react-input-mask";
import s from './styles.module.css'

const InputMaskNumber = ({value,onBlur, mask, placeholder,title = null, onChange, name, errorMessage}) => {
    return (
        <div className={s.input_mask}>
            {title && <p className={s.title}>{title}</p>}
            <InputMask maskChar={null} onBlur={onBlur} type={'tel'} name={name} mask={mask} placeholder={placeholder}
                       value={value}
                       onChange={onChange}
            />
            <span className={s.errorMessage}>{errorMessage}</span>
        </div>
    );
};

export default InputMaskNumber;
