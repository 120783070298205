import {Route, Routes} from "react-router-dom";
import {PATH} from "../constants/path";
import React, {lazy, Suspense} from "react";

import ProtectedRoute from '../pages/protected_route';
// import Verification from '../pages/verification';
// import CalendarEvents from '../pages/calendar_events';
// import Favorites from '../pages/favorites';
// import MyLots from '../pages/my_lots';
// import Wallet from '../pages/wallet';
// import Profile from '../pages/profile';
// import Account from '../pages/account';
import PreloaderPageLoad from "../components/preloader_page_load";
import MainPromo from "../pages/0-main_promo";
import Subscribe from "../pages/subscribe";
import MarketInfo from "../pages/market_item_info/market_info";
// import Referal from "../pages/referal";

const Auctions = lazy(() => import('../pages/auctions'));
const Blogs = lazy(() => import('../pages/blogs'));
const BlogItem = lazy(() => import('../pages/blog_item'));
const SupportItem = lazy(() => import('../pages/support_item'));
const OrderItem = lazy(() => import('../pages/order_item'));
const Support = lazy(() => import('../pages/support'));
const ActiveAuctions = lazy(() => import('../pages/active_auctions'));
const Contacts = lazy(() => import('../pages/contact'));
const DocumentsRoots = lazy(() => import('../pages/documents_roots'));
const Requisites = lazy(() => import('../pages/requisites'));
const AuctionUpInfo = lazy(() => import('../pages/auction_item_info/auction_up_info'));
const VerificationAddress = lazy(() => import('../pages/verification/verification_address'));
const VerificationMySelf = lazy(() => import('../pages/verification/verification_myself'));
const Referal = lazy(() => import('../pages/referal'));

const Verification = lazy(() => import('../pages/verification'));
const CalendarEvents = lazy(() => import('../pages/calendar_events'));
const Favorites = lazy(() => import('../pages/favorites'));
const MyLots = lazy(() => import('../pages/my_lots'));
const Wallet = lazy(() => import('../pages/wallet'));
const Profile = lazy(() => import('../pages/profile'));
const Account = lazy(() => import('../pages/account'));


const NotFound = lazy(() => import('../pages/404'));
const Main = lazy(() => import('../pages/0-main'));

export const Router = ({isAuth}) => (
    <Routes>
        <Route path={PATH.MAIN}
               element={<Suspense fallback={<PreloaderPageLoad/>}><MainPromo/></Suspense>}/>
        <Route path={PATH.CONTACT} element={<Suspense fallback={<PreloaderPageLoad/>}><Contacts/></Suspense>}/>
        <Route path={PATH.DOCUMENTROOT}
               element={<Suspense fallback={<PreloaderPageLoad/>}><DocumentsRoots/></Suspense>}/>
        <Route path={PATH.REGUISITES} element={<Suspense fallback={<PreloaderPageLoad/>}><Requisites/></Suspense>}/>
        <Route path={PATH.BLOGS} element={<Suspense fallback={<PreloaderPageLoad/>}><Blogs/></Suspense>}/>
        <Route path={PATH.BLOGS_ITEM} element={<Suspense fallback={<PreloaderPageLoad/>}><BlogItem/></Suspense>}/>
        <Route path={PATH.SUPPORT_ITEM} element={<Suspense fallback={<PreloaderPageLoad/>}><SupportItem/></Suspense>}/>
        <Route path={PATH.SUPPORT} element={<Suspense fallback={<PreloaderPageLoad/>}><Support/></Suspense>}/>

        <Route path={PATH.AUCTIONS_TYPE} element={<Suspense fallback={<PreloaderPageLoad/>}><Auctions/></Suspense>}>
            <Route path={PATH.AUCTIONS_TYPE}/>
            <Route path={PATH.AUCTIONS_TYPE_CATEGORY}/>
            <Route path={PATH.AUCTIONS_TYPE_CATEGORY_ITEM}/>
        </Route>

        {/*<Route path={PATH.MARKET} element={<Suspense fallback={<PreloaderPageLoad/>}><Auctions/></Suspense>}>*/}
        {/*    <Route path={PATH.MARKET_CATEGORY}/>*/}
        {/*    <Route path={PATH.MARKET_CATEGORY_ITEM}/>*/}
        {/*</Route>*/}

        <Route path={PATH.AUCTION_TYPE_CATEGORY_ITEM_LOT}
               element={<Suspense fallback={<PreloaderPageLoad/>}><AuctionUpInfo/></Suspense>}/>

        <Route path={PATH.MARKET_TYPE_CATEGORY_ITEM_LOT}
               element={<Suspense fallback={<PreloaderPageLoad/>}><MarketInfo/></Suspense>}/>

        <Route path={PATH.SUBSCRIBE}
               element={<Subscribe/>}/>

        <Route path={PATH.ACCOUNT}
               element={<ProtectedRoute isAuth={isAuth}/>}>

            <Route index element={<Suspense fallback={<PreloaderPageLoad/>}><Account/></Suspense>}/>
            <Route path={PATH.PROFILE} element={<Suspense fallback={<PreloaderPageLoad/>}><Profile/></Suspense>}/>
            <Route path={PATH.WALLET} element={<Suspense fallback={<PreloaderPageLoad/>}><Wallet/></Suspense>}/>
            <Route path={PATH.ACTIVE_AUCTIONS}
                   element={<Suspense fallback={<PreloaderPageLoad/>}><ActiveAuctions/></Suspense>}/>
            <Route path={PATH.MY_LOTS} element={<Suspense fallback={<PreloaderPageLoad/>}><MyLots/></Suspense>}/>
            <Route path={PATH.FAVORITES} element={<Suspense fallback={<PreloaderPageLoad/>}><Favorites/></Suspense>}/>
            <Route path={PATH.REFERAL} element={<Suspense fallback={<PreloaderPageLoad/>}><Referal/></Suspense>}/>
            <Route path={PATH.CALENDAR_EVENTS}
                   element={<Suspense fallback={<PreloaderPageLoad/>}><CalendarEvents/></Suspense>}/>
            <Route path={PATH.VERIFICATION}
                   element={<Suspense fallback={<PreloaderPageLoad/>}><Verification/></Suspense>}/>
            {/*<Route path={PATH.VERIFICATION + '/' + PATH.VERIFICATION_MYSELF}*/}
            {/*       element={<Suspense fallback={<div></div>}><VerificationMySelf/></Suspense>}/>*/}
            {/*<Route path={PATH.VERIFICATION + '/' + PATH.VERIFICATION_ADDRESS}*/}
            {/*       element={<Suspense fallback={<div></div>}><VerificationAddress/></Suspense>}/>*/}

        </Route>

        <Route path={PATH.ORDER_ITEM} element={<Suspense fallback={<div></div>}><OrderItem/></Suspense>}/>
        <Route key="/404" path="*" element={<Suspense fallback={<div></div>}><NotFound/></Suspense>}/>

    </Routes>
)
