import React, {useEffect, useState} from 'react';
import {Navigate, NavLink, Outlet, useLocation, useNavigate} from "react-router-dom";
import Container from "../../components/container";
import Header from "./header";
import s from './styles.module.css'
import classNames from "classnames";
import {PATH} from "../../constants/path";
import {useGetUserRefsQuery} from "../../redux/user.service";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/slice/user.slice";

const path = [
    {
        title: 'Профиль', sub_title: 'Личный кабинет', path: PATH.ACCOUNT,
    },
    {
        title: 'Настройки', sub_title: 'Настройки', path: PATH.ACCOUNT + '/' + PATH.PROFILE,
    },
    {
        title: 'Активы и транзакции', sub_title: 'Активы и транзакции', path: PATH.ACCOUNT + '/' + PATH.WALLET,
    },
    {
        title: 'Мои покупки', sub_title: 'Мои покупки', path: PATH.ACCOUNT + '/' + PATH.MY_LOTS,
    },
    {
        title: 'Избранное', sub_title: 'Избранное', path: PATH.ACCOUNT + '/' + PATH.FAVORITES,
    },
    {
        title: 'Календарь событий', sub_title: 'Календарь событий', path: PATH.ACCOUNT + '/' + PATH.CALENDAR_EVENTS,
    },
    {
        title: 'Реферальная программа',
        sub_title: 'Реферальная программа',
        path: PATH.ACCOUNT + '/' + PATH.REFERAL,
    },
    // {
    //     title: 'Верификация',
    //     sub_title: 'Верификация',
    //     path: PATH.ACCOUNT + '/' + PATH.VERIFICATION,
    // },
]

const ProtectedRoute = ({isAuth}) => {
    const navigate = useNavigate()
    const location = useLocation();

    const {data} = useGetUserRefsQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    console.log(data)

    const [breadcrumbs, setBreadCrumbs] = useState([]);

    useEffect(() => {
        const otherPath = path.filter((f) => location.pathname.split('/').includes(f.path.split('/')[2])).map((el) => ({
            title: el.title,
            action: () => navigate(el.path),
            pathName: el.path
        }))
        const index = otherPath.findIndex((i) => i.pathName === location.pathname)
        const filteredPath = otherPath.length > 1 ? (location.pathname === otherPath[index].pathName ? [otherPath[0], otherPath[index]] : [otherPath[0]]) : otherPath

        setBreadCrumbs([
            {
                title: 'Главная',
                action: () => navigate(PATH.MAIN)
            },
            {
                title: 'Личный кабинет',
                action: () => navigate(PATH.ACCOUNT),
                isChangedType: true
            },

            ...filteredPath
        ])
    }, [location.pathname])

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [location.pathname])

    return isAuth
        ? <Container>
            {path?.find(f => f?.path === location?.pathname) ?
                <Header breadcrumbs={breadcrumbs} currentPage={path?.find(f => f.path === location.pathname)}/> : null}
            <div className={s.content}>
                <div className={s.navigate}>
                    {path.map((el, i) => <NavLink
                        style={{
                            display: el.isVisible === false && 'none'
                        }}
                        className={classNames(s.link, ((el.path === location.pathname) || (el?.sub_path?.includes(location.pathname))) && s.active)}
                        key={i}
                        to={el.path}>{el.title}</NavLink>)}
                </div>
                <div className={s.pages}>
                    <Outlet/>
                </div>
            </div>
        </Container>
        : <Navigate to="/" replace state={{from: location}}/>;
};

export default ProtectedRoute;
