export function cleanPhoneNumber(phoneNumber) {
    // Удаляем все нецифровые символы из номера телефона
    const cleanedNumber = phoneNumber.replace(/\D/g, '');

    // Возвращаем очищенный номер телефона
    return cleanedNumber;
}

export function formatPhoneNumber(phoneNumber) {
    // Удаляем все символы, кроме цифр
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Форматируем номер телефона
    // const formatted = `+${cleaned.slice(0, 1)} (${cleaned.slice(1, 2)}••) •••-••-${cleaned.slice(9)}`;
    const formatted = `+${cleaned.slice(0, 1)} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9)}`;

    return formatted;
    // return phoneNumber
}

export function hideEmail(email) {
    // const [username, domain] = email.split('@');
    // const hiddenUsername = username.charAt(0) + '•'.repeat(username.length - 1);
    // return `${hiddenUsername}@${domain}`;
    return email
}
