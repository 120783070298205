import {createSlice} from "@reduxjs/toolkit";

export const globalSlice = createSlice({
    name: 'user',
    initialState: {
        isAuth: false,
        block: false,
    },
    reducers: {
        setIsAuth: (state, action) => {
            state.isAuth = action.payload
        },
        setBlock: (state, action) => {
            state.block = action.payload
        },
    }
})

export const {setIsAuth} = globalSlice.actions

export const {setBlock} = globalSlice.actions
export const selectIsAuth = (state) => state.global;

export default globalSlice.reducer
