import React from 'react';
import s from './styles.module.css'
import {Avatar, IconButton, Tooltip} from "@mui/material";
import {NavLink, useNavigate} from "react-router-dom";
import noImage from "../../../../../assets/images/no_image.jpg";
import classNames from "classnames";
import {ReactComponent as MolotIcon} from "../../../../../assets/svg/molot.svg";
import {spaceBtwHundreds} from "../../../../../helper/spaceBetweenSum";
import Button from "../../../../../common/ui_kit/button";
import {PATH} from "../../../../../constants/path";
import {motion} from "framer-motion";
import StatusTitle from "../../../../../components/auction_item/auctiom_item_up/status_title";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {ReactComponent as AuctionUpIcon} from "../../../../../assets/svg/auction_up.svg";
import {ReactComponent as Arrow} from "../../../../../assets/svg/arrow_next_price.svg";
import {useSelector} from "react-redux";
import {selectCategory} from "../../../../../redux/slice/category_subcategory.slice";

const ItemMarketLine = ({
                            new_price,
                            attributes,
                            old_price,
                            title,
                            img,
                            haveInAuc,
                            visibleType,
                            subcategory,
                            category,
                            id
                        }) => {

    const calcPercent = (oldPrice, newPrice) => {
        const discount = ((oldPrice - newPrice) / oldPrice) * 100;
        return `-${Math.round(discount)}%`;
    }

    const navigate = useNavigate()
    const {category: category_data} = useSelector(selectCategory)

    const category_slag = category_data?.results?.find((f) => f.id === category)
    const sub_category_slag = category_slag?.subcategory_set?.find((f) => f.id === subcategory)

    const handleGoLinkAuc = () => {
        if (haveInAuc) {
            navigate(`${PATH.AUCTION}/up/${category_slag?.slug}/${sub_category_slag?.slug}/${haveInAuc[0] || 0}`)
        }
    }

    const handleGoLinkProduct = () => {
        navigate(`${PATH.MARKET}/${category_slag?.slug}/${sub_category_slag?.slug}/${id}`)
    }

    const attributesConvert = (attributes && Object.keys(attributes)?.length > 0) ? (Object.keys(attributes)?.length > 4 ? Object.keys(attributes)?.slice(0, 4)?.map((key) => ({
        title: key,
        value: attributes[key]
    })) : Object.keys(attributes)?.map((key) => ({title: key, value: attributes[key]}))) : []


    return (
        <div className={s.item}>
            <div className={s.line_box_left}>

                <NavLink>
                    <div className={classNames(s.media_box, s.media_box_line)}>
                        <Avatar
                            sx={{
                                height: '100%',
                                width: '100%',
                                borderRadius: '8px',
                                overflow: 'hidden',
                            }}
                            src={img || noImage}
                        />
                    </div>
                </NavLink>
                <div className={s.line_content}>
                    <div>
                        <div className={classNames(s.title, s.title_line)}>
                            <NavLink>
                                <Tooltip title={title}>
                                    <span>{title}</span>
                                </Tooltip>
                            </NavLink>
                        </div>

                        <div
                            className={classNames(s.content_completed, visibleType === 'line' && s.content_completed_line)}>
                            {attributes ? attributesConvert.map((el) => {
                                return <div
                                    key={el?.title}
                                    className={classNames(s.content_item, visibleType === 'line' && s.content_item_line)}>
                                    <p className={s.content_item_title}>
                                        {el?.title}
                                    </p>
                                    <div className={s.line_dash}/>
                                    <p
                                        className={s.content_item_duration}>
                                        {el?.value}
                                    </p>
                                </div>
                            }) : null}


                        </div>

                    </div>
                </div>
            </div>
            <div className={s.action}>
                <div className={s.top_content_action}>
                    <div className={s.action_content}>

                        <p className={classNames(s.content_item_title, s.content_item_title_line)}>
                            Цена товара
                        </p>

                        <p className={classNames(s.price_box_title)}>{spaceBtwHundreds(new_price)} ₽</p>

                    </div>

                    <div className={s.price_box_right} onClick={handleGoLinkAuc} style={{
                        cursor: haveInAuc && 'pointer'
                    }}>
                        {haveInAuc && <div className={s.molot_icon}>
                            <MolotIcon/>
                        </div>}
                        <p className={classNames(s.molot_title, !haveInAuc && s.molot_title_no_auc)}>{haveInAuc ? 'Есть на аукционе' : 'Нет на аукционе'}</p>
                    </div>
                </div>

                <div className={s.action_button_box}>
                    {/*<NavLink>*/}
                    <Button title={'Перейти к товару'} onClick={handleGoLinkProduct} className={s.action_button}>
                        Перейти к товару
                    </Button>

                    {/*</NavLink>*/}
                </div>
            </div>
        </div>
    );
};

export default ItemMarketLine;