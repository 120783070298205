import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import cheerio from 'cheerio';
import {BASE_URL_IMAGE} from "../../../../constants/url";

const TextExpander = ({text, line = 1}) => {
    const [data, setData] = useState('');

    useEffect(() => {
        function parseStyles(styleString) {
            // Разбиваем строку стилей по точке с запятой (;)
            const stylePairsSplit = styleString.split(';');
            const stylePairs = stylePairsSplit.filter((f) => f);

            // Инициализируем пустой объект для хранения пар ключ-значение
            const stylesObject = {};

            // Проходимся по каждой паре ключ-значение
            stylePairs.forEach(pair => {
                // Разбиваем каждую пару по двоеточию (:)
                const keyValue = pair.split(':');

                // Убираем лишние пробелы в ключе и значении
                const key = keyValue[0].trim();
                const value = keyValue[1].trim();
                // Добавляем пару ключ-значение в объект стилей
                if (key && value) {
                    stylesObject[key] = value;
                }
            });

            return stylesObject;
        }

        // Ваши данные с бекенда
        const yourData = text || ''

        // Парсим HTML-данные
        const $ = cheerio.load(yourData);

        // Получаем все теги img
        $('img').each((index, element) => {
            // Получаем атрибут src текущего тега img
            let src = $(element).attr('src');
            let style = $(element).attr('style');

            const dimensions = {};
            // Добавляем базовый URL перед src
            if (!src.includes('http')) {
                src = `${BASE_URL_IMAGE}${src}`;
            }

            if (style) {

                const styles = parseStyles(style)

                if (styles.width) {
                    $(element).attr('style', `max-width:${styles.width};width:100%;height:auto`);
                } else {
                    $(element).attr('style', `max-width:100%;width:100%;height:auto`);
                }
            }

            // Устанавливаем новое значение src
            $(element).attr('src', src);
        });

        // Обновляем состояние с обновленными данными
        setData($.html());
    }, []);
    return (
        <div>
            <div dangerouslySetInnerHTML={{__html: data}} className={s.text}>

            </div>

        </div>
    );
};

export default TextExpander;
