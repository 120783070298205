import {createSlice} from "@reduxjs/toolkit";
import {activeAuctionsApi} from "../active_auctions.service";

export const activeLotsSlice = createSlice({
    name: 'activeLots',
    initialState: {
        activeLots: null
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            activeAuctionsApi.endpoints.getActiveAuctions.matchFulfilled,
            (state, {payload}) => {
                state.activeLots = payload
            }
        )
    },
    reducers: {
        setActiveLots: (state, action) => {
            state.activeLots = {...state.activeLots, ...action.payload}
        },
    }
})

export const {setActiveLots} = activeLotsSlice.actions

export const selectActiveLots = (state) => state.activeLots;

export default activeLotsSlice.reducer
