import React, {useEffect} from 'react';
import Button from "../../../../common/ui_kit/button";
import s from './styles.module.css'
import {useTimer} from "react-timer-hook";

const ButtonsAction = ({formik, loading, number, error}) => {

    const {
        totalSeconds,
        seconds: ss,
        minutes: mm,
        hours: hh,
        days: dd,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp: new Date(error?.exp_at),
        autoStart: Boolean(error?.exp_at),
    });

    const sameNumber = error?.mobile === number

    useEffect(() => {
        if (error && error.code === 305) {
            restart(new Date(error?.exp_at), true)
        } else {
            pause()
        }
    }, [error?.exp_at])

    // useEffect(() => {
    //     if ((!isRunning) && setError) {
    //         setError('')
    //     }
    // }, [isRunning])

    return (
        <>
            {((!isRunning || !sameNumber)) &&
                <Button disabled={loading} onClick={formik.handleSubmit} className={s.btn}>
                    Сбросить пароль
                </Button>}
            {((isRunning && sameNumber) && error && error?.code === 305) &&
                <Button disabled={true} loading={false} className={s.btn}>
                    Номер заблокирован на {totalSeconds} сек.
                </Button>}
        </>
    );
};

export default ButtonsAction;
