export const validate = (values) => {
    const errors = {};

    function validateResumeUrl(url) {
        // Регулярное выражение для проверки URL с указанными условиями
        const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,})(\/[\w.-]*)*(\?.*)?$/;

        // Проверяем URL с помощью регулярного выражения
        return urlRegex.test(url);
    }

    if (!values.cand_resume_url) {
        errors.cand_resume_url = 'Обязательное поле';
    } else if (!validateResumeUrl(values?.cand_resume_url)) {
        errors.cand_resume_url = 'Неверная ссылка';
    }

    if (!values.vacancy) {
        errors.vacancy = 'Обязательное поле';
    }

    if (!values.cand_fullname) {
        errors.cand_fullname = 'Обязательное поле';
    } else if (values.cand_fullname?.length < 3) {
        errors.cand_fullname = 'Минимальная длина 3 символа';
    } else if (values.cand_fullname?.length > 48) {
        errors.cand_fullname = 'Максимальная длина 48 символа';
    }

    if (!values.cand_phone_number) {
        errors.cand_phone_number = 'Обязательное поле';
    } else if (values.cand_phone_number.length !== 18) {
        errors.cand_phone_number = 'Неверный номер телефона';
    }

    if (!values.cand_email) {
        errors.cand_email = 'Обязательное поле';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.cand_email)) {
        errors.cand_email = 'Неверный email адресс';
    }
    return errors;
}
