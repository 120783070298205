import './App.css';
import {Router} from "./routers/router";
import Header from "./components/header";
import Navigate from "./components/navigate";
import Footer from "./components/footer";
import React, {createContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import moment from "moment-timezone";
import MobileNavigateBottom from "./components/mobile_navigate_bottom";
import {useGetCategorySubCategoryQuery} from "./redux/category.service";
import {useDispatch, useSelector} from "react-redux";
import {selectSocketGlobalSlice, setSocketGlobal} from "./redux/slice/socketroom.slice";
import {useGetCSRFQuery, useIsAuthQueryMutation, useResentPasswordEmailWithTokenMutation} from "./redux/auth.service";
import {BASE_URL_SOCKET} from "./constants/url";
import {useGetUserInfoMutation, useVerifyEmailMutation} from "./redux/user.service";
import {selectActiveLots, setActiveLots} from "./redux/slice/active_lots.slice";
import {favoriteApi} from "./redux/favority.service";
import {selectFavorites, setFavorites} from "./redux/slice/favorite.slice";
import {Link, useMediaQuery} from "@mui/material";
import {ErrorBoundary} from "react-error-boundary";
import {toast} from "react-toastify";
import {
    selectIsAuth,
    setIsAuth
} from "./redux/slice/global.slice";
import LoginNewPassword from "./components/reset_password";
import {
    selectUser, setUser
} from "./redux/slice/user.slice";
import classNames from "classnames";
import ModalWelcome from "./components/modal_welcome";
import ModalBlock from "./components/modal_block";
import axios from "axios";
import AlertNotification from "./components/alert_notification";

const tele = window?.Telegram?.WebApp

export const AuthContext = createContext(null)
export const TimeZoneContext = createContext(null)

function ErrorHandler({error}) {
    const navigate = useNavigate()

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(error.message);
            toast.success('Текст скопирован в буфер обмена!')
        } catch (error) {
            console.error('Ошибка при копировании текста:', error);
            toast.error('Ошибка при копировании текста.')
        }
    };
    return (
        <div role="alert" className={'error_container'}>
            <div className={'error_box'}>
                <h3>Скопируйте ошибку и отправьте в техподдержку</h3>
                <br/>
                <pre>Ошибка: {error.message}</pre>
                <br/>
                <button onClick={copyToClipboard}>Скопировать</button>
                <Link to={'/'} onClick={() => {
                    navigate('/')
                    setTimeout(() => window.location.reload(), 0)
                }}>Вернуться на главную страницу</Link>
            </div>
        </div>
    )
}


function App() {
    const [isStandaloneMode, setIsStandaloneMode] = useState(false)
    const [shouldFetchCSRF, setShouldFetchCSRF] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const query_1024 = useMediaQuery('(max-width:1024px)');
    const {isAuth, block} = useSelector(selectIsAuth)
    const {isFetching: isFetchingCSRF, isError} = useGetCSRFQuery('', {
        skip: !!searchParams?.get('confirm') || !shouldFetchCSRF
    })
    const {user} = useSelector(selectUser)

    const location = useLocation()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)
    const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)

    const {favorites} = useSelector(selectFavorites)
    const {activeLots} = useSelector(selectActiveLots)

    const {socketGlobal: socketGlobalData} = useSelector(selectSocketGlobalSlice)
    const [resentPasswordEmailWithToken, {isLoading: isLoadingReset}] = useResentPasswordEmailWithTokenMutation()
    const [getUserInfo] = useGetUserInfoMutation()
    const [isAuthQuery] = useIsAuthQueryMutation()
    const [verifyEmail] = useVerifyEmailMutation()

    const {isLoading: isLoadingCategory} = useGetCategorySubCategoryQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    });

    const [timezone, setTimezone] = useState(moment.tz.guess())

    const [openAuthModal, setOpenAuthModal] = useState(false)
    const [isFromAuction, setIsFromAuction] = useState(null)

    let socketv2;

    function initSocket() {
        const wsOrwss = (window.location.hostname === 'itembuy.ru' || window.location.hostname === 'ib-wr0ng-ch2i-dev.ru') ? 'wss' : 'ws'
        const wsUrl = `${wsOrwss}://${BASE_URL_SOCKET}/ws/notifier/`;

        socketv2 = new WebSocket(wsUrl);

        socketv2.onopen = () => {
            console.log("WebSocket connection established global");
        };

        socketv2.onclose = () => {
            console.log("WebSocket connection closed global");
        };

        socketv2.onerror = (error) => {
            // console.error("WebSocket error:", error);
        };
        socketv2.onmessage = (e) => {
            const data = JSON.parse(e.data)

            dispatch(setSocketGlobal({socketGlobal: data}))
        };
    }

    function closeSocket() {
        socketv2?.close();
    }

    useEffect(() => {
        isAuthQuery().unwrap().then((res) => {
            dispatch(setIsAuth(res?.authenticated))
            if (res.authenticated) {
                getUserInfo().unwrap().then((res) => {
                    setLoading(false)
                }).catch((e) => {
                    setLoading(false)
                    console.log(e)
                })
            }
            setLoading(false)
        }).catch((e) => {
            setLoading(false)
            console.log(e)
            dispatch(setIsAuth(false))
        })
    }, [])

    useEffect(() => {
        moment.tz.setDefault(timezone);
    }, [timezone, moment.tz.guess()])

    useEffect(() => {
        initSocket()
        return () => {
            closeSocket()
        }
    }, [isAuth])

    useEffect(() => {
        if (query_1024) {
            document.addEventListener("visibilitychange", (event) => {
                if (document.visibilityState === 'hidden') {
                    closeSocket()
                } else if (document.visibilityState === 'visible') {
                    initSocket()
                    window.location.reload()
                }
            });
        }

    }, [query_1024])

    useEffect(() => {
        console.log(socketGlobalData, 'socketGlobalData')
        if (socketGlobalData && socketGlobalData.t === 200) {
            dispatch(setActiveLots({
                ...activeLots, results: activeLots?.results?.map((el) => +el.id === +socketGlobalData?.ia ? ({
                    ...el,
                    highest_bid: socketGlobalData?.nba,
                    default_timer: socketGlobalData?.eed,
                    user_highest_bid: socketGlobalData?.cu ? socketGlobalData?.nba : el.user_highest_bid,
                    is_user_bid_highest: socketGlobalData?.cu
                }) : el)
            }))
        }
        if (socketGlobalData && socketGlobalData.t === 300 && socketGlobalData?.ia?.state === 'end') {
            dispatch(setActiveLots({
                ...activeLots, results: activeLots?.results?.filter((f) => +f.id !== +socketGlobalData?.ia?.id)
            }))
        }
        if (socketGlobalData && socketGlobalData.t === 801) {
            const free_bid_date = moment.tz(socketGlobalData.d || '', 'Europe/Moscow').clone().tz(moment.tz.guess())
            const now_date = moment.tz('Europe/Moscow').clone().tz(moment.tz.guess())

            toast.success(socketGlobalData.r || 'Бесплатный период активирован')

            dispatch(setUser({
                ...user,
                customer_profile: {
                    ...user?.customer_profile,
                    free_bid_period_ends_at: !socketGlobalData.d ? null : free_bid_date?.format(),
                    is_free_bid: !socketGlobalData.d ? null : free_bid_date?.valueOf() >= now_date?.valueOf()
                }
            }))
        }
        if (socketGlobalData && socketGlobalData.t === 802) {
            toast.warning(socketGlobalData.r || 'Бесплатный период окончен')

            dispatch(setUser({
                ...user,
                customer_profile: {
                    ...user?.customer_profile,
                    is_free_bid: false
                }
            }))
        }

        if (socketGlobalData && socketGlobalData?.t === 300) {
            const isHave = favorites?.results?.find((f) => +f.id === +socketGlobalData?.ia?.id)
            if (isHave) {
                dispatch(favoriteApi.util.invalidateTags(['fav']))
            }
        }

        if (socketGlobalData && socketGlobalData.t === 200) {
            const isHave = favorites?.results?.find((f) => +f.id === +socketGlobalData?.ia)

            if (isHave) {
                dispatch(setFavorites({
                    ...favorites, results: favorites?.results?.map((el) => +el.id === +socketGlobalData?.ia ? ({
                        ...el,
                        highest_bid: socketGlobalData?.nba,
                        highest_bid_amount: socketGlobalData?.nba,
                        default_timer: socketGlobalData?.eed
                    }) : el)
                }))
            }
        }
    }, [socketGlobalData])

    useEffect(() => {
        const confirmParam = searchParams.get('confirm');

        if (!confirmParam) return
        verifyEmail(confirmParam)
            .unwrap()
            .then((res) => {
                setShouldFetchCSRF(false)
                searchParams.delete("confirm");
                setSearchParams(searchParams)
                toast.success(res?.detail || 'Эл. почта подтверждена!')
                dispatch(setUser({
                    ...user,
                    email_verified: true
                }))
            })
            .catch((e) => {
                console.log(e)
                setShouldFetchCSRF(false)
                searchParams.delete("confirm");
                setSearchParams(searchParams)

                if (e.data?.detail) {
                    toast.error(e.data?.detail || 'Ошибка')
                } else {
                    toast.error('Ошибка')
                }
            })
    }, [searchParams])

    const resetPassword = (password = null, callback = null) => {
        const queryParams = new URLSearchParams(location.search);
        const confirmParam = queryParams.get('password_reset');

        if (!confirmParam) return

        const formData = new FormData();

        formData.append('token', confirmParam);

        if (password) {
            formData.append('new_password', password);
        }

        resentPasswordEmailWithToken(formData)
            .unwrap()
            .then((res) => {
                if (callback) {
                    callback()
                }
                if (password) {
                    toast.success(res.detail || 'Успешно')
                    queryParams.delete("confirm");
                    const newUrl = `${window.location.pathname}`;
                    window.history.pushState({path: newUrl}, '', newUrl);
                }
            })
            .catch((e) => {
                console.log(e)

                function errorHandler(errorObject) {

                    for (let key in errorObject) {
                        const value = errorObject[key];
                        if (Array.isArray(value)) {
                            value.forEach(error => {
                                toast.error(`${error}`);
                            });
                        } else {
                            toast.error(`${value}`);
                        }
                    }
                }

                if (e.data.detail) {
                    toast.error(e.data.detail || 'Ошибка')
                } else {
                    errorHandler(e.data)
                }
                queryParams.delete("confirm");
                const newUrl = `${window.location.pathname}`;
                window.history.pushState({path: newUrl}, '', newUrl);
            })
    }

    useEffect(() => {
        // toast.warning('Извините, текущая цена уже изменилась')
        if (isAuth || loading) return
        resetPassword(null, () => setOpenResetPasswordModal(true))
    }, [location.search, isAuth, loading])

    useEffect(() => {
        if (isError) {
            toast.error('Возникла ошибка, пожалуйста, перезагрузите страницу. Если ошибка сохраняется, обратитесь в службу технической поддержки')
        }
    }, [isError])

    useEffect(() => {
        if (tele) {
            console.log(tele)
            tele.ready()
            tele.expand()
            tele?.enableClosingConfirmation()
            tele?.setHeaderColor('#5654d4')
            tele?.setBackgroundColor('#ffffff')
        }
    }, [tele])

    useEffect(() => {
        const isInStandaloneMode = () => window.navigator.standalone === true;
        // alert(`standalone: ${isInStandaloneMode()}; iphone:${(navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent))}; isTelegramm: ${window.location.href.includes('t.me')}; initData: ${window.Telegram.WebApp.initData || false}`)
        setIsStandaloneMode(isInStandaloneMode() || false)
    }, [])

    if (isLoadingCategory || loading) return null


    return (
        // <ErrorBoundary FallbackComponent={ErrorHandler}>
        <>
            <AlertNotification status={socketGlobalData}/>
            {/*{!isAuth && <ModalWelcome/>}*/}
            {block !== false && <ModalBlock open={block}/>}
            {openResetPasswordModal &&
                <LoginNewPassword isLoading={isLoadingReset} handleConfirm={resetPassword} open={openResetPasswordModal}
                                  onClose={() => setOpenResetPasswordModal(false)}/>}
            <div className={classNames('app')}>

                <TimeZoneContext.Provider value={{
                    timezone: timezone,
                    changeTimeZone: (timezone) => setTimezone(timezone)
                }}>
                    <AuthContext.Provider value={{
                        open: openAuthModal,
                        isFromAuction: isFromAuction,
                        isAuth: isAuth,
                        actionModal: (value, isFromAuction = null) => {
                            setOpenAuthModal(value)
                            setIsFromAuction(isFromAuction)
                        },
                    }}>
                        <Header isAuth={isAuth}/>
                        <Navigate/>

                        <div className={'router'}>
                            <Router isAuth={isAuth}/>
                        </div>
                        <Footer
                            isIphoneAndStandalone={(isStandaloneMode && (navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)) || ((navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent) && window.Telegram.WebApp.initData)))}
                        />
                        <MobileNavigateBottom
                            isIphoneAndStandalone={(isStandaloneMode && (navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)) || ((navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent) && window.Telegram.WebApp.initData)))}
                            isAuth={isAuth}/>
                    </AuthContext.Provider>
                </TimeZoneContext.Provider>
            </div>
        </>
        // {/*</ErrorBoundary>*/}
    );
}

export default App;
