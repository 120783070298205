// export const BASE_URL = 'http://api.itembuy.tech/api/v1/'
// export const BASE_URL = 'http://127.0.0.1:8000/api/v1/'
export const BASE_URL = (window.location?.hostname === '127.0.0.1' && 'http://127.0.0.1:8000/api/v1/') || (window.location?.hostname === 'ib-wr0ng-ch2i-dev.ru' && 'https://api.ib-wr0ng-ch2i-dev.ru/api/v1') || 'https://api.itembuy.ru/api/v1/'
// https://api.itembuy.ru/api/v1/'
//
// export const BASE_URL_SOCKET = 'api.itembuy.tech'
// export const BASE_URL_SOCKET = '127.0.0.1:8000'https:///
export const BASE_URL_SOCKET = (window.location?.hostname === '127.0.0.1' && '127.0.0.1:8000') || (window.location?.hostname === 'ib-wr0ng-ch2i-dev.ru' && 'api.ib-wr0ng-ch2i-dev.ru') || 'api.itembuy.ru'
// 'api.itembuy.ru'
//
console.log(window.location)
// export const BASE_URL_IMAGE = 'http://api.itembuy.tech'
// export const BASE_URL_IMAGE = 'http://127.0.0.1:8000'
export const BASE_URL_IMAGE = (window.location?.hostname === '127.0.0.1' && 'http://127.0.0.1:8000') || (window.location?.hostname === 'ib-wr0ng-ch2i-dev.ru' && 'https://api.ib-wr0ng-ch2i-dev.ru') || 'https://api.itembuy.ru'
// https://api.itembuy.ru'
//
//

