import React from 'react';

import ItemMarketBox from "./item_market_box";
import ItemMarketLine from "./item_market_line";

const ItemMarket = ({
                        subcategory,
                        title,
                        shop_price,
                        retail_price,
                        hero_image,
                        category,
                        inc_aucs,
                        available_in_shop,
                        id,
                        attributes,
                        visibleType
                    }) => {


    return (
        <>
            {visibleType === 'box' ?
                <ItemMarketBox id={id} subcategory={subcategory} category={category} attributes={attributes}
                               new_price={shop_price} old_price={retail_price} title={title}
                               img={hero_image}
                               haveInAuc={inc_aucs?.length > 0 ? inc_aucs : false}/> :
                <ItemMarketLine id={id} subcategory={subcategory} category={category} attributes={attributes}
                                visibleType={visibleType} new_price={shop_price}
                                old_price={retail_price} title={title} img={hero_image}
                                haveInAuc={inc_aucs?.length > 0 ? inc_aucs : false}/>}


        </>
    );
};

export default ItemMarket;