import React from 'react';
import s from './styles.module.css'

const Title = ({title}) => {
    return (
       <h3 className={s.title}>{title}</h3>
    );
};

export default Title;
