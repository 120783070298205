import React, {useState} from 'react';
import s from './styles.module.css'
import HeaderModal from "../../../../components/header_modal";
import {useFormik} from "formik";
import {validate} from "./validate";
import Input from "../../../../common/ui_kit/inputs/input";
import Button from "../../../../common/ui_kit/button";
import TitleMobileAuth from "../../../../common/title_auth_mobile";

const LoginNewPassword = ({onClose, setStep, step}) => {
    const [value, setValue] = useState({
        password: '',
        re_password: '',
    })

    const formik = useFormik({
        initialValues: {
            password: '',
            re_password: '',
        },
        validate: validate,
        onSubmit: () => setStep({...step, step: 1})
    })
    return (
        <div>
            <HeaderModal isMobile={true} onClose={onClose} onClick={() => setStep({...step, step: 5})} title={'Придумайте новый пароль'}
                         arrow={true} typeModal={1}/>

            <TitleMobileAuth top={24} title={'Придумайте новый пароль'}/>

            <form className={s.form} onSubmit={(e) => e.preventDefault()}>
                <div className={s.enter_password_input}>
                    <Input value={formik.values.password}
                           type={'password'}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           name={'password'}
                           errorMessage={formik.touched.password && formik.errors.password}
                           placeholder={'Новый пароль'}/>

                    <Input value={formik.values.re_password}
                           type={'password'}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           name={'re_password'}
                           errorMessage={formik.touched.password && formik.errors.re_password}
                           placeholder={'Повторите пароль'}/>
                </div>

                <Button type={'submit'} onClick={formik.handleSubmit} className={s.btn}>Изменить пароль</Button>
            </form>
        </div>
    );
};

export default LoginNewPassword;
