export const validate = (values,) => {
    const errors = {};
    if (!values.password) {
        errors.password = 'Обязательное поле';
    }

    if (values.re_password !== values.password) {
        errors.re_password = 'Пароли не совпадают'
    }


    return errors;
}

