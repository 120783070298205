import React from 'react';
import Container from "../../../../components/container";
import s from './styles.module.css'
import AuctionUp from "../../../0-main/1-auction_up";

const Page3 = () => {
    return (
        <Container>
           <AuctionUp/>
        </Container>
    );
};

export default Page3;