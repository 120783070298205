import React from 'react';
import s from './styles.module.css'

const Input = ({
                   onChange,
                   disabled = false,
                   value,
                   type = 'text',
                   onBlur,
                   min = 0,
                   placeholder,
                   style,
                   errorMessage = '',
                   name,
                   onFocus,
                   onKeyPress = null,
                   title
               }) => {
    return (
        <div className={s.input}>
            {title && <p className={s.title}>{title}</p>}
            <input style={style}
                   autoComplete="new-password"
                   type={type}
                   onKeyPress={onKeyPress}
                   disabled={disabled}
                   name={name}
                   min={min}
                   value={value}
                   onBlur={onBlur}
                   onFocus={onFocus}
                   placeholder={placeholder}
                   onChange={onChange}/>
            <span className={s.errorMessage}>{errorMessage}</span>
        </div>

    );
};

export default Input;
