import React, {useContext, useEffect, useState} from 'react';
import Container from "../container";
import s from './styles.module.css'
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {ReactComponent as Logo} from "../../assets/svg/logo_prod_dark.svg";
import Tg from "../../assets/svg/tg";
import Vk from "../../assets/svg/vk";
import moscow from "../../assets/images/moscow.png";
import classNames from "classnames";
import {PATH} from "../../constants/path";
import ModalAskQuestion from "../../common/modal/custom_modal/modal_ask_question";
import CustomModal from "../../common/modal/custom_modal";
import ModalStaySupplier from "../../common/modal/custom_modal/modal_stay_supplier";
import ModalWorkUs from "../../common/modal/custom_modal/modal_work_us";
import {Avatar} from "@mui/material";

const Footer = ({isIphoneAndStandalone = false}) => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const [openSupport, setOpenSupport] = useState(false)
    const [openSupplier, setOpenSupplier] = useState(false)
    const [openWorkUs, setOpenWorkUs] = useState(false)

    useEffect(() => {
        if (searchParams.get('supplier') === 'open') {
            setOpenSupplier(true)
            searchParams.delete('supplier')
            setSearchParams(searchParams)
        }
    }, [searchParams])
    return (
        <div className={classNames(s.footer, isIphoneAndStandalone && s.footer_isIphone_isStandAlone)}>
            {openSupport &&
                <CustomModal open={openSupport}
                             onClose={() => setOpenSupport(!openSupport)}>
                    <ModalAskQuestion onClose={() => setOpenSupport(!openSupport)}/>
                </CustomModal>}

            {openSupplier &&
                <CustomModal open={openSupplier}
                             onClose={() => setOpenSupplier(!openSupplier)}>
                    <ModalStaySupplier onClose={() => setOpenSupplier(!openSupplier)}/>
                </CustomModal>}

            {openWorkUs &&
                <CustomModal open={openWorkUs}
                             onClose={() => setOpenWorkUs(!openWorkUs)}>
                    <ModalWorkUs onClose={() => setOpenWorkUs(!openWorkUs)}/>
                </CustomModal>}

            <Container>
                <div className={s.footer_content}>
                    <div className={s.footer_top}>
                        <div className={s.logo_box}>
                            <div className={s.logo_box_wrapper}>
                                <Logo className={s.logo} onClick={() => navigate('/')}/>
                                <a className={s.moscow_box} href={'https://i.moscow/company/52323030'}
                                   target={'_blank'}>
                                    <Avatar src={moscow} sx={{
                                        borderRadius: '0', height: '44px', width: '69px',
                                        '@media (max-width: 768px)': {
                                            height: '33px',
                                            width: '52px'
                                        }
                                    }}/>
                                    <p className={s.moscow_title}>
                                        Участник <br/>
                                        Московского <br/>
                                        инновационного <br/>
                                        кластера
                                    </p>
                                </a>
                            </div>
                        </div>

                        <div className={classNames(s.box_list, s.box_list_1)}>
                            <h3 className={s.title_ul}>Компания</h3>
                            <div className={s.list}>
                                <NavLink to={`${PATH.BLOGS}/6`}>О нас</NavLink>
                                <NavLink to={PATH.REGUISITES}>Реквизиты</NavLink>
                                <NavLink to={PATH.DOCUMENTROOT}>Документы и правила</NavLink>
                                <NavLink to={PATH.CONTACT}>Контакты</NavLink>
                                <NavLink to={PATH.SUPPORT}>Помощь</NavLink>
                            </div>
                        </div>

                        <div className={classNames(s.box_list, s.box_list_2)}>
                            <h3 className={s.title_ul}>Партнерам</h3>
                            <div className={s.list}>
                                <p onClick={() => setOpenSupplier(true)}>Стать поставщиком</p>
                                <p onClick={() => setOpenWorkUs(true)}>Работать с нами</p>
                            </div>
                        </div>

                        <div className={s.contact}>
                            <a href="tel:8(800)2509133">
                                <p className={s.number}>8 (800) 250-91-33</p>
                            </a>
                            <a href={`mailto:info@itembuy.ru`}>
                                <p className={s.email}>info@itembuy.ru</p>
                            </a>
                        </div>
                    </div>

                    <div className={s.action_footer}>
                        <div className={s.social}>
                            <a href="https://vk.com/itembuy" target={'_blank'}>
                                <div className={s.social_item}>
                                    <Vk/>
                                </div>
                            </a>

                            <a href="https://t.me/itembuy" target={'_blank'}>
                                <div className={classNames(s.social_item, s.tg)}>
                                    <Tg/>
                                </div>
                            </a>
                        </div>
                        <div/>
                        <div/>
                        <div className={s.btn} onClick={() => setOpenSupport(true)}>Написать в поддержку</div>
                    </div>

                    <div className={s.footer_bottom}>
                        <p>© 2023-2024 ITEMBUY — Онлайн-аукцион электроники, бытовой техники, модных аксессуаров,
                            автомобилей, недвижимости.
                            <br/>Все права защищены.</p>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Footer;
