import React from 'react';
import s from './styles.module.css'
import {Skeleton} from "@mui/material";

const PreloaderPageLoad = () => {
    return (
        <div className={s.main}>
            <Skeleton variant="rounded"
                      width={`100%`}
                      sx={{borderRadius: '16px'}}
                      height={'100%'}/>
        </div>
    );
};

export default PreloaderPageLoad;