import React from 'react';
import s from "../modal_work_us/styles.module.css";
import classNames from "classnames";
import {ReactComponent as Icon} from "../../../../assets/svg/modal/star.svg";
import {ReactComponent as Close} from "../../../../assets/svg/modal/close.svg";

import {useFormik} from "formik";
import axios from "axios";
import {toast} from "react-toastify";
import CustomSelect from "../../../ui_kit/inputs/select";
import Input from "../../../ui_kit/inputs/input";
import InputMaskNumber from "../../../ui_kit/inputs/input_mask";
import TextArea from "../../../ui_kit/inputs/textarea";
import Button from "../../../ui_kit/button";
import DescriptionFooterModal from "../../description_footer_modal";
import {validate} from "./validate";
import {useGetCategorySupplierQuery, useSendSupplierMessageMutation} from "../../../../redux/user.service";
import {cleanPhoneNumber} from "../../../../helper/clearNumber";
import HeaderModal from "../../../../components/header_modal";
import DescriptionSuccessInfo from "../../../description_succes_info";

const ModalStaySupplier = ({onClose}) => {
    const {data, isLoading} = useGetCategorySupplierQuery('')
    const [sendSupplierMessage, {isLoading: isLoadingSend}] = useSendSupplierMessageMutation()

    const formik = useFormik({
        initialValues: {
            category: '',
            organization: '',
            repr_name: '',
            repr_phone_number: '',
            brands: '',
            repr_email: '',
        },
        validate,
        onSubmit: (values) => {
            sendSupplierMessage({
                ...values,
                category: values.category?.value,
                repr_phone_number: `+${cleanPhoneNumber(values.repr_phone_number)}`
            })
                .unwrap()
                .then((res) => {
                    toast.success(res.detail || 'Сообщение отправлено')
                    onClose()
                })
                .catch((e) => {
                    if (e.data?.detail) {
                        toast.error(e.data?.detail)
                    } else if (e.status === 400) {
                        for (let i = 0; i < Object.keys(e.data)?.length; i++) {
                            if (e.data[Object.keys(e.data)[i]] && e.data[Object.keys(e.data)[i]][0]) {
                                toast.error(e.data[Object.keys(e.data)[i]][0] || 'Ошибка')
                            } else {
                                toast.error('Ошибка')
                            }

                        }
                    } else {
                        toast.error('Ошибка')
                    }
                })
        }
    })

    return (
        <div className={s.content}>
            <HeaderModal isMobile={true} onClose={onClose} classNameTitle={s.icon_title} title={<div className={s.icon}>
                <Icon/>
            </div>} typeModal={1}/>

            <div className={s.header_title_box}>
                <div className={classNames(s.icon, s.icon_mobile)}>
                    <Icon/>
                </div>

                <h3 className={s.title}>Станьте поставщиком <br/> ITEMBUY</h3>
                <p className={s.sub_title}>Если вы хотите продавать свой товар на ITEMBUY заполните форму и мы
                    обязательно свяжемся с вами</p>
            </div>

            <form onSubmit={(e) => {
                e.preventDefault()
                formik.handleSubmit()
            }} className={s.main_action}>
                <div className={s.action}>
                    <CustomSelect placeholder={'Выберите категории'}
                                  errorMessage={formik.touched.category && formik.errors.category}
                                  options={data?.results ? data?.results?.map((el) => ({
                                      label: el?.title,
                                      value: el?.id
                                  })) : []}
                                  name={'category'}
                                  value={formik.values.category}
                                  onChange={(value) => {
                                      formik.setFieldValue('category', value)
                                  }}/>
                    <Input value={formik.values.organization}
                           errorMessage={formik.touched.organization && formik.errors.organization}
                           name={'organization'}
                           onBlur={formik.handleBlur}
                           placeholder={'Наименование организации'}
                           onChange={formik.handleChange}/>

                    <Input value={formik.values.repr_name}
                           name={'repr_name'}
                           placeholder={'Контактное лицо / ФИО'}
                           errorMessage={formik.touched.repr_name && formik.errors.repr_name}
                           onChange={formik.handleChange} onBlur={formik.handleBlur}/>

                    <Input value={formik.values.repr_email} onBlur={formik.handleBlur} name={'repr_email'}
                           placeholder={'Email'}
                           errorMessage={formik.touched.repr_email && formik.errors.repr_email}
                           onChange={formik.handleChange}/>

                    <InputMaskNumber
                        errorMessage={formik.touched.repr_phone_number && formik.errors.repr_phone_number}
                        value={formik.values.repr_phone_number} name={'repr_phone_number'}
                        placeholder={'+7 000 000-00-00'}
                        mask="+7 (999) 999-99-99"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}/>

                    <TextArea value={formik.values.brands} name={'brands'}
                              errorMessage={formik.touched.brands && formik.errors.brands}
                              onBlur={formik.handleBlur}
                              placeholder={'Представителем каких брендов вы являетесь?'}
                              onChange={formik.handleChange}/>

                    <div className={s.btn}>
                        <Button type={'submit'}
                                disabled={isLoadingSend}
                        >Отправить</Button>
                        <DescriptionSuccessInfo/>
                    </div>
                </div>
            </form>


        </div>
    );
};

export default ModalStaySupplier;
