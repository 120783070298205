import {createApi} from '@reduxjs/toolkit/query/react'
import {baseQueryWithReauth} from "../api/baseQuery";
import Cookies from 'js-cookie';

export const authApi = createApi({
        reducerPath: 'authApi',
        baseQuery: baseQueryWithReauth,
        tagTypes: ['authApi'],
        endpoints: (build) => ({
            getCSRF: build.query({
                query: () => ({
                    url: `auth/csrf/`,
                    method: 'GET',
                }),
                transformResponse: (response, meta) => {
                    // Здесь вы можете получить доступ к заголовкам через meta.response.headers
                    const token = meta?.response?.headers.get('X-CSRFToken')
                    Cookies.set('token', token);
                    return response;
                },
                providesTags: ['authApi'],
            }),
            login: build.mutation({
                query: (payload) => ({
                    url: `auth/login/`,
                    method: 'POST',
                    body: JSON.stringify({
                        [payload.type]: payload.login,
                        "password": payload.password,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('token')
                    }
                }),
                transformResponse: (response, meta) => {
                    return response;
                },
                invalidatesTags: (res, error, erg) => error ? [] : ['authApi']
            }),
            logoutQuery: build.mutation({
                query: (payload) => ({
                    url: `auth/logout/`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('token')
                    }
                }),
                transformResponse: (response, meta) => {
                    return response;
                },
                invalidatesTags: (res, error, erg) => error ? [] : ['authApi']
            }),
            isAuthQuery: build.mutation({
                query: () => ({
                    url: `auth/user/is-authenticated/`,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('token')
                    }
                }),
                transformResponse: (response, meta) => {

                    return response;
                },
            }),

            registrationQuery: build.mutation({
                queryFn: async (payload, api, extraOptions, baseQuery) => {

                    const removeNulls = (obj) => {
                        const newObj = {};
                        Object.keys(obj).forEach((key) => {
                            const value = obj[key];
                            if (value !== null) {
                                newObj[key] = typeof value === 'object' ? removeNulls(value) : value;
                            }
                        });
                        return newObj;
                    };

                    const requestBody = removeNulls({
                        email: payload.email,
                        phone_number: payload.number,
                        password: payload.password,
                        otp: payload?.otp || null,
                        invited_by_user_token: payload?.invited_by_user_token || null,
                        // customer_profile: {
                        //     firstname: payload.first_name,
                        //     surname: payload.last_name,
                        //     patronymic: payload.middle_name || '',
                        //     sex: payload.gender,
                        //     birthday: payload.birthday,
                        //     avatar: null
                        // }
                    });

                    const csrfToken = await baseQuery({
                        url: `auth/csrf/`,
                        method: 'GET',
                    })

                    const token = csrfToken?.meta?.response?.headers.get('X-CSRFToken')
                    Cookies.set('token', token);

                    const register = await baseQuery({
                        url: `auth/register/`,
                        method: 'POST',
                        body: JSON.stringify(requestBody),
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': token
                        }
                    })

                    return register
                }
            }),
            makeActive: build.mutation({
                query: (id) => ({
                    url: `auth/make-active/?user_id=${id}`,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('token')
                    }
                }),
                transformResponse: (response, meta) => {
                    return response;
                },
            }),
            resendCodeSms: build.mutation({
                query: (formData) => ({
                    url: `auth/2fa/resend-phone-verification-code/`,
                    method: 'POST',
                    formData: true,
                    body: formData,
                    headers: {
                        'X-CSRFToken': Cookies.get('token')
                    }
                }),
            }),
            resentPasswordEmail: build.mutation({
                query: (formData) => ({
                    url: `auth/reset-password/send/`,
                    method: 'POST',
                    formData: true,
                    body: formData,
                    headers: {
                        'X-CSRFToken': Cookies.get('token')
                    }
                }),
            }),
            resentPasswordMobile: build.mutation({
                query: (formData) => ({
                    url: `auth/2fa/reset-password/send/`,
                    method: 'POST',
                    formData: true,
                    body: formData,
                    headers: {
                        'X-CSRFToken': Cookies.get('token')
                    }
                }),
            }),
            setPasswordMobile: build.mutation({
                query: (formData = {}) => {
                    // formData.append('csrfmiddlewaretoken', Cookies.get('token'));
                    return {
                        url: `auth/2fa/reset-password/set/`,
                        method: 'POST',
                        formData: true,
                        body: formData,
                        headers: {
                            'X-CSRFToken': Cookies.get('token')
                        }
                    }
                }
                ,
            }),
            resentPasswordEmailWithToken: build.mutation({
                query: (formData) => ({
                    url: `auth/reset-password/check/`,
                    method: 'POST',
                    formData: true,
                    body: formData,
                    headers: {
                        'X-CSRFToken': Cookies.get('token')
                    }

                }),
            }),
        }),
    }
)

export const {
    useGetCSRFQuery,
    useMakeActiveMutation,
    useLoginMutation,
    useLogoutQueryMutation,
    useIsAuthQueryMutation,
    useRegistrationQueryMutation,
    useResendCodeSmsMutation,
    useResentPasswordEmailMutation,
    useResentPasswordMobileMutation,
    useSetPasswordMobileMutation,
    useResentPasswordEmailWithTokenMutation,
} = authApi;
