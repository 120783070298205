import React, {useEffect} from 'react';
import s from './styles.module.css'
import ActiveLotItem from "./active_lot_item";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveLots, setActiveLots} from "../../../redux/slice/active_lots.slice";
import {selectSocketGlobalSlice} from "../../../redux/slice/socketroom.slice";

const ActiveLots = ({closeActiveLots}) => {
    const dispatch = useDispatch()

    const {activeLots} = useSelector(selectActiveLots)
    // const {socketGlobal} = useSelector(selectSocketGlobalSlice)
    //
    // useEffect(() => {
    //     if(socketGlobal && socketGlobal?.t && socketGlobal?.t === 200) {
    //         setActiveLots
    //     }
    // },[socketGlobal])
    return (
        <div className={s.content} onClick={(e) => e.stopPropagation()}>
            <div className={s.content_items}>
                {(!activeLots || activeLots?.results?.length === 0) ?
                    <h1 className={s.empty}>Нет активных лотов</h1> : activeLots.results?.map((el, i) =>
                        <ActiveLotItem {...el}  closeActiveLots={closeActiveLots} user_highest_bid={el?.user_highest_bid}
                                       isLast={(activeLots.results?.length - 1) === i} key={el.id}/>)
                }
            </div>
        </div>
    );
};

export default ActiveLots;
