import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slice/user.slice";
import HelmetHeader from "../../common/helment";
import s from './styles.module.css'
import Container from "../../components/container";
import Header from "./header";
import Content from "./content";
import { useBuySubscribeMutation, useGetSubscribeListQuery } from "../../redux/user.service";
import { useNavigate } from "react-router-dom";
import AccordionInfo from "../0-main_promo/components/4-page/accordion";
import Button from "../../common/ui_kit/button";
import { PATH } from "../../constants/path";

const data_info = [
    { sub_title: 1, title: 'Что такое повышение цены и зачем оно нужно?' },
    { sub_title: 2, title: 'Как работает система тарифов?' },
    { sub_title: 3, title: 'Что произойдёт, когда у меня закончатся повышения?' },
    { sub_title: 4, title: 'Можно ли использовать бонусы для покупки повышений?' },
    { sub_title: 5, title: 'Чем отличается тариф “Профессионал” от других?' },
    { sub_title: 6, title: 'Что будет, если я не использую все повышения?' },
    { sub_title: 7, title: 'Могу ли я докупить повышения, если они ещё не закончились?' },
]

const Subscribe = () => {


    const { data, isLoading } = useGetSubscribeListQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const navigate = useNavigate()

    const components = {
        1: <p className={s.title_acc}>Повышение цены — это возможность сделать своё ценовое предложение в аукционе на ITEMBUY. Каждое такое предложение увеличивает текущую цену лота. Пользователь, сделавший последнее повышение (предложивший лучшую цену), выигрывает аукцион.</p>,
        2: <p className={s.title_acc}>Вы выбираете один из доступных тарифов, который включает определённое количество повышений цены. Чем больше повышений в тарифе, тем дешевле каждое отдельное повышение.</p>,

        3: <p className={s.title_acc}>
            Когда ваши повышения закончатся, вы больше не сможете участвовать в аукционе. Это может привести к тому, что ваша последняя ставка будет перебита. В этом случае вам предложат приобрести новый тариф для продолжения участия.
        </p>,

        4: <p className={s.title_acc}>
            Да, вы можете частично или полностью оплатить выбранный тариф с помощью бонусов, заработанных на платформе.
        </p>,

        5: <p className={s.title_acc}>Тариф "Профессионал" включает 500 повышений, что делает его самым выгодным по стоимости за одно повышение. Он отлично подходит для активных пользователей и профессионалов, так как предоставляет больше гибкости и возможностей для участия в аукционах.</p>,
        6: <p className={s.title_acc}>
            Неиспользованные повышения остаются на вашем счёте и могут быть использованы в будущем. Срок их действия не ограничен.
        </p>,
        7: <p className={s.title_acc}>
            Да, вы можете докупить дополнительный тариф в любое время, даже если у вас ещё остались доступные повышения. Это даст вам больше возможностей для участия в аукционах.
        </p>,
    }
    console.log(data?.results)
    if (isLoading) return
    return (
        <div className={s.content}>
            <HelmetHeader title={'ITEMBUY: Тарифы'}
                description={'Ознакомьтесь с доступными тарифами на ITEMBUY. Выберите подходящий план, чтобы получить больше возможностей для управления вашими покупками на онлайн-аукционе.'}
            />

            <Header />
            <Container>
                <Content
                    subscribes={(data?.results ? [...data?.results]?.sort((a, b) => b?.price - a?.price) : []) || []} />

                <div className={s.content_info}>
                    <h4 className={s.title}>
                        Часто-задаваемые <br />
                        вопросы
                    </h4>

                    <div className={s.box}>
                        {data_info?.map((el, i) => {
                            return <AccordionInfo label={el.title} key={el?.sub_title}>
                                {components[el.sub_title]}
                            </AccordionInfo>
                        })}

                    </div>

                    <div className={s.btn}>
                        <Button onClick={() => navigate(`${PATH.AUCTIONS}/up`)}>Перейти к аукционам</Button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Subscribe;
