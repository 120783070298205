import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {ReactComponent as InfoIcon} from '../../assets/svg/info_welcome.svg'
import HeaderModal from "../header_modal";
import Button from "../../common/ui_kit/button";
import CustomModal from "../../common/modal/custom_modal";
import {ReactComponent as Logo} from "../../assets/svg/logo_v2.svg";
import {ReactComponent as Vk} from "../../assets/svg/vk_s.svg";
import classNames from "classnames";
import {ReactComponent as Tg} from "../../assets/svg/tg_s.svg";
import {useTimer} from "react-timer-hook";
import moment from "moment/moment";
import {useDispatch} from "react-redux";

const ModalBlock = ({open}) => {
    const handleClose = () => {
        window.location.reload();
    }
    const {
        totalSeconds,
        isRunning,
        restart
    } = useTimer({
        expiryTimestamp: new Date(moment().add(open, 'seconds')),
        autoStart: true,
    });

    useEffect(() => {
        restart(new Date(moment().add(open, 'seconds')), true)
    }, [open])
    return (
        <CustomModal open={open !== false}
                     zIndex={99999999999999999999}
        >
            <HeaderModal isMobile={true}
                         isHiddenIconClose={true}
                         title={<Logo/>}
                         arrow={false} typeModal={1}/>
            <div className={s.content}>
                <div className={s.content_top}>
                    <div className={s.icon}>
                        <Logo/>
                    </div>
                    <div className={s.description_box}>
                        <p>
                            <b>Блокировка!</b>
                        </p>
                        <p>
                            Ваши действия временно заблокированы.
                        </p>
                        <p>
                            {`Мы заметили, что вы слишком часто отправляли запросы к серверу. Пожалуйста, подождите
                                ${totalSeconds} сек. перед тем, как продолжить. Эта мера необходима для предотвращения перегрузки
                                системы и обеспечения ее стабильной работы для всех пользователей.`}
                        </p>
                        <p>
                            Спасибо за ваше понимание и терпение!
                        </p>
                    </div>
                </div>

                <Button disabled={isRunning} loading={!isRunning} className={s.btn} onClick={handleClose}>{
                    isRunning ? `Закрыть через ${totalSeconds} сек.` : 'Закрыть'
                }</Button>
            </div>
        </CustomModal>
    );
};

export default ModalBlock;
