import React from 'react';
import {Skeleton} from "@mui/material";
import s from './styles.module.css'

const BreadCrumbs = ({breadcrumbs, last_dot, style, isLoading = false}) => {
    return (
        <div className={s.bread_crumb_container} style={style}>
            {!isLoading && breadcrumbs.map((el, i) => <div key={i} style={{display: el?.isHidden ? 'none' : 'flex'}}>
                <p onClick={() => {
                    if (!el.action) return
                    el.action()
                }} className={s.crumb} style={{
                    cursor: el.action && 'pointer'
                }}>{el.title}</p>

                {el?.hiddenDot ? null : <p style={{
                    display: (last_dot === (i + 1)) ? 'none' : 'block'
                }} className={s.dot}>•</p>}
            </div>)}

            {isLoading && <Skeleton variant="rounded"
                                    width={`400px`}
                                    height={'16px'}
                                    sx={{borderRadius: '16px'}}/>}
        </div>
    );
};

export default BreadCrumbs;
