import React from 'react';
import HeaderModal from "../../../../components/header_modal";
import s from './styles.module.css'
import {useFormik} from "formik";
import {validate} from "../../login/login_code/validate";
import InputMaskNumber from "../../../../common/ui_kit/inputs/input_mask";
import Button from "../../../../common/ui_kit/button";
import TitleMobileAuth from "../../../../common/title_auth_mobile";
import SendAgain from "./send_again";
import {useResendCodeSmsMutation} from "../../../../redux/auth.service";
import {cleanPhoneNumber} from "../../../../helper/clearNumber";
import {toast} from "react-toastify";

const RegistrationCode = ({
                              onClose,
                              step,
                              setError,
                              setTimerResetSms,
                              regFormik,
                              timerResetSms,
                              valueToSend,
                              loading,
                              error
                          }) => {
    const [resendCodeSms, {isLoading}] = useResendCodeSmsMutation()
    const formik = useFormik({
        initialValues: {
            code: ''
        },
        validate: validate,
        onSubmit: (values) => {
            if (!values.code) return
            const code = values.code.match(/\d+/g)

            regFormik.setFieldValue('otp', code?.join(''))
            setTimeout(() => regFormik.handleSubmit(), 0)
        }
    })

    const handlerResendSms = () => {
        const formData = new FormData();
        formData.append('phone_number', `+${cleanPhoneNumber(valueToSend)}`);
        resendCodeSms(formData)
            .unwrap()
            .then((res) => {
                toast.success(res?.detail || 'Сообщение отправлено')
                setTimerResetSms({
                    date: res.res_at || '',
                    number: valueToSend
                })
                setError('')

            })
            .catch((e) => {
                console.log(e)
                toast.error(e?.data?.detail || 'Ошибка')
                setTimerResetSms({
                    date: e?.data?.service_info || '',
                    number: valueToSend
                })
            })
    }
    return (
        <div>
            <HeaderModal isMobile={true} onClose={() => {
                onClose()
                regFormik.setFieldValue('otp', null)
            }}
                         title={'Подтверждение'}
                         arrow={false} typeModal={1}/>

            <TitleMobileAuth top={24} title={'Подтверждение'}/>

            <div className={s.enter_password_title}>
                <p>{step.enter_type === 'mobile' ?
                    <>Мы отправили код на номер <br/>
                        {valueToSend || '+7 (000) 000-00-00'}</> :
                    <>Мы отправили код на почту <br/>
                        {valueToSend || 'test@gmail.com'}</>}</p>
            </div>

            <form className={s.form} onSubmit={(e) => {
                e.preventDefault()
                formik.handleSubmit()
            }}>
            <div className={s.code_box}>
                <InputMaskNumber maskChar={null} mask="999-999" placeholder={'000-000'}
                                 value={formik.values.code}
                                 onChange={formik.handleChange}
                                 name={'code'}
                                 onBlur={formik.handleBlur}
                                 errorMessage={formik.touched.code && formik.errors.code}
                />

                {(error && (error?.code === 303 || error?.code === 302)) &&
                    <span className={s.try_count}>Попыток {error.tries}</span>}
            </div>

            <div className={s.btns}>
                <Button type={'submit'}
                        disabled={loading}
                         className={s.btn_enter}>
                    Подтвердить
                </Button>

                <SendAgain timerResetSms={timerResetSms} isLoading={isLoading} handlerResendSms={handlerResendSms}/>
            </div>
            </form>
        </div>
    );
};

export default RegistrationCode;
