import React, {useState} from 'react';
import {Avatar, Box, Modal} from "@mui/material";
import s from './styles.module.css'
import {ReactComponent as Close} from "../../../../assets/svg/close.svg";
import classNames from "classnames";

import {
    GlassMagnifier,
} from "react-image-magnifiers";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '85%',
    width: '100%',
    maxHeight: '90%',
    overflow: 'auto',
    padding: '24px',
    background: ' #FFFFFF',
    borderRadius: '16px',
};

const ModalImgView = ({open, onClose, title = '', description = '', images, index}) => {
    const [showImg, setShowImg] = useState(index)
    const [isTextClamped, setIsTextClamped] = useState(false)
    const [modalDescription, setModalDescription] = useState(false)

    return (
        <Modal
            sx={{
                zIndex: 99999999
            }}
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={{
                ...style,
                '@media (max-width: 460px)': {
                    width: '95%'
                }
            }}>
                <div className={s.header}>
                    <p className={s.title}>{title}</p>
                    <div className={s.close_icon} onClick={onClose}>
                        <Close/>
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.content_left}>
                        <GlassMagnifier
                            cursorStyle={'crosshair'}
                            allowOverflow={false}
                            magnifierBorderColor={'#E9EAEC'}
                            imageSrc={images[showImg]}
                            imageAlt="Example"
                            largeImageSrc={images[showImg]}
                        />
                    </div>
                    <div className={s.content_right}>
                        <div className={s.images}>
                            {images?.map((el, i) => <div
                                style={{
                                    border: showImg === i && '1px solid #FFD34A'
                                }}
                                className={classNames(s.choose_img, i !== showImg && s.choose_img_hover)}
                                onClick={() => setShowImg(i)}>
                                <Avatar sx={{
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '8px',
                                }} alt={'image'}
                                        src={el}/>
                            </div>)}
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default ModalImgView;
