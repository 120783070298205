import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BASE_URL} from "../constants/url";
import {setBlock} from "../redux/slice/global.slice";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, {getState}) => {
        return headers
    },
})
let maxRetryAfter = 0;

export const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error && result?.error?.status === 429) {
        console.log('error 429')
        const retryAfter = +result?.meta?.response?.headers.get('Retry-After') || 0;
        console.log(`retryAfter: ${retryAfter};${result?.meta?.response?.headers.get('Retry-After')}`)
        console.log(`result request: ${result}`)
        if (retryAfter > maxRetryAfter) {
            maxRetryAfter = retryAfter;
            // console.log(maxRetryAfter)
            console.log('error 429 inside dispatch')
            api.dispatch(setBlock(maxRetryAfter));
        }
    }
    return result;

};
