import React, {useEffect} from 'react';
import s from './styles.module.css'
import Container from "../../../components/container";
import {ReactComponent as AuctionUpIcon} from '../../../assets/svg/auction_up.svg'
import TitleAuction from "../../../common/title_auction";
import {NavLink, useNavigate} from "react-router-dom";
import {PATH} from "../../../constants/path";
import AuctionItemUp from "../../../components/auction_item/auctiom_item_up";
import {Skeleton, Tooltip, useMediaQuery} from "@mui/material";
import {useGetAuctionsUpQuery} from "../../../redux/auctions.service";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {selectAuctions, setAuctions} from "../../../redux/slice/auctions.slice";
import {selectSocketGlobalSlice} from "../../../redux/slice/socketroom.slice";

const AuctionUp = () => {
    const dispatch = useDispatch()
    const {socketGlobal} = useSelector(selectSocketGlobalSlice)
    const {auctions: auction_up} = useSelector(selectAuctions)

    const navigate = useNavigate()
    const query_700 = useMediaQuery('(max-width:780px)');
    const {error, isLoading: isLoadingUp, refetch} = useGetAuctionsUpQuery({page_size: 4}, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
    })

    useEffect(() => {
        if (socketGlobal?.t === 300) {
            refetch()
        } else if (socketGlobal?.t === 200) {
            const dataNew = auction_up?.results?.find((f) => +f.id === +socketGlobal?.ia)
            if (!dataNew) return
            const date = moment.tz(socketGlobal?.eed, 'Europe/Moscow')

            dispatch(setAuctions({
                auctions: {
                    ...auction_up,
                    results: auction_up?.results?.map((el) => +el.id === +socketGlobal?.ia ? ({
                        ...el,
                        highest_bid_amount: socketGlobal?.nba,
                        highest_bid_date: date?.format(),
                        default_timer: date.format()
                    }) : el)
                }
            }))
        }
    }, [socketGlobal])

    return (
        <div className={s.auction_up_container}>
            <Container>
                <TitleAuction withButton={true} title={'Аукционы на повышение'} link={`${PATH.AUCTIONS}/up`}
                              svg={<Tooltip title={'Торги на повышение'}><AuctionUpIcon/></Tooltip>}/>

                <div className={s.content}>
                    {isLoadingUp
                        ? [1, 2, 3, 4].map(el => <Skeleton key={el} variant="rounded"
                                                           width={`100%`}
                                                           sx={{borderRadius: '16px'}}
                                                           height={query_700 ? '305px' : `580px`}/>)
                        : auction_up?.results?.map((el) => {
                            return <AuctionItemUp
                                isLoadingUp={isLoadingUp}
                                path={`${PATH.AUCTION}/up/${el?.product?.category_slug}/${el?.product?.subcategory_slug}/${el?.id}`}
                                onClick={() => navigate(`${PATH.AUCTION}/up/${el?.product?.category_slu}/${el?.product?.subcategory_slug}/${el?.id}`)}
                                key={el.id} {...el}/>
                        })}
                </div>

                <NavLink to={`${PATH.AUCTIONS}/up`} className={s.link_all_mobile}>
                    Все лоты
                </NavLink>
            </Container>
        </div>
    );
};

export default AuctionUp;
