import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReauth} from "../api/baseQuery";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import {formatDateNameWeek} from "../helper/convertDate";

export const favoriteApi = createApi({
        reducerPath: 'favoriteApi',
        baseQuery: baseQueryWithReauth,
        tagTypes: ['fav'],
        endpoints: (build) => ({
            setFavorite: build.mutation({
                query: (id) => ({
                    url: 'auth/user/follow/auctions/',
                    method: 'POST',
                    body: {
                        id: id
                    },
                    headers: {
                        'X-CSRFToken': Cookies.get('token')
                    }
                }),
                invalidatesTags: (res, error, erg) => error ? [] : ['fav']
            }),
            getFavorite: build.query({
                query: (id) => ({
                    url: 'auctions/?follow=true&page=1&page_size=100',
                    method: 'GET',
                    headers: {
                        'X-CSRFToken': Cookies.get('token')
                    }
                }),
                transformResponse(baseQueryReturnValue, meta, arg) {
                    return {
                        ...baseQueryReturnValue, results: baseQueryReturnValue?.results?.map((el) => {
                            const time_for_bid = el?.time_for_bid?.split(':')

                            return {
                                ...el, default_timer: el.state === 'run' ?
                                    (el?.highest_bid_date ?
                                        moment.tz(el?.highest_bid_date, 'Europe/Moscow').add(time_for_bid[0], 'hours').add(time_for_bid[1], 'minutes').add(time_for_bid[2], 'second').format()

                                        : moment.tz(el?.start_date, 'Europe/Moscow').add(time_for_bid[0], 'hours').add(time_for_bid[1], 'minutes').add(time_for_bid[2], 'second').format())
                                    : moment().format()
                            }
                        })
                    }
                },
                providesTags: ['fav']
            }),
            getFavoriteNear: build.query({
                query: (id) => ({
                    url: 'auctions/?follow=true&page=1&page_size=100&state=pub,run',
                    method: 'GET',
                    headers: {
                        'X-CSRFToken': Cookies.get('token')
                    }
                }),
                transformResponse(baseQueryReturnValue, meta, arg) {


                    function groupByDate(arr) {
                        return arr.reduce((result, item) => {
                            const dateMomemnt = moment.tz(item.start_date, 'Europe/Moscow').clone().tz(moment.tz.guess())

                            const dateKey = formatDateNameWeek(dateMomemnt?.format());
                            result[dateKey] = result[dateKey] || [];
                            result[dateKey].push(item);
                            return result;
                        }, {});
                    }

                    return {
                        group: groupByDate(baseQueryReturnValue?.results),
                        result: baseQueryReturnValue?.results
                    }
                },
                providesTags: ['fav']
            }),
        })
    }
)

export const {
    useSetFavoriteMutation,
    useGetFavoriteQuery,
    useGetFavoriteNearQuery,
} = favoriteApi;
