import React, {useCallback, useEffect, useRef, useState} from 'react';
import classNames from "classnames";
import s from "./styles.module.css";
import {ReactComponent as Plus} from "../../../../../assets/images/main/plus.svg";
import {ReactComponent as Minus} from "../../../../../assets/images/main/minus.svg";

const AccordionInfo = ({children, label}) => {
    const [isExpanded, setIsExpanded] = useState(false),
        duration = 200,
        accordionContentRef = useRef(null)


    const incrementHeight = (progress) => {
        if (accordionContentRef.current) {
            const element = accordionContentRef.current,
                sectionHeight = progress * element.scrollHeight;

            element.style.height = `${sectionHeight}px`;
        }
    };

    const decrementHeight = (progress) => {
        if (accordionContentRef.current) {
            const element = accordionContentRef.current,
                sectionHeight = element.scrollHeight - progress * element.scrollHeight;

            element.style.height = `${
                sectionHeight > element.scrollHeight ? element.scrollHeight : sectionHeight
            }px`;
            element.style.overflow = 'hidden';
        }
    };

    const expandAccordion = useCallback(() => {
        const start = performance.now();

        if (accordionContentRef.current) {
            const element = accordionContentRef.current;

            requestAnimationFrame(function animate(time) {
                const runtime = time - start,
                    relativeProgress = runtime / duration,
                    process = Math.min(relativeProgress, 1);

                if (process < 1) {
                    incrementHeight(process);
                    requestAnimationFrame(animate);
                }

                if (process === 1) {
                    element.style.height = 'auto';
                    element.style.overflow = 'initial';
                }
            });
        }
    }, []);

    const collapseAccordion = useCallback(() => {
        const start = performance.now();

        if (accordionContentRef.current) {
            const element = accordionContentRef.current;

            requestAnimationFrame(function animate(time) {
                const runtime = time - start,
                    relativeProgress = runtime / duration,
                    process = Math.min(relativeProgress, 1);

                if (process < 1) {
                    decrementHeight(process);
                    requestAnimationFrame(animate);
                }
                if (process === 1) {
                    element.style.height = '';
                    element.style.overflow = '';
                }
            });
        }
    }, []);

    const updateUi = useCallback(
        (isOpen) => {
            if (isOpen) {
                expandAccordion();
            } else {
                collapseAccordion();
            }
        },
        [expandAccordion, collapseAccordion]
    );

    const toggleAccordion = useCallback(() => {
        const expanded = !isExpanded;

        updateUi(expanded);
        setIsExpanded(expanded);
    }, [isExpanded, updateUi]);

    return (
        <div className={classNames(s.accordion_wrapper)} onClick={toggleAccordion}>
            <div className={classNames(s.accordion, isExpanded && s.accordion_open)}>
                <div
                    className={classNames(s.accordion_header)}

                >
                    <span className={classNames(s.accordion_header_title)}>{label}</span>
                    {isExpanded ? <Minus/> : <Plus/>}
                </div>
                <div className={classNames(s.accordion_content, isExpanded && s.accordion_content_open)}
                     ref={accordionContentRef}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AccordionInfo;