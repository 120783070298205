export const validate_email = (values) => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Обязательное поле';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Неверный email адресс';
    }
    return errors;
}
export const validate_number = (values) => {
    const errors = {};

    if (!values.number) {
        errors.number = 'Обязательное поле';
    } else if (values.number.length !== 18) {
        errors.number = 'Неверный номер телефона';
    }
    return errors;
}
